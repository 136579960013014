import React from "react";
import classnames from "classnames/bind";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next"; 

import styled from "./_Mypage.module.scss";
const cx = classnames.bind(styled);

class Mypage extends React.Component {
  ChangeView = e => {
    const page = e;

    this.props.history.push(`/user/mypage/${page}`);
  };

  render() {
    const {t} = this.props;
    return (
      <>
        <div className={cx("mypage-wrap")}>
          <div className={cx("mypage-user-data")}>
            <h1>{this.props.MemberData["MEMBER_NAME"]}</h1>
            <span>{this.props.MemberData["EMAIL"]}</span>
          </div>
        </div>
        <div className={cx("mypage-category")}>
          <div className={cx("main-category")}>
            <ul>
              <li
                className={cx({
                  "primary-blue": this.props.thispage === "recent"
                })}
              >
                <button type="button" onClick={() => this.ChangeView("recent")}>
                  {window.screen.width < 330
                    ? t("myPage.recentlyHouse2")
                    : t("myPage.recentlyHouse")}
                  {/* 최근 본 하우스 */}
                </button>
              </li>
              <li
                className={cx({
                  "primary-blue": this.props.thispage === "interest"
                })}
              >
                <button
                  type="button"
                  onClick={() => this.ChangeView("interest")}
                >
                  {t("myPage.favHouse")}
                </button>
              </li>
              <li
                className={cx({
                  "primary-blue": this.props.thispage === "scraps"
                })}
              >
                <button type="button" onClick={() => this.ChangeView("scraps")}>
                  {t("myPage.myScrap")}
                </button>
              </li>
              <li
                className={cx({
                  "primary-blue": this.props.thispage === "inquiry"
                })}
              >
                <button
                  type="button"
                  onClick={() => this.ChangeView("inquiry")}
                >
                  {t("myPage.roomQuestion")}
                </button>
              </li>
              <li
                className={cx({
                  "primary-blue": this.props.thispage === "privacy"
                })}
              >
                <button
                  type="button"
                  onClick={() => this.ChangeView("privacy")}
                >
                  {t("myPage.personalInfo")}
                </button>
              </li>
            </ul>
          </div>
        </div>
        {this.props.children}
      </>
    );
  }
}

export default withTranslation()(withRouter(Mypage));
