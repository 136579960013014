import React from "react";
import { withRouter } from "react-router-dom";
import { withCookies } from "react-cookie";
import { withTranslation } from "react-i18next";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as UserAction from "stores/modules/passwordchange";

import PasswordChange from "components/user/PasswordChange/PasswordChange";

class PasswordChangeContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  //비밀번호 변경 함수
  passwordChange = async (oldpassword, newpassword) => {
    const { UserAction } = this.props;
    const {t} = this.props;
    try {
      const res = await UserAction.PasswordChange(oldpassword, newpassword);

      if(res.data.result.resCode === "0000"){
        this.props.closeModal();

        return;
      }
      else if(res.data.result.rescode === "0004"){
        this.props.history.push('/error/404')
      }
      else if(res.data.result.rescode === "0005"){
        this.props.history.push('/error/506')
      }
      else {
        alert(res.data.result.resMessage);
      }

      // window.location.replace("/user/mypage");
      return;
    } catch (e) {
      console.log(e);
    }
  };

  render() {
    return (
      <PasswordChange
        passwordChange={this.passwordChange}
        closeModal={this.props.closeModal}
      />
    );
  }
}

export default withTranslation()(withCookies(
  connect(null, dispatch => ({
    UserAction: bindActionCreators(UserAction, dispatch)
  }))(withRouter(PasswordChangeContainer))
));
