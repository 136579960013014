import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as UserAction from "stores/modules/mainall";
import EmailOk from "components/user/EmailOk/EmailOk";

class EmailOkContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmId : "",
      isLoading : true
    };
  }
  componentDidMount(){
    console.log('dd');
    this.emailCheck();
  }

  // 이메일 확인 값 가져오기
  getConfirmIdData = () => {
    let nextState = this.state;
    nextState["confirmId"] = this.props.match.params.confirmId;
    this.setState(nextState);
  }
  // 이메일 유효성 검증
  emailCheck = async () => {
    const { UserAction } = this.props;
    let confirmId = this.props.match.params.confirmId;
    // let emailKey = "89e15813-e621-40b6-9d77-e34f8a773358";
    let nextState = this.state;
    try {
      const res = await UserAction.pwdModiEmailCheck(confirmId);
      // nextState["confirmId"] = res.data.result.data;
      if (res.data.result.resCode === "0000") {
        nextState["confirmId"] = confirmId;
        nextState["isLoading"] = false;
        this.setState(nextState);
        return res;
      } else if (res.data.result.resCode === "0002") {
        this.props.history.push("/user/expiredemail");
      } else {
        this.props.history.push("/error/" + res.data.result.resCode);
      }
    } catch (e) {
      console.log(e);
    }
  };
  render() {
    if(this.state.isLoading) return null;
    return (
      <EmailOk confirmId={this.state.confirmId} />
    );
  }
}

export default connect(null, dispatch => ({
  UserAction: bindActionCreators(UserAction, dispatch)
}))(EmailOkContainer);