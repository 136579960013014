import { createAction, handleActions } from "redux-actions";
import { pender } from "redux-pender";
import client from "lib/client";
import qs from "querystring";

const LANG_KO = "LANG_KO";
const LANG_EN = "LANG_EN";

export const Lang_Ko = createAction(LANG_KO, () => Promise.resolve());
export const Lang_En = createAction(LANG_EN, () => Promise.resolve());

const initialState = {
  lang: "ko"
};

export default handleActions(
  {
    ...pender({
      type: LANG_KO,
      onSuccess: state => {
        let nextState = state;
        nextState["lang"] = "ko";
        client.defaults.headers.common.lang = "ko";
        return nextState;
      }
    }),
    ...pender({
      type: LANG_EN,
      onSuccess: state => {
        let nextState = state;
        nextState["lang"] = "en";
        client.defaults.headers.common.lang = "en";
        return nextState;
      }
    })
  },
  initialState
);
