import React from "react";
import styled from "styled-components";

const EventPopups = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 99999;
    overflow: hidden;
    max-width: 540px;
    max-height: 600px;
`;
// 팝업
const EventPopup = ({ children, closeEvent, isEventOpen }) => {
  return !isEventOpen ? null : (
    <EventPopups closeEvent={closeEvent}>{children}</EventPopups>
    );
};

export default EventPopup;
