import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as MainallActions from "stores/modules/mainall";

import Dictinary from "components/dictionary";

class DictinaryContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nowCategory: "All",
      nowDetailCategory: "",
      youngCategory: true,
      ArticleArr: []
    };
  }
  
  // 쉐어하우스 스토리 페이지 뿌려주는 함수
  ArticleContent = async ({SEARCH_TEXT}) => {
    // 콘텐츠 조회 api 통신
    const { MainallActions } = this.props;
    try {
      const data = await MainallActions.ArticleText({SEARCH_TEXT});
      const res = await MainallActions.ArticleContent({SEARCH_TEXT: data.payload.SEARCH_TEXT});
      let nextState = this.state;
      nextState['ArticleArr'] = res.data.result.data.articleList;
      this.setState(nextState);
      return res.data.result.data.articleList; // 슬기로운 하우스 라이프 콘텐츠 반환 [ 배열형식임 ]
    } catch (e) {
      console.log(e);
    }
  };

  render() {
    return (
      <Dictinary
        nowDetailCategory={this.state.nowDetailCategory}
        ChangeYoungCategory={this.ChangeYoungCategory}
        ChangeCategory={this.ChangeCategory}
        ChangeDetailCate={this.ChangeDetailCate}
        SelCategory={this.SelCategory}
        ArticleContent={this.ArticleContent}
      /> 
    );
  }
}

export default connect(
  state => ({
    SEARCH_TEXT: state.mainall.article.content.SEARCH_TEXT
  }),
  dispatch => ({
    MainallActions: bindActionCreators(MainallActions, dispatch)
  })
)(DictinaryContainer);
