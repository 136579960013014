import React from "react";
import classnames from "classnames/bind";
import styled from "./_Inquiry.module.scss";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import MobileInquiry from "components/user/mypage/details/MobileInquiry/MobileInquiry";

import notification from "assets/images/notification.png";
import check from "assets/images/check.png";

const cx = classnames.bind(styled);

class Inquiry extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inquirydata: [],
      checked: [],
      checkedAll: false
    };
  }
  // 렌더링할때 입주문의 내역 받아옴
  componentDidMount() {
    this.props.InquiryData().then(value => {
      let NextState = this.state;
      NextState["inquirydata"] = value;
      this.setState(NextState);
    });
  }

  // 문의 내역 삭제
  inqueryDel = async () => {
    const { t } = this.props;
    if (this.state.checked.length === 0) {
      alert(t("myPage.delEmpty"));
      return false;
    }
    if (!window.confirm(t("myPage.delConfirm"))) {
      return false;
    }
    let nextState = this.state;
    const promises = nextState["checked"].map(async item =>
      this.props.inquery_del(item)
    );

    await Promise.all(promises);

    this.props.InquiryData().then(value => {
      let NextState = this.state;
      NextState["inquirydata"] = value;
      this.setState(NextState);
    });
  };

  // 모두 선택
  onChangeCheckBoxAll = () => {
    if (
      this.state.checked.length !== this.state.inquirydata["inquiryList"].length
    )
      this.setState({
        checked: this.state.inquirydata["inquiryList"].map(
          item => item["INQUIRY_ID"]
        )
      });
    else
      this.setState({
        checked: []
      });
  };

  // 체크박스
  onChangeCheckBox = e => {
    let nextState = this.state;

    if (this.state.checkedAll) {
      nextState["checkedAll"] = false;
    }

    if (e.target.checked) {
      nextState.checked.push(e.target.value);
    } else {
      nextState.checked = nextState.checked.filter(
        check => check !== e.target.value
      );
    }

    this.setState(nextState);
  };

  render() {
    const { t } = this.props;
    return (
      <>
        <div className={cx("Inquiry")}>
          <div>
            <button onClick={this.inqueryDel}>{t("myPage.Del")}</button>
            <table className={cx("inquiry-list")}>
              <thead>
                <tr>
                  <th width="4%">
                    <input
                      type="checkbox"
                      className={cx("chk-box")}
                      id="chk-all"
                      checked={
                        this.state.checked.length !== 0 &&
                        this.state.checked.length ===
                          this.state.inquirydata["inquiryList"].length
                      }
                      onClick={this.onChangeCheckBoxAll}
                    />
                    <label htmlFor="chk-all">
                      <em>
                        <img src={check} alt={"check"} />
                      </em>
                    </label>
                  </th>
                  <th width="12%">{t("myPage.rentType")}</th>
                  <th width="17%">{t("myPage.house")}</th>
                  <th width="52%">{t("myPage.recentlyMessage")}</th>
                  <th width="15%">{t("myPage.date")}</th>
                </tr>
              </thead>
              <tbody>
                {this.state.inquirydata["inquiryList"] &&
                  this.state.inquirydata["inquiryList"].map((items, index) => {
                    return (
                      <tr key={items["CREATE_DT"]}>
                        <td>
                          <input
                            type="checkbox"
                            className={cx("chk-box")}
                            id={"chk-piece" + index}
                            value={items["INQUIRY_ID"]}
                            onChange={this.onChangeCheckBox}
                            checked={
                              this.state.checked.includes(
                                items["INQUIRY_ID"]
                              ) || this.state.checkedAll
                            }
                          />
                          <label htmlFor={"chk-piece" + index}>
                            <em>
                              <img src={check} alt={"check"} />
                            </em>
                          </label>
                        </td>
                        <td>
                          <Link
                            to={`/user/mypage/inquiry/detail/${items["INQUIRY_ID"]}`}
                          >
                            <p>{items["RENT_TYPE_NAME"]}</p>
                          </Link>
                        </td>
                        <td>
                          <Link
                            to={`/user/mypage/inquiry/detail/${items["INQUIRY_ID"]}`}
                          >
                            <p>
                              {items["BRAND_NAME"] + " " + items["HOUSE_NAME"]}
                            </p>
                          </Link>
                        </td>
                        <td className={cx("content")}>
                          <Link
                            to={`/user/mypage/inquiry/detail/${items["INQUIRY_ID"]}`}
                          >
                            <p>{items["LAST_MESSAGE"]}</p>
                          </Link>
                          {items["READ_YN"] === "N" && (
                            <img src={notification} alt="notification" />
                          )}
                        </td>
                        <td>
                          <Link
                            to={`/user/mypage/inquiry/detail/${items["INQUIRY_ID"]}`}
                          >
                            <span>{items["LAST_MESSAGE_DT"]}</span>
                          </Link>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
        <MobileInquiry
          InquiryData={this.props.InquiryData}
          inqueryDel={this.props.inquery_del}
        />
      </>
    );
  }
}

export default withTranslation()(Inquiry);
