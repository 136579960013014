import { createAction, handleActions } from "redux-actions";
import { pender } from "redux-pender";
import qs from 'query-string';
import client from "lib/client";

const ARTICLE_TEXT = "ARTICLE_TEXT";
const ARTICLE_CONTENT = "ARTICLE_CONTENT";
const PASSWORD_EMAILCHECK = "PASSWORD_EMAILCHECK";
const EMAIL_PASSWORDMODI = "EMAIL_PASSWORDMODI";
const EMAIL_REFUSE = "EMAIL_REFUSE";

const ArticleContent_API = ({CATEGORY, SEARCH_TEXT, rows} = { CATEGORY: "", SEARCH_TEXT: "", rows: "" }) => {
  // 청년주거백과 콘텐츠 검색 결과
  // 카테고리 0001 슬기로운 하우스 생활 0002 컴앤스테이툰 0003 쉐어하우스 스토리 0004 쉐어하우스 리포트 0005 청년주택의 모든 것 0006 청년임대주택 알아보기
    return client.get(`/rest/article?${qs.stringify({ CATEGORY, SEARCH_TEXT, rows })}`);
};

const passwordEmailCheck_API = key => {
  // 청년주거백과 콘텐츠 검색 결과
  // 카테고리 0001 슬기로운 하우스 생활 0002 컴앤스테이툰 0003 쉐어하우스 스토리 0004 쉐어하우스 리포트 0005 청년주택의 모든 것 0006 청년임대주택 알아보기
  return client.get(`/rest/account/confirm/${key}`);
};

const passwordEmailModi_API = (newPassword, confirmId) => {
  // 청년주거백과 콘텐츠 검색 결과
  // 카테고리 0001 슬기로운 하우스 생활 0002 컴앤스테이툰 0003 쉐어하우스 스토리 0004 쉐어하우스 리포트 0005 청년주택의 모든 것 0006 청년임대주택 알아보기
  return client.post(
    '/rest/password/change',qs.stringify({ newPassword, confirmId })
  );
};

const emailRefuse_API = (confirmId) => {
  // 청년주거백과 콘텐츠 검색 결과
  // 카테고리 0001 슬기로운 하우스 생활 0002 컴앤스테이툰 0003 쉐어하우스 스토리 0004 쉐어하우스 리포트 0005 청년주택의 모든 것 0006 청년임대주택 알아보기
  return client.post(
    `/rest/account/confirm/${confirmId}/refuse `);
};

export const ArticleText = createAction(ARTICLE_TEXT);
export const ArticleContent = createAction(ARTICLE_CONTENT, ArticleContent_API); // 청년주거백과 콘텐츠 검색 결과
export const pwdModiEmailCheck = createAction(PASSWORD_EMAILCHECK, passwordEmailCheck_API); // 이메일 비밀번호 찾기 연동 (confirmId 유효성 확인)
export const pwModiEmail = createAction(EMAIL_PASSWORDMODI, passwordEmailModi_API); // 이메일 비밀번호 찾기 비밀번호 변경 연동
export const emailRefuse = createAction(EMAIL_REFUSE, emailRefuse_API); // 이메일 승인 거부

const initialState = {
  article: {
    content: {
      SEARCH_TEXT: "",
      ARTICLE_LIST: []
    }
  }
};

export default handleActions(
  {
    [ARTICLE_TEXT] : (state, action) => {
      let nextState = state;
      const { SEARCH_TEXT } = action.payload;
      nextState['article']['content']['SEARCH_TEXT'] = SEARCH_TEXT;
      return nextState;
    },
    ...pender({
      type: ARTICLE_CONTENT,
      onSuccess: (state, action) => {
        let nextState = state;
        nextState['article']['content']['ARTICLE_LIST'] = action.payload.data.result.data.articleList
        return nextState;
      }
    }),
    ...pender({
      type: PASSWORD_EMAILCHECK,
      onSuccess: () => {
        return;
      }
    }),
    ...pender({
      type: EMAIL_PASSWORDMODI,
      onSuccess: () => {
        return;
      }
    }),
    ...pender({
      type: EMAIL_REFUSE,
      onSuccess: () => {
        return;
      }
    })
  },
  initialState
);
