import React from "react";
import classNames from "classnames/bind";
import Header from "containers/common/header/HedaerContainer";
import Footer from "components/common/Footer/Footer";
import MobileFooter from "components/common/MobileFooter/MobileFooter";
import { withRouter } from "react-router-dom";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as MainAction from "stores/modules/main";

import topBtn from "assets/images/topBtn.png";

import styles from "./_MainTemplate.module.scss";
const cx = classNames.bind(styles);

class MainTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isPrev: true,
      isModalOpen: false,
      HeaderFixed: false
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.onScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.onScroll);
  }

  // 로그인 오픈
  openModal = e => {
    e.preventDefault();
    this.setState({ isModalOpen: true });
  };

  // 로그인 클로즈
  closeModal = e => {
    this.setState({ isModalOpen: false });
  };

  // 헤더 고정 유무
  headerPosition = () => {
    let nextState = this.state;
    nextState["HeaderFixed"] = !this.state.HeaderFixed;
    this.setState(nextState);
  };

  // 뒤로가기
  goPrev = () => {
    this.props.history.goBack();
  };

  // 상단 이동
  toTop = () => {
    window.scrollTo(0, 0);
  };

  // 스크롤시 상단이동 버튼 노출
  onScroll = e => {
    const scrollTop = ("scroll", e.srcElement.scrollingElement.scrollTop);
    if (window.screen.width < 768) {
      // 최상단 이동 버튼 작동
      if (scrollTop > 480) {
        this.topBtn.style.display = "block";
      } else if (scrollTop < 480) {
        this.topBtn.style.display = "none";
      }
    }
  };

  render() {
    return (
      <div className={cx("base")}>
        <Header
          openModal={this.openModal}
          closeModal={this.closeModal}
          isModalOpen={this.state.isModalOpen}
          HeaderFixed={this.state.HeaderFixed}
        />
        {this.props.children}
        <div
          className={cx("goPrev")}
          onClick={this.goPrev}
          id="mainTemplate_goPrev_btn"
        >
          <p>
            <span></span>
            <span></span>
          </p>
        </div>
        <div
          className={cx("toTop")}
          onClick={this.toTop}
          ref={ref => {
            this.topBtn = ref;
          }}
          id="mainTemplate_toTop_btn"
        >
          <img src={topBtn} alt="topBtn" />
        </div>
        <Footer />
        <MobileFooter />
      </div>
    );
  }
}

export default withRouter(MainTemplate);
