import { combineReducers } from "redux";
import { penderReducer as pender } from "redux-pender";

import user from "./user";
import main from "./main";
import mainall from './mainall';
import search from './search';
import lang from './lang';
//-----------------------리덕스 기본 설정----------------------//
export default combineReducers({
  user,
  main,
  mainall,
  search,
  lang,
  pender
});
