import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withCookies } from "react-cookie";
import * as ContentAction from "stores/modules/contentdetail";
import * as MainAllAction from "stores/modules/mainall";
import * as UserAction from 'stores/modules/user';
import ContentDetail from "components/dictionary/detail/Postdetail";
import { resCode } from "lib/resCode";

class ContentDetailContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  // 카테고리 가져오는거
  CategoryGet = async ({CATEGORY}) => {
    const {MainAllAction} = this.props;

    try{
      const res = await MainAllAction.ArticleContent({CATEGORY,SEARCH_TEXT:"", rows: 3});
      const data = resCode(res);
      return data;
    } catch(e){
      console.log(e);
    }
  }

  ContentDetail = async () => {
    // 청년주거백과 상세 페이지 (자체 제작 게시글) 뿌려주는 함수
    try {
      const contentrest = this.props.location.pathname;
      const res = await this.props.ContentAction.ContentDetail(contentrest);
      resCode(res);
      return res.data.result.data;
    } catch (e) {
      console.log(e);
    }
  };

  // 현재 나의 스크랩 확인 함수 ( 스크랩 되었을시 스크랩 된 게시글의 스크랩아이콘 변경 을 위함. )
  Seemyscrap = async () => {
    const { ContentAction } = this.props;
    try {
      const res = await ContentAction.Seemyscrap();
      const data = res.data.result.data.articleList;
      return data;
    } catch (e) {
      console.log(e);
    }
  };

    // 스크랩 추가
    AddScraps = async (houseId) => {
      const { ContentAction } = this.props;
      try {
        await ContentAction.AddScraps(houseId);
        return;
      } catch (e) {
        console.log(e);
      }
    };

    // 스크랩 제거
    DeleteScraps = async (houseId) => {
      const { ContentAction } = this.props;
      try {
        await ContentAction.DeleteScraps(houseId);
        return;
      } catch(e) {
        console.log(e);
      }
    }
    // 좋아요 추가
    AddLike = async (houseId) => {
      const { ContentAction } = this.props;
      try {
        await ContentAction.AddLike(houseId);
        return;
      } catch(e) {
        console.log(e);
      }
    }
    // 좋아요 삭제
    DeleteLike = async (houseId) => {
      const { ContentAction } = this.props;
      try {
        await ContentAction.DeleteLike(houseId);
        return;
      } catch(e) {
        console.log(e);
      }
    }

  // 댓글 작성함수 ( 대댓글 포함해서 같이 처리 )
  AddComment = async (articlepath, comment, seq) => {
    try {
      if (seq !== "" && seq !== null && seq !== undefined) {
        const res = await this.props.ContentAction.AddComment(
          articlepath,
          comment,
          seq
        );
        return res;
      } else {
        const res = await this.props.ContentAction.AddComment(
          articlepath,
          comment
        );
        return res;
      }
    } catch (e) {
      console.log(e);
    }
  };
  // 댓글 삭제
  DelComment = async (articleId, seq) => {
    try {
      
        const res = await this.props.ContentAction.delComment(
          articleId,
          seq
        );
        console.log(res);
        return res;
      
    } catch (e) {
      console.log(e);
    }
  };
  // 댓글 수정
  ModiComment = async (articleId, message ,seq) => {
    console.log(articleId, message, seq)
    try {

      const res = await this.props.ContentAction.ModiComment(
        articleId,
        message,
        seq
      );
      console.log(res);
      return res;

    } catch (e) {
      console.log(e);
    }
  };


  render() {
    return (
      <ContentDetail
        CategoryGet={this.CategoryGet}
        isLogined={this.props.isLogined}
        cookies={this.props.cookies.cookies}
        ContentDetail={this.ContentDetail}
        AddComment={this.AddComment}
        DelComment={this.DelComment}
        ModiComment={this.ModiComment}
        Seemyscrap={this.Seemyscrap}
        AddScraps={this.AddScraps}
        DeleteScraps={this.DeleteScraps}
        AddLike={this.AddLike}
        DeleteLike={this.DeleteLike}
      />
    );
  }
}

export default withCookies(
  connect(state => ({
    isLogined: state.user.auth.user.isLogined,
  }), dispatch => ({
    ContentAction: bindActionCreators(ContentAction, dispatch),
    UserAction: bindActionCreators(UserAction, dispatch),
    MainAllAction: bindActionCreators(MainAllAction, dispatch)
  }))(ContentDetailContainer)
);
