import React from "react";
import { withCookies } from "react-cookie";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as UserAction from "stores/modules/user";

import { encode } from "lib/encrypt";
import { resCode } from "lib/resCode";

import Login from "components/user/Login";

class UserLoginContainer extends React.Component {
  state = {
    loginButton: false
  };

  // 로그인 한번만 누르게 하기
  button = () => {
    this.setState({ ...this.state, loginButton: true });
  };

  // 일반회원 로그인 함수
  LoginNormal = async ({ userId, userPw }) => {
    const { UserAction } = this.props;
    const {t} = this.props;
    try {
      const res = await UserAction.LoginNormal({
        userId,
        userPw
      });

      const Ecode = res.data.result["resCode"];

      if (Ecode === "0002") {
        return alert(t("login.loginFail"));
      } else if (Ecode === "0000") {
        let LoginData = {
          member_name: res["data"]["result"]["data"]["member"]["MEMBER_NAME"],
          access_token: res["headers"]["x-access-token"],
          refresh_token: res["headers"]["x-refresh-token"]
        };

        LoginData = encode(JSON.stringify(LoginData));

        this.props.cookies.set("auth", LoginData, {
          path: "/"
        });

        this.props.closeModal();


        return;
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.setState({ ...this.state, loginButton: false });
    }
  };

  render() {
    return (
      <Login
        closeModal={this.props.closeModal}
        cookies={this.props.cookies}
        loginButton={this.state.loginButton}
        LoginNormal={this.LoginNormal}
        button={this.button}
      />
    );
  }
}

export default withTranslation()(withCookies(
  connect(null, dispatch => ({
    UserAction: bindActionCreators(UserAction, dispatch)
  }))(withRouter(UserLoginContainer))
));
