import { createAction, handleActions } from "redux-actions";
import { pender } from "redux-pender";
import client from "lib/client";
import qs from "query-string";

const HOUSEDETAIL = "HOUSEDETAIL";
const HOUSE_INQUERY = "HOUSEINQUERY";
const HOUSE_MESSAGE_QUERY = "HOUSE_MESSAGE_QUERY";
const HOUSE_MESSAGE_DETAIL = "HOUSE_MESSAGE_DETAIL";
const FAVHOUSE = "FAVHOUSE";
const DELETE_FAV_HOUSE = "DELETE_FAV_HOUSE";
const SEEFAVHOUSE = "SEEFAVHOUSE";

const HouseDetail_API = houseid => {
  return client.get(`/rest/${houseid}`); // 해당하우스의 상세정보 조회
};

const Houseinquery_API = (
  {HOUSE_ID, MESSAGE, INQUIRY_ID, TO_DATE, FROM_DATE, ROOM_CODE} = {
    HOUSE_ID: "",
    FROM_DATE: "",
    TO_DATE: "",
    ROOM_CODE: ""
  }
) => {
  return client.post(
    "/rest/my/inquiry/message",
    qs.stringify({
      HOUSE_ID,
      MESSAGE,
      INQUIRY_ID,
      FROM_DATE,
      TO_DATE,
      ROOM_CODE
    })
  );
};

const houseMessageListAPI = () => {
  return client.get("/rest/my/inquiry/message");
};

const houseDetailMessage_API = ({INQUIRY_ID}) => {
  return client.get(`/rest/my/inquiry/message/${INQUIRY_ID}`);
};

// 관심하우스 추가
const AddFavHouse_API = houseid => {
  return client.post(`/rest/my/favorite/house/${houseid}`);
};

// 관심 하우스 제거
const delFavHouse_API = houseid => {
  return client.delete(`/rest/my/favorite/house/${houseid}`);
};

// 관심하우스 목록
const SeeFavHouse_API = () => {
  return client.get("/rest/my/favorite/house");
};

export const HouseDetail = createAction(HOUSEDETAIL, HouseDetail_API); // 하우스 상세 정보 조회
export const HouseInquery = createAction(HOUSE_INQUERY, Houseinquery_API); // 하우스 입주 문의 추가
// 나의 입주문의 상세 api
export const HouseDetailMessage = createAction(HOUSE_MESSAGE_DETAIL, houseDetailMessage_API);
export const houseMessage = createAction(
  HOUSE_MESSAGE_QUERY,
  houseMessageListAPI
); //하우스 문의 리스트
export const AddFavHouse = createAction(FAVHOUSE, AddFavHouse_API);
export const delFavHouse = createAction(DELETE_FAV_HOUSE, delFavHouse_API); // 나의 관심목록 하우스 삭제
export const favHouseList = createAction(SEEFAVHOUSE, SeeFavHouse_API);

const initialState = {};

export default handleActions(
  {
    ...pender({
      type: HOUSEDETAIL,
      onSuccess: () => {
        return;
      }
    }),
    ...pender({
      type: HOUSE_INQUERY,
      onSuccess: () => {
        return;
      }
    }),
    ...pender({
      type: HOUSE_MESSAGE_QUERY,
      onSuccess: () => {
        return;
      }
    }),
    ...pender({
      type:HOUSE_MESSAGE_DETAIL,
      onSuccess: () => {
        return;
      }
    }),
    ...pender({
      type: FAVHOUSE,
      onSuccess: () => {
        return;
      }
    }),
    ...pender({
      type: DELETE_FAV_HOUSE,
      onSuccess: () => {
        return;
      }
    }),
    ...pender({
      type: SEEFAVHOUSE,
      onSuccess: () => {
        return;
      }
    })
  },
  initialState
);
