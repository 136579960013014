import React from "react";
import classnames from "classnames/bind";
import { withTranslation } from "react-i18next";
import styles from "./_CloseAccount.module.scss";
import ReactHtmlParser from "react-html-parser";

const cx = classnames.bind(styles);

class CloseAccount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {t} = this.props;
    return (
      <div className={cx("modp")}>
        <button
          className={cx("close")}
          onClick={this.props.closeModal}
        ></button>
        <div className={cx("modp-wrap")}>
          <h1>{ReactHtmlParser(t("myPage.outMember"))}</h1>
          <span>
            {ReactHtmlParser(t("myPage.outText"))}
          </span>

          <div>
            <input
              className={cx("comfirm")}
              type="button"
              value={t("myPage.Y")}
              onClick={e => this.props.CloseAccount(e)}
            />
            <input
              className={cx("cancel")}
              type="button"
              value={t("myPage.N")}
              onClick={this.props.closeModal}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(CloseAccount);
