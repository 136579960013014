import React from "react";
import { withTranslation } from "react-i18next";
import ReactHtmlParser from "react-html-parser";
import classnames from "classnames/bind";
import styles from "./_Register.module.scss";
import { withRouter } from "react-router";

import Popup from "components/common/Popup";
import Login from "containers/user/login/UserLoginContainer";

const cx = classnames.bind(styles);

class Register extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            EMAIL: "", // 이메일
            MEMBER_NAME: "", // 회원 실명
            GENDER: "M", // 성별
            BIRTHYEAR: "", // 출생년도 4자리 ex ) 2000
            NEW_PASSWORD: "", // 비밀번호
            RE_NEW_PASSWORD: "", // 비밀번호 확인
            NATION_CODE: "", // 국가코드
            NOTICE_YN: "N", // 메일 수신동의
            NATION_ARR: [],
            hasValue: false, // 현재 모든 필수정보가 입력되었는지,
            isModalOpen: false
        };
    }
    // 창 닫기
    closeModal = () => {
        this.setState({ ...this.state, isModalOpen: false });
    };

    // 현재 입력되거나 변경된 값 state 넣고 저장 ( 회원가입 관련 )
    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
        this.hasAllValue();
    };

    // 해당 문자열이 빈 문자열인지 체크 하는 함수
    empty = str => {
        if (str.trim() === "") {
            return true;
        } else {
            return false;
        }
    };

    // 회원가입시 필요한 내용이 전부 들어있는지 확인함
    hasAllValue = () => {
        let NexthasValue = false;
        if (
            ((!this.empty(this.state.EMAIL),
            !this.empty(this.state.MEMBER_NAME),
            !this.empty(this.state.GENDER),
            !this.empty(this.state.BIRTHYEAR),
            !this.empty(this.state.NEW_PASSWORD),
            !this.empty(this.state.RE_NEW_PASSWORD)),
            !this.empty(this.state.NATION_CODE))
        ) {
            NexthasValue = true;
            this.setState({
                hasValue: NexthasValue
            });
        }
    };

    // 회원가입 ( 정규식을 포함한 회원가입 함수 [입력누락도 검사해줌])
    register = e => {
      e.preventDefault();
      const {t} = this.props;
      if (this.empty(this.state.EMAIL)) {
        alert(t("myPage.emailEmpty"));
          return false;
      } else {
          const emailRule = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i; //이메일 정규식
          if (!emailRule.test(this.state.EMAIL)) {
            alert(t("myPage.emailAlert"));
              return false;
          }
      }

      if (this.empty(this.state.MEMBER_NAME)) {
        alert(t("register.emptyName"));
          return false;
      }

      if (this.empty(this.state.GENDER)) {
        alert(t("register.emptyGender"));
          return false;
      }

      if (this.empty(this.state.BIRTHYEAR)) {
        alert(t("register.emptyYear"));
          return false;
      }

      if (this.empty(this.state.NEW_PASSWORD)) {
        alert(t("register.emptyPwd"));
          return false;
      } else {
          // var pattern1 = /[0-9]/;

          // var pattern2 = /[a-zA-Z]/;

          // var pattern3 = /[~!@#$%<>^&*]/;

          // if (this.state.NEW_PASSWORD.length < 8) {
          //     alert("비밀번호를 8자 이상으로 입력해주십시오");
          //     return false;
          // } else if (
          //     !pattern1.test(this.state.NEW_PASSWORD) ||
          //     !pattern2.test(this.state.NEW_PASSWORD) ||
          //     !pattern3.test(this.state.NEW_PASSWORD)
          // ) {
          //     alert(
          //         "비밀번호는 영문+숫자+특수기호 조합으로 구성하여야합니다."
          //     );
          //     return false;
          // } else {
          //     var SamePass_0 = 0; //동일문자 카운트
          //     var SamePass_1 = 0; //연속성(+) 카운드
          //     var SamePass_2 = 0; //연속성(-) 카운드

          //     var chr_pass_0;
          //     var chr_pass_1;

          //     for (var i = 0; i < this.state.NEW_PASSWORD.length; i++) {
          //         chr_pass_0 = this.state.NEW_PASSWORD.charAt(i);
          //         chr_pass_1 = this.state.NEW_PASSWORD.charAt(i + 1);

          //         //동일문자 카운트
          //         if (chr_pass_0 === chr_pass_1) {
          //             SamePass_0 = SamePass_0 + 1;
          //         } // if

          //         //연속성(+) 카운드
          //         if (
          //             chr_pass_0.charCodeAt(0) - chr_pass_1.charCodeAt(0) ===
          //             1
          //         ) {
          //             SamePass_1 = SamePass_1 + 1;
          //         } // if

          //         //연속성(-) 카운드
          //         if (
          //             chr_pass_0.charCodeAt(0) - chr_pass_1.charCodeAt(0) ===
          //             -1
          //         ) {
          //             SamePass_2 = SamePass_2 + 1;
          //         } // if
          //     } // for

          //     if (SamePass_0 > 1) {
          //         alert("동일문자를 3번 이상 사용할 수 없습니다.");
          //         return false;
          //     } // if

          //     if (SamePass_1 > 1 || SamePass_2 > 1) {
          //         alert(
          //             "연속된 문자열(123 또는 321, abc, cba 등)을\n 3자 이상 사용 할 수 없습니다."
          //         );
          //         return false;
          //     } // if
          // }
          if (this.state.NEW_PASSWORD.length < 8) {
            alert(t("register.pwLengthAlert"));
              return false;
          }
      }

      if (this.empty(this.state.RE_NEW_PASSWORD)) {
        alert(t("myPage.pwCheckAlert"));
          return false;
      } else {
          if (this.state.NEW_PASSWORD !== this.state.RE_NEW_PASSWORD) {
              alert(
                t("myPage.pwNotEqual")
              );
              return false;
          }
      }

      if (this.empty(this.state.NATION_CODE)) {
          if (this.state.NATION_CODE === "") {
            alert(t("register.emptyNation"));
              return false;
          }
      }

      if (this.state.NOTICE_YN === "N") {
          alert(
            t("register.sendCheck")
          );
          return false;
      }

      this.props.RegisterNormal({
          EMAIL: this.state.EMAIL,
          MEMBER_NAME: this.state.MEMBER_NAME,
          GENDER: this.state.GENDER,
          BIRTHYEAR: this.state.BIRTHYEAR,
          NEW_PASSWORD: this.state.NEW_PASSWORD,
          NATION_CODE: this.state.NATION_CODE,
          NOTICE_YN: this.state.NOTICE_YN
      });
    };
    // 로그인 팝업
    PopupLogin = e => {
        e.preventDefault();
        const {t} = this.props;
        if (this.props.isLogined === true) {
          return alert(t("register.alreadyLogin"));
        }

        let nextState = this.state;

        nextState["isModalOpen"] = !nextState["isModalOpen"];

        this.setState(nextState);

        return (
            <Popup isModalOpen={this.state.isModalOpen}>
                <Login isModalOpen={this.state.isModalOpen} />
            </Popup>
        );
    };

    // 회원가입시 체크박스에 뉴슬레터 관련해서 메일 받겠다 확인하는 input 확인
    onClick = e => {
        if (e.target.name !== "NOTICE_YN") {
            this.setState({
                [e.target.name]: e.target.value
            });
        }
    };

    // 인풋값 입력
    onChange = e => {
      const name = e.target.name;
      if (name === "NOTICE_YN") {
        this.setState({
          [e.target.name]: this.state[e.target.name] === "Y"?"N":"Y"
        });
      }
    };



    // 렌더링 후 국가코드 state 에 넣어줌
    componentDidMount() {
        this.props.NationCodes().then(value => {
            this.setState({
                NATION_ARR: value
            });
        });
    }

    render() {
        const { NATION_ARR } = this.state;
        const {t} = this.props;
        return (
          <div className={cx("content")}>
            <div className={cx("register")}>
              <div className={cx("register-left")}>
                <h1>
                  {ReactHtmlParser(t("register.registerText"))}
                </h1>
                <p>{t("register.nowJoin")}</p>
                <div className={cx("clear")}></div>
              </div>
              <div className={cx("register-right")}>
                <div>
                  <h1>{t("register.join")}</h1>
                  <form>
                    <label className={cx("email_box")}>
                      <input
                        name="EMAIL"
                        type="email"
                        placeholder={t("register.email")}
                        className={cx("email_check")}
                        onChange={this.handleChange}
                      />
                    </label>
                    <input
                      name="MEMBER_NAME"
                      type="text"
                      placeholder={t("register.name")}
                      required
                      onChange={this.handleChange}
                    />
                    <div className={cx("genders")}>
                      <label>
                        <input
                          type="radio"
                          name="GENDER"
                          defaultChecked
                          value="M"
                          onChange={this.onClick}
                        />
                        <div className={cx("gender-box")}>
                          {t("register.man")}
                        </div>
                      </label>
                      <label>
                        <input
                          type="radio"
                          name="GENDER"
                          value="F"
                          onChange={this.onClick}
                        />
                        <div className={cx("gender-box")}>
                          {t("register.woman")}
                        </div>
                      </label>
                    </div>
                    <input
                      name="BIRTHYEAR"
                      type="text"
                      placeholder={t("register.birth")}
                      required
                      onChange={this.handleChange}
                    />
                    <select
                      name="NATION_CODE"
                      type="text"
                      required
                      onChange={this.handleChange}
                    >
                      <option value="" data-default defaultChecked>
                        {t("register.nation")}
                      </option>
                      {NATION_ARR.map(item => {
                        return (
                          item.code !== "0" && (
                            <option
                              key={item.code}
                              value={item.code}
                              data-default
                              defaultChecked
                            >
                              {item.name}
                            </option>
                          )
                        );
                      })}
                    </select>
                    <input
                      name="NEW_PASSWORD"
                      type="password"
                      placeholder={t("register.pwdInput")}
                      required
                      onChange={this.handleChange}
                    />
                    <input
                      name="RE_NEW_PASSWORD"
                      type="password"
                      placeholder={t("register.pwdReInput")}
                      required
                      onChange={this.handleChange}
                    />
                    <div className={cx("notice-agreement")}>
                      <label>
                        {this.state.NOTICE_YN === "Y" ? "✔" : ""}
                        <input
                          type="checkbox"
                          name="NOTICE_YN"
                          checked={this.state.NOTICE_YN === "Y" ? true : false}
                          onChange={this.onChange}
                        />
                      </label>
                      <span>{t("register.newsSend")}</span>
                    </div>
                    <div className={cx("clear")}></div>
                    <p>{t("register.mailSend")}</p>
                    <input
                      type="submit"
                      onClick={e => this.register(e)}
                      value={t("register.join")}
                    />
                    <div>
                      <span>{t("register.already")}</span>
                      <button
                        onClick={this.PopupLogin}
                        className={cx("primary_blue")}
                      >
                        {t("register.login")}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <div className={cx("clear")}></div>
            </div>
            <Popup
              closeModal={this.closeModal}
              isModalOpen={this.state.isModalOpen}
            >
              <Login
                closeModal={this.closeModal}
                isModalOpen={this.state.isModalOpen}
              />
            </Popup>
          </div>
        );
    }
}

export default withTranslation()(withRouter(Register));
