import React from "react";
import HouseLife from "components/dictionary/detail/House_life";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as UserActions from "stores/modules/mainall";

class HouseLifeContainer extends React.Component {

  componentDidMount() {
    this.ArticleContent();
  }

  // 슬기로운 하우스 라이프 콘테이너
  ArticleContent = async () => {
    // 콘텐츠 조회 api 통신
    const { UserActions, SEARCH_TEXT } = this.props;
    try {
      const res = await UserActions.ArticleContent({ SEARCH_TEXT, CATEGORY: "0001" });
      return res.data.result.data.articleList; // 슬기로운 하우스 라이프 콘텐츠 반환 [ 배열형식임 ]
    } catch (e) {
      console.log(e);
    }
  };

  render() {
    // 콘텐츠 조회 api 통신 호출 함수 전달 및 하우스 라이프 컴포넌트 반환
    return (
      <HouseLife
        ArticleContent={this.ArticleContent}
        ArticleArr={this.props.ARTICLE_LIST}
      />
    );
  }
}

export default connect(state => ({
  SEARCH_TEXT: state.mainall.article.content.SEARCH_TEXT,
  ARTICLE_LIST: state.mainall.article.content.ARTICLE_LIST
}), dispatch => ({
  UserActions: bindActionCreators(UserActions, dispatch)
}))(HouseLifeContainer);
