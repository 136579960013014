import React from "react";
import classnames from "classnames/bind";
import styled from "./_Scraps.module.scss";
import heart from "assets/images/heart.png";
import comment from "assets/images/comment.png";
import { withRouter, Link } from "react-router-dom";
import { withTranslation } from "react-i18next";

import check from "assets/images/check.png";

const cx = classnames.bind(styled);

class Scraps extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      scraps: [],
      isLoading: true,
      checked: [],
      checkedAll: false
    };
  }

  componentDidMount() {
    this.setScrap();
  }
  // 스크랩 가져오기
  setScrap = () => {
    this.props.SeeScraps().then(value => {
      let nextState = this.state;
      nextState["scraps"] = value;
      this.setState(nextState);
    });
  };
  // 체크박스 전체 선택
  onChangeCheckBoxAll = () => {
    if (this.state.checked.length !== this.state.scraps.length)
      this.setState({
        checked: this.state.scraps.map(item => item["ARTICLE_ID"])
      });
    else
      this.setState({
        checked: []
      });
  };
  // 스크랩 삭제 버튼 이벤트
  onClickScrapDel = async () => {
    let nextState = this.state;

    const promises = nextState["checked"].map(async item =>
      this.props.DelScraps(item)
    );

    await Promise.all(promises);
      
    // window.location.reload();
    this.setScrap();

  };
  // 페이지 이동
  goto = item => {
    if (item["ARTICLE_TYPE"] === "1") {
      this.props.history.push(item["ARTICLE_URL"]);
    } else {
      window.open(item["ARTICLE_URL"]);
    }
  };
  // 체크박스 개별 선택
  onChangeCheckBox = e => {
    let nextState = this.state;

    if (this.state.checkedAll) {
      nextState["checkedAll"] = false;
    }

    if (e.target.checked) {
      nextState.checked.push(e.target.value);
    } else {
      nextState.checked = nextState.checked.filter(
        check => check !== e.target.value
      );
    }

    this.setState(nextState);
  };

  render() {
    const {t} = this.props;
    return (
      <>
        <div className={cx("story")}>
          <h2>
            <input
              type="checkbox"
              id="chk-all"
              checked={
                this.state.checked.length !== 0 &&
                this.state.checked.length === this.state.scraps.length
              }
              onClick={this.onChangeCheckBoxAll}
              readOnly
            />
            <label htmlFor="chk-all">
              <span></span>
            </label>
            <div>
              {this.state.scraps.length} {t("myPage.contentCount")}
            </div>
          </h2>
          <button onClick={this.onClickScrapDel}>{t("myPage.Del")}</button>
          <div>
            {this.state.scraps.map((item, index) => {
              return (
                <div className={cx("story-card")} key={index}>
                  <div
                    onClick={() => this.goto(item)}
                    className={cx("contentWrap")}
                  >
                    <div className={cx("card-image")}>
                      <img src={item["INFO_THUMB_URL"]} alt="card" />
                    </div>
                    <div className={cx("card-title")}>
                      <Link to={item["ARTICLE_URL"]}>{item["TITLE"]}</Link>
                    </div>
                    <div className={cx("card-footer")}>
                      <span>{item["CREATE_DT"]}</span>
                      <span>
                        <img src={comment} alt="comment" />
                        {item["COMMENT_CNT"]}
                      </span>
                      <span>
                        <img src={heart} alt="heart" />
                        {item["LIKE_CNT"]}
                      </span>
                    </div>
                  </div>
                  <h3>
                    <input
                      type="checkbox"
                      className={cx("chk-box")}
                      id={"chk-piece" + index}
                      onChange={this.onChangeCheckBox}
                      value={item["ARTICLE_ID"]}
                      checked={
                        this.state.checked.includes(item["ARTICLE_ID"]) ||
                        this.state.checkedAll
                      }
                    />
                    <label htmlFor={"chk-piece" + index}>
                      <em>
                        <img src={check} alt={"check"} />
                      </em>
                    </label>
                  </h3>
                  <div className={cx("clear")}></div>
                </div>
              );
            })}
          </div>
        </div>
      </>
    );
  }
}

export default withTranslation()(withRouter(Scraps));
