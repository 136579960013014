import React from "react";
import classnames from "classnames/bind";
import { withRouter, Link } from "react-router-dom";
import { withTranslation } from "react-i18next";

import heart from "assets/images/heart.png";
import comment from "assets/images/comment.png";
// import user_avata from "assets/images/user-avata.png";
import Image from "assets/images/default-image.png";
import Play from "assets/images/play.png";

import styles from "./_Dictionary.module.scss";
const cx = classnames.bind(styles);

class MainAll extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ArticleArr: []
    };
  }

  componentDidMount() {
    this.props.ArticleContent().then(array => {
      this.setState({
        ArticleArr: array
      });
      this.forceUpdate();
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.ArticleArr !== this.props.ArticleArr) {
      let State = this.state;
      State["ArticleArr"] = nextProps["ArticleArr"];
      this.setState(State);
      return true;
    }
    return false;
  }
  // 이미지 에러시 이미지 대체
  onImageError = e => {
    e.currentTarget.src = Image;
  };
  // 페이지 이동
  goto = item => {
    if (item["ARTICLE_TYPE"] === "1") {
      this.props.history.push(item["ARTICLE_URL"]);
    } else {
      window.open(item["ARTICLE_URL"]);
    }
  };

  render() {
    const { t } = this.props;
    return (
      <div className={cx("dictionary-contents")}>
        <div className={cx("contents-list")}>
          {this.state.ArticleArr.filter(items => items["CATEGORY"] === "0001")
            .length > 0 && (
            <>
              <h2 className={cx("houselife")}>
                {t("Dictionary.houseLife")}
                <button
                  onClick={() => this.props.history.push("/article/house")}
                >
                  {t("Dictionary.more")}
                </button>
              </h2>
              <div className={cx("houselife-items")}>
                <div>
                  {this.state.ArticleArr.filter(
                    items => items["CATEGORY"] === "0001"
                  ).map((items, index) => {
                    if (index < 3) {
                      return (
                        <div
                          onClick={e => this.goto(items)}
                          className={cx("third-cols")}
                          key={items["ARTICLE_ID"]}
                        >
                          <div className={cx("image-cover")}>
                            <img
                              src={items["INFO_THUMB_URL"]}
                              alt="슬기로운 하우스생활"
                              onError={this.onImageError}
                            />
                            {items["ARTICLE_TYPE"] === "2" && (
                              <span>
                                <img src={Play} alt="" />
                              </span>
                            )}
                          </div>
                          <h3>{items["TITLE"]}</h3>
                          <div className={cx("card-footer")}>
                            <span>{items["CREATE_DT"]}</span>
                            {items["ARTICLE_TYPE"] === "1" && (
                              <>
                                <span>
                                  <img src={heart} alt="heart" />
                                  {items["LIKE_CNT"]}
                                </span>
                                <span>
                                  <img src={comment} alt="comment" />
                                  {items["COMMENT_CNT"]}
                                </span>
                              </>
                            )}
                          </div>
                        </div>
                      );
                    }
                    return null;
                  })}
                </div>
              </div>
            </>
          )}
          {this.state.ArticleArr.filter(items => items["CATEGORY"] === "0002")
            .length > 0 && (
            <>
              <h2 className={cx("comic")}>
                {t("Dictionary.comic")}
                <button
                  onClick={() => {
                    this.props.history.push("/article/house/comic");
                  }}
                >
                  {t("Dictionary.more")}
                </button>
              </h2>
              <div className={cx("comics")}>
                <div>
                  {this.state.ArticleArr.filter(
                    items => items["CATEGORY"] === "0002"
                  ).map((items, index) => {
                    if (index < 2) {
                      return (
                        <div
                          onClick={e => this.goto(items)}
                          key={items["ARTICLE_ID"]}
                        >
                          <div className={cx("comic-avata")}>
                            <img
                              src={items["INFO_THUMB_URL"]}
                              alt="컴앤스테이툰"
                              onError={this.onImageError}
                            />
                            {items["ARTICLE_TYPE"] === "2" && (
                              <span>
                                <img src={Play} alt="" />
                              </span>
                            )}
                          </div>
                          <div className={cx("comic-info")}>
                            <h4>
                              <a href={items["ARTICLE_URL"]} target="BLANK">
                                {items["TITLE"]}
                              </a>
                            </h4>
                            {items["SUMMARY"] && <p>{items["SUMMARY"]}</p>}
                            <div className={cx("card-footer")}>
                              <span>{items["CREATE_DT"]}</span>
                              {items["ARTICLE_TYPE"] === "1" && (
                                <>
                                  <span>
                                    <img src={heart} alt="heart" />
                                    {items["LIKE_CNT"]}
                                  </span>
                                  <span>
                                    <img src={comment} alt="comment" />
                                    {items["COMMENT_CNT"]}
                                  </span>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    }
                    return null;
                  })}
                </div>
              </div>
            </>
          )}
          {this.state.ArticleArr.filter(items => items["CATEGORY"] === "0003")
            .length > 0 && (
            <>
              <h2 className={cx("story")}>
                {t("Dictionary.story")}
                <button
                  onClick={() => {
                    this.props.history.push("/article/house/story");
                  }}
                >
                  {t("Dictionary.more")}
                </button>
              </h2>
              <div className={cx("story")}>
                <div>
                  {this.state.ArticleArr.filter(
                    items => items["CATEGORY"] === "0003"
                  ).map((items, index) => {
                    if (index < 4) {
                      return (
                        <div
                          className={cx("story-card")}
                          onClick={e => this.goto(items)}
                          key={items["ARTICLE_ID"]}
                        >
                          <div className={cx("card-image")}>
                            <img
                              src={items["INFO_THUMB_URL"]}
                              alt="card"
                              onError={this.onImageError}
                            />
                            {items["ARTICLE_TYPE"] === "2" && (
                              <span>
                                <img src={Play} alt="" />
                              </span>
                            )}
                          </div>
                          <div className={cx("card-title")}>
                            <a
                              href={"/article/" + items["ARTICLE_ID"]}
                              target="BLANK"
                            >
                              {items["BRAND_NAME"] + " " + items["TITLE"]}
                            </a>
                          </div>
                          <div className={cx("card-footer")}>
                            <span>{items["CREATE_DT"]}</span>
                            {items["ARTICLE_TYPE"] === "1" && (
                              <>
                                <span>
                                  <img src={heart} alt="heart" />
                                  {items["LIKE_CNT"]}
                                </span>
                                <span>
                                  <img src={comment} alt="comment" />
                                  {items["COMMENT_CNT"]}
                                </span>
                              </>
                            )}
                          </div>
                        </div>
                      );
                    }
                    return null;
                  })}
                </div>
              </div>
            </>
          )}
          {this.state.ArticleArr.filter(items => items["CATEGORY"] === "0004")
            .length > 0 && (
            <>
              <h2 className={cx("report")}>
                {t("Dictionary.report")}
                <button
                  onClick={() => {
                    this.props.history.push("/article/house/report");
                  }}
                >
                  {t("Dictionary.more")}
                </button>
              </h2>
              <div className={cx("houselife-report")}>
                <div>
                  {this.state.ArticleArr.filter(
                    items => items["CATEGORY"] === "0004"
                  ).map((items, index) => {
                    if (index < 3) {
                      return (
                        <div
                          onClick={e => this.goto(items)}
                          className={cx("third-cols")}
                          key={items["ARTICLE_ID"]}
                        >
                          <div className={cx("image-cover")}>
                            <img
                              src={items["INFO_THUMB_URL"]}
                              alt="게시물 이미지"
                              onError={this.onImageError}
                            />
                            {items["ARTICLE_TYPE"] === "2" && (
                              <span>
                                <img src={Play} alt="" />
                              </span>
                            )}
                          </div>
                          <h3>{items["TITLE"]}</h3>
                          <div className={cx("card-footer")}>
                            <span>{items["CREATE_DT"]}</span>
                            {items["ARTICLE_TYPE"] === "1" && (
                              <>
                                <span>
                                  <img src={heart} alt="heart" />
                                  {items["LIKE_CNT"]}
                                </span>
                                <span>
                                  <img src={comment} alt="comment" />
                                  {items["COMMENT_CNT"]}
                                </span>
                              </>
                            )}
                          </div>
                        </div>
                      );
                    }
                    return null;
                  })}
                </div>
              </div>
            </>
          )}
          {this.state.ArticleArr.filter(items => items["CATEGORY"] === "0005")
            .length > 0 && (
            <>
              <h2 className={cx("younghouse")}>
                {t("Dictionary.youngHouse")}
                <button
                  onClick={() => {
                    this.props.history.push("/article/young");
                  }}
                >
                  {t("Dictionary.more")}
                </button>
              </h2>
              <div className={cx("young-house")}>
                <div>
                  {this.state.ArticleArr.filter(
                    items => items["CATEGORY"] === "0005"
                  ).map((items, index) => {
                    if (index < 2) {
                      return (
                        <div
                          onClick={e => this.goto(items)}
                          key={items["ARTICLE_ID"]}
                        >
                          <div className={cx("comic-avata")}>
                            <img
                              src={items["INFO_THUMB_URL"]}
                              alt="청년주택의 모든 것"
                              onError={this.onImageError}
                            />
                            {items["ARTICLE_TYPE"] === "2" && (
                              <span>
                                <img src={Play} alt="" />
                              </span>
                            )}
                          </div>
                          <div className={cx("comic-info")}>
                            <h4>
                              <a href={items["ARTICLE_URL"]} target="BLANK">
                                {items["TITLE"]}
                              </a>
                            </h4>
                            {items["SUMMARY"] && <p>{items["SUMMARY"]}</p>}
                            <div className={cx("card-footer")}>
                              <span>{items["CREATE_DT"]}</span>
                              {items["ARTICLE_TYPE"] === "1" && (
                                <>
                                  <span>
                                    <img src={heart} alt="heart" />
                                    {items["LIKE_CNT"]}
                                  </span>
                                  <span>
                                    <img src={comment} alt="comment" />
                                    {items["COMMENT_CNT"]}
                                  </span>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    }
                    return null;
                  })}
                </div>
              </div>
            </>
          )}
          {this.state.ArticleArr.filter(items => items["CATEGORY"] === "0006")
            .length > 0 && (
            <>
              <h2 className={cx("youngknow")}>
                {t("Dictionary.youngHouseKnow")}
                <button
                  onClick={() => {
                    this.props.history.push("/article/young/youngknow");
                  }}
                >
                  {t("Dictionary.more")}
                </button>
              </h2>
              <div className={cx("young-house-info")}>
                <div>
                  {this.state.ArticleArr.filter(
                    items => items["CATEGORY"] === "0006"
                  ).map((items, index) => {
                    if (index < 3) {
                      return (
                        <div
                          onClick={e => this.goto(items)}
                          className={cx("third-cols")}
                          key={items["ARTICLE_ID"]}
                        >
                          <div className={cx("image-cover")}>
                            <img
                              src={items["INFO_THUMB_URL"]}
                              alt="게시물 이미지"
                              onError={this.onImageError}
                            />
                            {items["ARTICLE_TYPE"] === "2" && (
                              <span>
                                <img src={Play} alt="" />
                              </span>
                            )}
                          </div>
                          <h3>
                            <Link to={"/article/" + items["ARTICLE_ID"]}>
                              {items["TITLE"]}
                            </Link>
                          </h3>
                          <div className={cx("card-footer")}>
                            <span>{items["CREATE_DT"]}</span>
                            {items["ARTICLE_TYPE"] === "1" && (
                              <>
                                <span>
                                  <img src={heart} alt="heart" />
                                  {items["LIKE_CNT"]}
                                </span>
                                <span>
                                  <img src={comment} alt="comment" />
                                  {items["COMMENT_CNT"]}
                                </span>
                              </>
                            )}
                          </div>
                        </div>
                      );
                    }
                    return null;
                  })}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}

export default withTranslation()(withRouter(MainAll));
