import React from "react";
import classnames from "classnames/bind";
import { withTranslation } from "react-i18next";
import styles from "./_MobilePostDetailMenu.module.scss";
import user_avata from "assets/images/user-avata.png";
import book_mark from "assets/images/bookmark.png";
import color_kakao from "assets/images/color_kakao.png";
import color_facebook from "assets/images/color_facebook.png";
import color_blog from "assets/images/color_blog.png";
import heart from "assets/images/heart.png";
import link_share from "assets/images/link_share.png";
import heart_color from "assets/images/heart_color.png";
import book_mark_color from "assets/images/bookmark_color.png";

const cx = classnames.bind(styles);

class MobilePostDetailMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  // 스크랩 추가
  addScrap = (e) => {
    this.props.AddScraps(e);
  };

  // 스크랩 제거
  delScrap = (e) => {
    this.props.DeleteScraps(e);
  };

  // 좋아요 추가
  AddLike = (e) => {
    this.props.AddLike(e);
  }

  // 좋아요 제거
  delLike = (e) => {
    this.props.DeleteLike(e);
  }
  render() {
    const {t} = this.props;
    return (
      <div className={cx("fixed-info")}>
        <h3 className={cx("primary_blue")}>{this.props.article["CATEGORY_NAME"]}</h3>
        <h1>
          {this.props.article["TITLE"]}
        </h1>

        <div className={cx("publisher")}>
          <span className={cx("profile")}>
            <img src={user_avata} alt="user-avata" />
          </span>
          {this.props.article["WRITER"]}
        </div>
        <span className={cx("write-date")}>{this.props.article["CREATE_DT"]}</span>

        <div className={cx("util-buttons")}>
          <div>
            <img src={color_kakao} alt="icon" />
          </div>
          <div>
            <img src={color_facebook} alt="icon" />
          </div>
          <div>
            <img src={color_blog} alt="icon" />
          </div>
          <div>
            <img src={link_share} alt="icon" />
          </div>
        </div>
        {this.props.article.IS_LIKE_YN === "Y" ? (
          <button className={cx("like")} onClick={this.delLike}>
            <img src={heart_color} alt="heart" /> {t("PostDetail.like")}&nbsp;
            {this.props.article["LIKE_CNT"]}
          </button>
        ) : (
          <button className={cx("like")} onClick={this.AddLike}>
              <img src={heart} alt="heart" /> {t("PostDetail.like")}&nbsp;
            {this.props.article["LIKE_CNT"]}
          </button>
        )}

        {this.props.article.IS_SCRAP_YN === "Y" ? (
          <button className={cx("scrap")} onClick={this.delScrap}>
            <img src={book_mark_color} alt="icon" /> {t("PostDetail.scrap")}&nbsp;
          </button>
        ) : (
          <button className={cx("scrap")} onClick={this.addScrap}>
              <img src={book_mark} alt="icon" /> {t("PostDetail.scrap")}&nbsp;
          </button>
        )}

        {/* <button className={cx("like")}>
          <img src={heart} alt="icon" /> 좋아요&nbsp;{"1"}
        </button>
        <button className={cx("scrap")}>
          <img src={book_mark} alt="icon" /> 스크랩&nbsp;{"1"}
        </button> */}

        <div className={cx("clear")}></div>
      </div>
    );
  }
}

export default withTranslation()(MobilePostDetailMenu);
