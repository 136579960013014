import React from "react";
import classnames from "classnames/bind";
import { withTranslation } from "react-i18next";
import styles from "./_Compare.module.scss";

import ReactHtmlParser from "react-html-parser";

import check from "assets/images/check.png";

const cx = classnames.bind(styles);

class Compare extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      listIndex: [],
      allCheck: false
    };
  }
  // 체크박스 체크 확인
  checkValue = e => {
    const { listIndex } = this.state;
    let nextState = this.state;
    const checked = e.target.checked;
    let inputValue = e.target.value;
    const newValue = listIndex.concat(inputValue);

    if (checked === true) {
      nextState["listIndex"] = newValue;
      this.setState(nextState);
    } else {
      const array = newValue.filter(item => item !== inputValue);
      nextState["listIndex"] = array;
      this.setState(nextState);
    }
  };
  // 전체 체크
  handleAllCheck = e => {
    if (this.props.comparelist.length < 0) return null;

    let nextState = this.state;

    const comparelist = this.props.comparelist;

    if (!comparelist || !comparelist === undefined) return null;
    const checked = e.target.checked;

    if (checked === true) {
      for (let index in comparelist) {
        document.getElementsByName("listIndex")[index].checked = true;
        nextState["listIndex"].push(index);
      }
    } else {
      for (let index in comparelist) {
        document.getElementsByName("listIndex")[index].checked = false;
        nextState["listIndex"].pop(index);
      }
    }

    nextState["allCheck"] = e.target.checked;

    this.setState(nextState);
  };
  // 리스트에서 삭제
  delList = async () => {
    const { delList } = this.props;
    const { listIndex } = this.state;

    await delList(listIndex);

    let nextState = this.state;
    nextState["allCheck"] = false;
    nextState["listIndex"] = [];
    this.setState(nextState);
  };

  render() {
    const { t } = this.props;
    return (
      <div className={cx("compare")}>
        <div>
          <div className={cx("compare-header")}>
            <p>{t("compare.houseCompare")}</p>
            <button
              className={cx("compare-close")}
              onClick={this.props.closeModal}
            >
              <div className={cx("line")}></div>
              <div className={cx("line")}></div>
            </button>
          </div>
          <div className={cx("clear")}></div>
          <div className={cx("compare-body")}>
            <div>
              <div className={cx("body_head")}>
                <input
                  type="checkbox"
                  id="chk-all"
                  onChange={this.handleAllCheck}
                  checked={this.state.allCheck}
                />
                <label htmlFor="chk-all">
                  <em>
                    <img src={check} alt={"check"} />
                  </em>
                  <span>{t("compare.houseSelect")}</span>
                </label>
                <button onClick={this.delList}>{t("compare.del")}</button>
              </div>
              <div className={cx("tableWrap")}>
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <p>{t("compare.house")}</p>
                      </td>
                      {this.props.comparelist.map((items, index) => {
                        return (
                          <td key={index}>
                            <input
                              type="checkbox"
                              id={"chk-piece" + index}
                              value={index}
                              onChange={this.checkValue}
                              name="listIndex"
                            />
                            <label htmlFor={"chk-piece" + index}>
                              <em>
                                <img src={check} alt={"check"} />
                              </em>
                            </label>
                            <img
                              src={items["pictureList"][0]["THUMB_URL"]}
                              alt="compare"
                            />
                            <h1 key={index}>
                              {items["brand"]["BRAND_NAME"] +
                                " " +
                                items["house"]["HOUSE_NAME"]}
                            </h1>
                            <span>({items["brand"]["OPEN_DATE"]})</span>
                          </td>
                        );
                      })}
                    </tr>
                    <tr>
                      <td>
                        <p>{t("compare.rentType")}</p>
                      </td>

                      {this.props.comparelist.map((items, index) => {
                        return (
                          <td key={index}>
                            {items["house"]["RENT_TYPE_NAME"]}
                          </td>
                        );
                      })}
                    </tr>
                    <tr>
                      <td>
                        <p>{t("compare.area")}</p>
                      </td>
                      {this.props.comparelist.map((items, index) => {
                        return <td key={index}>{items["house"]["ADDRESS"]}</td>;
                      })}
                    </tr>
                    <tr>
                      <td>
                        <p>{t("compare.subway")}</p>
                      </td>
                      {this.props.comparelist.map((item, index) => {
                        return (
                          <td key={index}>
                            {item["subwayList"]
                              .filter((items, index) => index < 2)
                              .map((item, index) => {
                                return (
                                  <p key={index}>{item["SUB_NAME"] + "역"}</p>
                                );
                              })}
                          </td>
                        );
                      })}
                    </tr>
                    <tr>
                      <td>
                        <p>{t("compare.university")}</p>
                      </td>
                      {this.props.comparelist.map((item, index) => {
                        return (
                          <td key={index}>
                            {item["universityList"]
                              .filter((items, index) => index < 3)
                              .map((item, index) => {
                                return <p key={index}>{item["UNI_NAME"]}</p>;
                              })}
                          </td>
                        );
                      })}
                    </tr>
                    <tr>
                      <td>
                        <p>{t("compare.houseType")}</p>
                      </td>
                      {this.props.comparelist.map((item, index) => {
                        return (
                          <td key={index}>
                            <p>{item["house"]["HOUSE_TYPE"]}</p>
                          </td>
                        );
                      })}
                    </tr>
                    <tr>
                      <td>
                        <p>{t("compare.rentFee")}</p>
                      </td>
                      {this.props.comparelist.map((item, index) => {
                        return (
                          <td key={index}>
                            <p>{item["house"]["RENTFEE"]}</p>
                          </td>
                        );
                      })}
                    </tr>
                    <tr>
                      <td>
                        <p>{t("compare.deposit")}</p>
                      </td>
                      {this.props.comparelist.map((item, index) => {
                        return (
                          <td key={index}>
                            <p>{item["house"]["DEPOSIT"]}</p>
                          </td>
                        );
                      })}
                    </tr>
                    <tr>
                      <td>
                        <p>{t("compare.managePay")}</p>
                      </td>
                      {this.props.comparelist.map((item, index) => {
                        return (
                          <td key={index}>
                            <p>{item["house"]["MANAGE_FEE_AMT"]}</p>
                          </td>
                        );
                      })}
                    </tr>
                    <tr>
                      <td>
                        <p>{t("compare.roomCount")}</p>
                      </td>
                      {this.props.comparelist.map((item, index) => {
                        return (
                          <td key={index}>
                            <p>{ReactHtmlParser(item["house"]["ROOM_CNT"])}</p>
                          </td>
                        );
                      })}
                    </tr>
                    <tr>
                      <td>
                        <p>{t("compare.roomCondition")}</p>
                      </td>
                      {this.props.comparelist.map((item, index) => {
                        return (
                          <td key={index}>
                            <p>{item["house"]["MOVEIN_CONDITION"]}</p>
                          </td>
                        );
                      })}
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className={cx("compare-bg")}></div>
      </div>
    );
  }
}

export default withTranslation()(Compare);
