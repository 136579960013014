import React from "react";
import { withCookies } from "react-cookie";
import { withRouter } from "react-router-dom";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as UserActions from "stores/modules/user";
import { withTranslation } from "react-i18next";

import qs from 'query-string';
import client from 'lib/client';
import { encode } from "lib/encrypt";

class SNSLoginContainer extends React.Component {

  componentDidMount = async () => {
    const {t} = this.props;
    try {

      const { UserActions } = this.props;
      const {type} = this.props.match.params;
      const {code} = qs.parse(this.props.location.search);

    if(type === "kakaotalk"){
      const res = await UserActions.LoginKaKao({code});
      let LoginData = {
        member_name: res["data"]["result"]["data"]["member"]["MEMBER_NAME"],
        access_token: res["headers"]["x-access-token"],
        refresh_token: res["headers"]["x-refresh-token"]
      };
      
      LoginData = encode(JSON.stringify(LoginData));
      
      this.props.cookies.set("auth", LoginData, {
        path: "/"
      });
      this.props.history.replace('/');
    }
    else if (type === "naver") {
      const res = await UserActions.LoginNaver({code}); 
      
      if(res.data.result.resCode === "0000"){

      let LoginData = {
        member_name: res["data"]["result"]["data"]["member"]["MEMBER_NAME"],
        access_token: res["headers"]["x-access-token"],
        refresh_token: res["headers"]["x-refresh-token"]
      };
      
      LoginData = encode(JSON.stringify(LoginData));
      
      this.props.cookies.set("auth", LoginData, {
        path: "/"
      });

      this.props.history.replace('/');
    } else {
      throw new Error('');
    }

    } else {
      alert(t("login.notNormal"));
      window.location.replace('/');
    }
  } catch(e){
    console.log(e);
      alert(t("login.notNormal"));
    window.location.replace('/');
  }
}

  render(){
    return null;
  }

}
export default withTranslation()(withCookies(
  connect(null, dispatch => ({
    UserActions: bindActionCreators(UserActions, dispatch)
  }))(withRouter(SNSLoginContainer))
));
