export const resCode = code => {
  let resCode = code.data.result.resCode;

  //  0000: 정상처리
  //  0404: 데이터 없음 -> 404 페이지 표시
  //  0506: 서비스 처리 오류 -> 506 페이지 표시
  //  1100: 세션 만료됨 -> 재로그인처리 또는 메인화면으로 이동 (토큰 방식으로 변경되어 사용X)
  //  1101: 토큰 만료됨 -> 재로그인처리 또는 메인화면으로 이동
  //  1200: 디바이스 토큰 부족 -> 디바이스 등록 처리 (앱일때 사용)
  //  2100: 접근권한 부족 -> 메시지처리 또는 메인화면으로 이동
  //  기타 Response코드 -> Alert(Toast) 메시지처리

  if (resCode === "0000") {
    return code.data.result.data;
  } else if (resCode === "0001" || resCode === "0404") {
    window.location.replace('/error/404');
    throw new Error("데이터가 존재하지않습니다.");
  } else if (resCode === "0506") {
    window.location.replace('/error/506');
    throw new Error("서비스 오류가 발생했습니다.");
  } else if (resCode === "1100" || resCode === "1101") {
    alert("로그인정보가 만료되었습니다.\n다시 로그인을 해주세요.");
    throw new Error("로그인정보가 만료되었습니다.\n다시 로그인을 해주세요.");
  } else {
    alert(`${code.data.result.resMessage}`);
    throw new Error(`${code.data.result.resMessage}`);
  }
};
