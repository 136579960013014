import React from "react";
import MainTemplate from "components/base/MainTemplate";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { withRouter } from "react-router";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as UserActions from "stores/modules/user";
import * as LangActions from "stores/modules/lang";
import { withCookies } from "react-cookie";
import i18n from "lang/i18n";
import { withTranslation } from "react-i18next";

import { encode, decode } from "lib/encrypt";

import Main from "containers/common/main/MainContainer";

import Register from "containers/user/register/UserRegsterContainer";
import RegisterDone from "components/user/RegisterDone";
import SNSLogin from "containers/user/sns/SNSLoginContainer";

import Intro from "components/company/intro/Intro";
import Terms from "components/company/terms/Terms";
import Privacy from "components/company/privacy/Privacy";
import Search from "containers/search/SearchContainer";
import Detail from "containers/house/housecontainer";
import Dictionary from "containers/common/dictionary/detail/DictinaryContainer";
// import Dictionary from "components/dictionary";
import DictionaryPost from "containers/dictionary/PostDetailContainer";
import Mypage from "containers/user/mypage/MypageContainer";
// import InquiryDetail from "components/user/mypage/details/Inquiry/detail/InquiryDetail";
import InquiryDetailContainer from "containers/user/inquiry/inquiryDetailContainer";
import PasswordFind from "containers/user/passwordfind/PasswordFindContainer";
// import EmailCheck from "containers/user/emailCheck";
import EmailOk from "containers/user/mailOkContainer/MailOkContainer";
import ExpiredEmail from "components/user/ExpiredEmail";
import ErrorPage from "components/common/errorPage";
import PasswordEmailCheck from "containers/user/PasswordEmailCheckContainer/PasswordEmailCheckContainer";
import SNSLoginContainer from "containers/user/sns/SNSLoginContainer";
import DeclinedEmail from "containers/user/DeclinedEmailContainer/DeclinedEmailContainer";

import "./App.css";

class App extends React.Component {
  constructor() {
    super();
    this.state = {
      isLoading: true,
      member_name: "",
      created: false
    };
  }

  componentDidMount = async () => {
    await this.language();
    this.Session_Refresh();
  };

  language = async () => {
    const { LangActions } = this.props;
    const myStorage = window.localStorage;

    const lang = myStorage.getItem("i18nextLng");

    if (lang === "en-US") {
      LangActions.Lang_En();
    } else {
      LangActions.Lang_Ko();
    }

    return;
  };

  Session_Refresh = async () => {
    const { t } = this.props;
    try {
      const token = this.props.cookies.get("auth");

      if (token !== null && token !== undefined && token !== "") {
        if (token.trim() === "") {
          this.props.cookies.remove("auth");
          this.props.history.replace("/");
          return;
        }
        const refresh_token = JSON.parse(decode(token))["refresh_token"];
        // console.log(refresh_token);
        const res = await this.props.UserActions.Session_Refresh({
          refresh_token
        });

        if (res["data"]["result"]["resCode"] !== "0000") {
          alert(t("main.loginAlert"));
          await this.Logout();
          return false;
        }

        let LoginData = {
          member_name: res["data"]["result"]["data"]["member"]["MEMBER_NAME"],
          access_token: res.data.result.data["ACCESS_TOKEN"],
          refresh_token
        };

        LoginData = encode(JSON.stringify(LoginData));

        this.props.cookies.set("auth", LoginData, { path: "/" });
      }
    } catch (e) {
      await this.Logout();
      alert(t("main.loginAlert"));
    } finally {
      this.setState({ ...this.state, isLoading: false });
    }
  };

  Logout = async () => {
    await this.props.UserActions.Logout();
    await this.props.cookies.remove("auth", { path: "/" });
    await this.props.history.replace("/");
  };

  // 로그인이 되어있을때
  PrivateRoute = ({ component: Component, ...other }) => {
    const { isLogined } = this.props;
    const { isLoading } = this.state;

    return (
      <Route
        {...other}
        render={props => {
          if (isLoading) return null;

          if (isLogined)
            return (
              <Redirect
                to={{
                  pathname: "/"
                }}
              />
            );

          return <Component {...props} />;
        }}
      />
    );
  };

  render() {
    if (this.state.isLoading) return null;
    return (
      <BrowserRouter htmlAttributes={{ lang: i18n.language }}>
        <MainTemplate thisPath={this.props.location.pathname}>
          <Switch>
            {/* 메인페이지 */}
            <Route exact path="/" component={Main} />

            {/* 일반회원 회원가입 페이지 */}
            <Route
              exact
              path="/user/register"
              component={Register}
            />

            <Route //일반회원 회원가입 완료페이지
              exact
              path="/user/registerdone"
              component={RegisterDone}
            />

            <Route exact path="/user/passwordfind" component={PasswordFind} />

            {/* 하우스검색 페이지 */}
            <Route exact path="/search" component={Search} />

            <Route // 하우스 상세페이지
              exact
              path="/house/:house"
              component={Detail}
            />

            {/* 청년주거백과 */}
            <Route exact strict path="/article" component={Dictionary} />
            {/* 청년주거백과 자체제작 게시물 상세페이지 */}

            <Route exact strict path="/article/house" component={Dictionary} />
            <Route exact strict path="/article/young" component={Dictionary} />
            <Route
              exact
              strict
              path="/article/house/:sub(comic|houselife|story|report)"
              component={Dictionary}
            />
            <Route
              exact
              strict
              path="/article/young/:type(youngknow)"
              component={Dictionary}
            />

            <Route exact path="/article/:type" component={DictionaryPost} />

            {/* 마이페이지 */}
            <Route exact path="/user/mypage/" component={Mypage} />
            <Route exact path="/user/mypage/:type" component={Mypage} />
            <Route
              exact
              path="/user/mypage/inquiry/detail/:inquiry_id"
              component={InquiryDetailContainer}
            />

            {/* 이메일 체크 페이지 */}
            {/* <Route exact path="/account/confirmEmail/:email" component={EmailCheck} /> */}

            {/* 이메일 패스워드 변경 페이지 */}
            <Route
              exact
              path="/account/resetPassword/:emailKey"
              component={PasswordEmailCheck}
            />

            {/* 이메일인증 승인됨 */}
            {/* <Route exact path="/account/confirmEmail/:confirmId " component={EmailOk} /> */}
            <Route
              exact
              path="/account/confirmEmail/:confirmId"
              component={EmailOk}
            />
            <Route
              exact
              path="/account/refuseConfirm/:confirmId"
              component={DeclinedEmail}
            />
            {/* 패스워드 변경 이메일 연동 페이지 */}
            <Route
              exact
              path="/user/passwordemailcheck/:emailKey"
              // path="/account/resetPassword/:emailKey"
              component={PasswordEmailCheck}
            />

            <Route //이메일 인증 기간 만료됨
              exact
              path="/user/expiredemail"
              component={ExpiredEmail}
            />

            {/* 컴앤스테이 회사소개 */}
            <Route exact path="/company/intro" component={Intro} />

            {/* 컴앤스테이 이용약관 */}
            <Route exact path="/company/terms" component={Terms} />

            {/* 개인정보 취급정보 방침 */}
            <Route exact path="/company/privacy" component={Privacy} />

            {/* TODO: 없는 페이지 표시 수정 */}
            <Route exact path="/error/:errorCode" component={ErrorPage} />

            <Route
              exact
              path="/sns/:type/oauth/"
              component={SNSLoginContainer}
            />

            <Route component={ErrorPage} />
          </Switch>
        </MainTemplate>
      </BrowserRouter>
    );
  }
}

export default withTranslation()(
  withCookies(
    connect(
      state => ({
        isLogined: state.user.auth.user.isLogined
      }),
      dispatch => ({
        UserActions: bindActionCreators(UserActions, dispatch),
        LangActions: bindActionCreators(LangActions, dispatch)
      })
    )(withRouter(App))
  )
);
