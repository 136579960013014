import React from "react";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { withCookies } from "react-cookie";
import { withTranslation } from "react-i18next";

import { connect } from "react-redux";
import * as UserAction from "stores/modules/user";

import PasswordFind from 'components/user/PasswordFind';

import { resCode } from "lib/resCode";

class PasswordFindContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  //비밀번호 변경 함수
  passwordFind = async ({EMAIL}) => {
    const { UserAction } = this.props;
    const {t} = this.props;
    try {
      const res = await UserAction.PasswrodFind({EMAIL});
      const data = resCode(res);

      alert(res.data.result.resMessage || t("myPage.pwdMailSend"));
      
      if (res.data.result.resCode === "0000") {
        this.props.history.push("/");
      }

      return data;
    } catch (e) {
      console.log(e);
    }
  };

  render() {
    return <PasswordFind passwordFind={this.passwordFind}/>;
  }
}

export default withTranslation()(withCookies(
  connect(null, dispatch => ({
    UserAction: bindActionCreators(UserAction, dispatch)
  }))(withRouter(PasswordFindContainer))
));
