import React from "react";
import classnames from "classnames/bind";
import { withCookies } from "react-cookie";
import _ from "lodash";
import { Link, withRouter } from "react-router-dom";

import Slider from "react-slick";

import Popularity from "./popularity/Popularity";
import ViewedHouse from "./viewedHouse/ViewedHouse";
import FavHouse from "./favHouse/FavHouse";

import search from "assets/images/Search.png";
import plus_icon from "assets/images/Union.png";
import Image from "assets/images/default-image.png";

import category1 from "assets/images/school.png";
import category2 from "assets/images/subway.png";
import category4 from "assets/images/house.png";
import category3 from "assets/images/pin.png";

import InhabiMobile from "components/common/InhabiMobile/InhabiMobile";
import Popup from "components/common/Popup/Popup";
import EventPopup from "components/common/Main/EventPopup";
import Login from "containers/user/login/UserLoginContainer";

// import Login from "components/user/Login/Login";

import styles from "./_Main.module.scss";
import { withTranslation } from "react-i18next";

const cx = classnames.bind(styles);

class Main extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formActive: false,
      isLoading: true,
      active0: true,
      active1: false,
      active2: false,
      active3: false,
      nowSearchPh: "지역",
      favhouselist: [],
      bannerList: [],
      popupList: [],
      popupLink: "",
      bannerCount: 0,
      favhouselist_len: "",
      viewtype: "recent",
      nowSearchKeyword: "",
      isModalOpen: false,
      isEventOpen: false,
      popupOpen : false
    };
    this.banner = React.createRef();
  }

  async componentDidMount() {
    const { t } = this.props;
    document.getElementById("mainTemplate_goPrev_btn").style.display = "none";

    // 검색 도우미 대기 시간
    this.PullSearchHelper = _.debounce(this.PullSearchHelper, 300, {
      maxWait: 1000
    });

    // 베너 정보 가져오기
    await this.getBanner();
    // 로그인 정보 가져오기
    try {
      if (this.props.cookies.cookies.hasOwnProperty("auth")) {
        const { auth } = this.props.cookies.cookies;

        if (auth.trim() === "") {
          this.props.cookies.remove("auth");
          let nextState = this.state;
          nextState["isLoading"] = false;
          this.setState(nextState);

          return;
        }

        this.props.SeeFavHouse().then(value => {
          let nextState = this.state;

          if (value === null || value === undefined || value === "") {
            nextState["isLoading"] = false;
            this.setState(nextState);
            return;
          }
          nextState["favhouselist"] = value.houseList;
          nextState["favhouselist_len"] = value.houseList.length;
          nextState["isLoading"] = false;
          this.setState(nextState);
        });
      } else {
        let nextState = this.state;
        nextState["isLoading"] = false;
        this.setState(nextState);
        return false;
      }
    } catch (e) {
      alert(t("main.loginAlert"));
      window.location.replace("/");
    }
  }

  componentWillUnmount() {
    
  }
  // 로그인창 열기
  openModal = e => {
    if (e !== undefined && e !== null) {
      e.preventDefault();
    }
    this.setState({ isModalOpen: true });
  };
  // 로그인창 닫기
  closeModal = e => {
    this.setState({ isModalOpen: false });
  };

  // 검색 설정
  changeActive = e => {
    const { t } = this.props;
    let selected = e;

    this.setState({
      active: selected
    });

    if (e === 1) {
      this.setState({
        nowSearchPh: t("common.location1")
      });
    } else if (e === 2) {
      this.setState({
        nowSearchPh: t("common.location2")
      });
    } else if (e === 3) {
      this.setState({
        nowSearchPh: t("common.location3")
      });
    } else {
      this.setState({
        nowSearchPh: t("common.location3")
      });
      return;
    }
  };

  // 검색어 입력 제어
  SearchHasData = e => {
    const { t } = this.props;
    const value = e.target.value;
    // eslint-disable-next-line
    const regexp = /[\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"]/gi;

    if (regexp.test(value)) {
      alert(t("main.searchAlert"));
      return;
    }

    let nextState = this.state;

    nextState["nowSearchKeyword"] = value;

    this.setState(nextState);

    this.PullSearchHelper();
  };

  // 검색도우미 호출
  PullSearchHelper = () => {
    const { t } = this.props;
    if (
      !this.state.nowSearchKeyword ||
      this.state.nowSearchKeyword === "" ||
      this.state.nowSearchKeyword === " "
    ) {
      return null;
    }

    // eslint-disable-next-line
    const regexp = /[\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"]/gi;

    if (regexp.test(this.state.NowSearchKeyword)) {
      alert(t("main.searchAlert"));
      return;
    }

    this.props.SearchHelp(this.state.nowSearchKeyword);
  };

  // 최근본 하우스, 관심하우스 탭 변경
  ChangeView = () => {
    let NextState = this.state;
    if (NextState["viewtype"] === "recent") {
      if (JSON.stringify(this.props.cookies) === "{}") {
        this.openModal();
        return false;
      } else {
        NextState["viewtype"] = "favhouse";
        this.setState({ NextState });
      }
    } else if (NextState["viewtype"] === "favhouse") {
      NextState["viewtype"] = "recent";
      this.setState({ NextState });
    }
  };

  // 더보기
  MoreSee = e => {
    e.preventDefault();
    if (this.state.viewtype === "recent") {
      window.location.replace("/user/mypage");
    } else if (this.state.viewtype === "favhouse") {
      if (!this.props.cookies.get("auth")) {
        this.openModal();
        return false;
      } else {
        window.location.replace("/user/mypage/interest");
      }
    }
  };

  // 관심하우스 추가
  AddFav = async houseid => {
    if (!this.props.isLogined) {
      this.setState({ isModalOpen: true });
      return false;
    }

    if (!this.props.cookies.get("auth")) {
      this.openModal();

      return false;
    } else {
      await this.props.AddFavHouse(houseid);
      return;
    }
  };

  // 관심하우스 제거
  DelFav = async houseid => {
    if (!this.props.isLogined) {
      this.setState({ isModalOpen: true });
      return false;
    }

    if (JSON.stringify(this.props.cookies) === "{}") {
      this.openModal();
      return false;
    } else {
      await this.props.deleteFavHouse(houseid);

      return;
    }
  };

  // 검색 이동
  Search = e => {
    const { active0, active1, active2, active3 } = this.state;
    const { t } = this.props;
    e.preventDefault();
    if (this.state.nowSearchKeyword === "") {
      this.props.history.push("/search");
      return;
    }

    if (this.state.nowSearchKeyword.length < 2) {
      alert(t("main.searchLengthAlert"));
      return false;
    }

    let filter = [];

    if (active0) {
      this.props.SearchHouse(this.state.nowSearchKeyword);
      return;
    }
    if (active1) {
      filter.push("HOUSE2030");
    }
    if (active2) {
      filter.push("SHAREHOUSE");
    }
    if (active3) {
      filter.push("PREMIUM_HOUSE");
    }

    this.props.SearchHouse(this.state.nowSearchKeyword, filter);
    // 검색결과 가 나온 하우스 검색 페이지로 이동 + 값 post 방식으로 보낼거임
  };

  // 검색 조건 설정에 따른 검색
  SearchClick = (e, item) => {
    e.preventDefault();
    const { active0, active1, active2, active3 } = this.state;

    let filter = [];

    if (active0) {
      this.props.SearchHouse(item["KEYWORD"]);
      return;
    }
    if (active1) {
      filter.push("HOUSE2030");
    }
    if (active2) {
      filter.push("SHAREHOUSE");
    }
    if (active3) {
      filter.push("PREMIUM_HOUSE");
    }

    this.props.SearchHouse(item["KEYWORD"], filter);
  };

  // 검색 조건 설정
  onActiveClick = type => {
    let nextState = this.state;
    nextState[type] = !nextState[type];

    if (nextState["active1"] || nextState["active2"] || nextState["active3"]) {
      nextState["active0"] = false;
    } else {
      nextState["active1"] = false;
      nextState["active2"] = false;
      nextState["active3"] = false;
      nextState["active0"] = true;
    }

    this.setState(nextState);
  };

  // 이미지 에러시 이미지 대체
  onImageError = e => {
    e.currentTarget.src = Image;
  };

  // 검색으로 이동
  MoreContent = () => {
    window.location.replace("/search");
  };

  // 페이지 이동
  goto = e => {
    window.location.href = e;
  };

  // 포커스 블러 변경
  ChangeFocutBlur = e => {
    const type = e.type;

    setTimeout(() => {
      let nextState = this.state;

      if (type === "focus") {
        nextState["formActive"] = true;
      } else {
        nextState["formActive"] = false;
      }

      this.setState(nextState);
    }, 200);
  };

  // 베너이동
  goBanner = e => {
    window.open(e);
  };
  // 베너 정보 가져오기
  getBanner = () => {
    const MyStorage = localStorage;
    let popup = MyStorage.getItem("popupOpen");
    let today = new Date();
    let date =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();
    let nextState = this.state;
    nextState["bannerList"] = this.props.bannerList;
    if (this.props.popupList[0]) {
      nextState["popupList"] = this.props.popupList[0];
      nextState["popupLink"] = this.props.popupList[0].LINK_URL;

      if (popup !== date) {
        nextState["isEventOpen"] = true;
      }

    }
    nextState["bannerCount"] = this.props.bannerList.length;
    this.setState(nextState);
  };
  // 팝업 닫기
  closeEvent = () => {
    let nextState = this.state;
    nextState["isEventOpen"] = false;
    this.setState(nextState);
    let today = new Date();
    let date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();
    if(this.state.popupOpen){
      const MyStorage = localStorage;
      let popup = MyStorage.getItem("popupOpen");
      if (popup) {
        if (popup === date){

        }else{
          MyStorage.setItem("popupOpen", date);
        } 
      }else{
          MyStorage.setItem("popupOpen",date);
      }

    }
  };
  // 팝업 링크
  popupLink = () => {
    if (this.state.popupLink !== "" && this.state.popupLink !== undefined) {
      window.open(this.state.popupLink);
      let nextState = this.state;
      nextState["isEventOpen"] = false;
      this.setState(nextState);
    }
  };
  // 팝업 체크 박스 체크 확인
  popupCheck = () => {
    let nextState = this.state;
    nextState['popupOpen'] = !this.state.popupOpen;
    this.setState(nextState);
  }

  render() {
    const { active0, active1, active2, active3 } = this.state;
    const { t } = this.props;
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 2,
      slidesToScroll: 2,
      autoplay: true,
      autoplaySpeed: 3000,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesPerRow: 1,
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: false,
            autoplay: true,
            autoplaySpeed: 3000
          }
        }
      ]
    };

    return (
      <div>
        <div className={cx("content")}></div>
        <div className={cx("main-banner")}>
          <div className={cx("title")}>
            <h1>
              Where do you
              <br />
              want to live?
            </h1>
          </div>

          <div className={cx("tab")}>
            <div className={cx("tab-menu-wrap")}>
              <ul>
                <li
                  onClick={() => this.onActiveClick("active1")}
                  className={cx({ active: active1 })}
                >
                  {t("common.youthhouse")}
                </li>
                <li
                  onClick={() => this.onActiveClick("active2")}
                  className={cx({ active: active2 })}
                >
                  {t("common.sharehouse")}
                </li>
                <li
                  onClick={() => this.onActiveClick("active3")}
                  className={cx({ active: active3 })}
                >
                  {t("common.oneroom")}
                </li>
              </ul>
              <form className={cx("tab-menu-form")} onSubmit={this.Search}>
                <input
                  type="text"
                  placeholder={this.state.nowSearchPh}
                  className={cx("main-search-input")}
                  value={this.state.nowSearchKeyword}
                  onChange={this.SearchHasData}
                  onFocus={this.ChangeFocutBlur}
                  onBlur={this.ChangeFocutBlur}
                />
                <button type="submit">검색</button>
                <img src={search} alt={"search"} />
              </form>
              <div
                className={
                  this.state.formActive
                    ? cx("form-active")
                    : cx("form-unactive")
                }
              >
                {this.props.keyword_list &&
                  this.props.keyword_list.map((item, index) => (
                    <div
                      className={cx("help-content")}
                      key={index}
                      onClick={e => this.SearchClick(e, item)}
                    >
                      {item["CATEGORY"] === "1" && (
                        <img src={category1} alt="대학교" />
                      )}
                      {item["CATEGORY"] === "2" && (
                        <img src={category2} alt="대학교" />
                      )}
                      {item["CATEGORY"] === "3" && (
                        <img src={category3} alt="대학교" />
                      )}
                      {item["CATEGORY"] === "4" && (
                        <img src={category4} alt="대학교" />
                      )}
                      <p>{item["KEYWORD"]}</p>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
        <div className={cx("content")}>
          {this.props.todayHouse_len !== 0 && (
            <div className={cx("popularity")}>
              <div className={cx("content")}>
                <h2>{t("common.hothouse")}</h2>
                <div className={cx("popularity-items")}>
                  <div>
                    {this.props.todayHouseArr.map(item => {
                      return (
                        <Popularity
                          key={item["HOUSE_ID"]}
                          HOUSE_URL={item["HOUSE_URL"]}
                          INFO_THUMB_URL={item["INFO_THUMB_URL"]}
                          RENT_TYPE_NAME={item["RENT_TYPE_NAME"]}
                          HOUSE_TYPE_NAME={item["HOUSE_TYPE_NAME"]}
                          BRAND_NAME={item["BRAND_NAME"]}
                          HOUSE_NAME={item["HOUSE_NAME"]}
                          ADDRESS={item["ADDRESS"]}
                          RENTFEE={item["RENTFEE"]}
                          DEPOSIT={item["DEPOSIT"]}
                          HOUSE_ID={item["HOUSE_ID"]}
                          FAVORITED_YN={item["IS_FAVORITED_YN"]}
                          favHouseList_len={this.state.favhouselist_len}
                          favHouseList={this.state.favhouselist}
                          AddFav={this.AddFav}
                          DelFav={this.DelFav}
                        />
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className={cx("recently-viewed")}>
            <div className={cx("content")}>
              <h2>
                <span
                  onClick={() => this.ChangeView()}
                  className={
                    this.state.viewtype === "recent" ? cx("seltype") : null
                  }
                >
                  {t("common.recentsearch")}
                </span>
                &nbsp;&nbsp;&nbsp;|
                <span
                  onClick={() => this.ChangeView()}
                  className={
                    this.state.viewtype === "favhouse" ? cx("seltype") : null
                  }
                >
                  &nbsp;&nbsp;&nbsp; {t("common.interest")}
                </span>
                <Link to="/" onClick={e => this.MoreSee(e)}>
                  {t("common.more")}
                </Link>
              </h2>
              {this.state.viewtype === "recent" ? (
                <div className={cx("recently-viewed-items")}>
                  <div>
                    {this.props.viewdhouse_arr
                      .filter((_item, index) => index < 4)
                      .map(items => {
                        return (
                          <ViewedHouse
                            key={items["HOUSE_ID"]}
                            HOUSE_URL={items["HOUSE_URL"]}
                            INFO_THUMB_URL={items["INFO_THUMB_URL"]}
                            RENT_TYPE_NAME={items["RENT_TYPE_NAME"]}
                            HOUSE_TYPE_NAME={items["HOUSE_TYPE_NAME"]}
                            BRAND_NAME={items["BRAND_NAME"]}
                            HOUSE_NAME={items["HOUSE_NAME"]}
                            ADDRESS={items["ADDRESS"]}
                            RENTFEE={items["RENTFEE"]}
                            DEPOSIT={items["DEPOSIT"]}
                            HOUSE_ID={items["HOUSE_ID"]}
                            FAVORITED_YN={items["IS_FAVORITED_YN"]}
                            favHouseList_len={this.state.favhouselist_len}
                            favHouseList={this.state.favhouselist}
                            viewdhouse_len={this.props.viewdhouse_len}
                            AddFav={this.AddFav}
                            DelFav={this.DelFav}
                          />
                        );
                      })}

                    {this.props.viewdhouse_len < 4 && (
                      <>
                        <div
                          className={cx("more-house")}
                          onClick={this.MoreContent}
                        >
                          <div className={cx("more-content")}>
                            <span>{t("common.ismore")}</span>
                            <button>
                              <img src={plus_icon} alt="plus" />
                            </button>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              ) : (
                <div className={cx("recently-viewed-items")}>
                  <div>
                    {this.state.favhouselist
                      .filter((_items, index) => index < 4)
                      .map(items => {
                        return (
                          <FavHouse
                            key={items["HOUSE_ID"]}
                            HOUSE_URL={items["HOUSE_URL"]}
                            INFO_THUMB_URL={items["INFO_THUMB_URL"]}
                            RENT_TYPE_NAME={items["RENT_TYPE_NAME"]}
                            HOUSE_TYPE_NAME={items["HOUSE_TYPE_NAME"]}
                            BRAND_NAME={items["BRAND_NAME"]}
                            HOUSE_NAME={items["HOUSE_NAME"]}
                            ADDRESS={items["ADDRESS"]}
                            RENTFEE={items["RENTFEE"]}
                            DEPOSIT={items["DEPOSIT"]}
                            HOUSE_ID={items["HOUSE_ID"]}
                            favHouseList_len={this.state.favhouselist_len}
                            favHouseList={this.state.favhouselist}
                            viewdhouse_len={this.props.viewdhouse_len}
                            AddFav={this.AddFav}
                            DelFav={this.DelFav}
                          />
                        );
                      })}
                    {this.state.favhouselist_len < 4 && (
                      <>
                        <div
                          className={cx("more-house")}
                          onClick={this.MoreContent}
                        >
                          <div className={cx("more-content")}>
                            <span>{t("common.ismore")}</span>
                            <button>
                              <img src={plus_icon} alt="plus" />
                            </button>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className={cx("event-banner")} ref={this.banner}>
            <Slider {...settings}>
              {this.state.bannerList &&
                this.state.bannerList.map((val, index) => {
                  return (
                    <img
                      key={index}
                      src={val["BANNER_PIC_URL"]}
                      alt="bannerUrl"
                      onClick={() => this.goBanner(val["LINK_URL"])}
                    />
                  );
                })}
            </Slider>
          </div>
        </div>
        <div className={cx("inhabitation")}>
          <div className={cx("inhabi-inner")}>
            <div className={cx("inhabi-text")}>
              <h1>
                {t("common.comeenstay")}
                <br />
                {t("common.livingencyclo")}
              </h1>
            </div>
            <div className={cx("inhabi-content")}>
              <div className={cx("inhabi-contents-left")}>
                <Link
                  to={{
                    pathname: "/article/young",
                    state: {
                      nowCategory: "young"
                    }
                  }}
                >
                  <div>
                    <h3>
                      {t("common.everythingofhouse")}
                      <br />
                      <span>{t("common.everymore")}</span>
                    </h3>
                    <p>
                      {t("main.whatThat")}
                      <br />
                      {t("main.noCar")}
                    </p>
                  </div>
                </Link>
                <Link
                  to={{
                    pathname: "/article/house",
                    state: {
                      nowCategory: "house",
                      nowDetailCategory: "0001"
                    }
                  }}
                >
                  <div>
                    <h3>
                      {t("main.fun")}
                      <br />
                      <span>{t("main.funLife")}</span>
                    </h3>
                    <p>
                      {t("main.shareHouseAbout")}
                      <br />
                      {t("main.moreInfo")}
                      <br />
                      {t("main.toKnow")}
                    </p>
                  </div>
                </Link>
                <Link
                  to={{
                    pathname: "/article/young/youngknow",
                    state: {
                      nowCategory: "young",
                      category: false
                    }
                  }}
                >
                  <div>
                    <h3>
                      {t("main.youngHome")}
                      <br />
                      <span>{t("main.youngHomeKnow")}</span>
                    </h3>
                    <p>
                      {t("main.youngHouseAbout")}
                      <br />
                      {t("main.youngInfo")}
                      <br />
                      {t("main.moreKnow")}
                    </p>
                  </div>
                </Link>
                <Link
                  to={{
                    pathname: "/article/house/comic",
                    state: {
                      nowCategory: "house",
                      nowDetailCategory: "0002"
                    }
                  }}
                >
                  <div>
                    <h3>
                      {t("main.shareHouse")}
                      <br />
                      <span>{t("main.inLiveStory")}</span>
                    </h3>
                    <p>
                      {t("main.titleVol")}
                      <br />
                      {t("main.situation")}
                    </p>
                  </div>
                </Link>
              </div>
              <div className={cx("inhabi-contents-right")}>
                <Link
                  to={{
                    pathname: "/article/house/story",
                    state: {
                      nowCategory: "house",
                      nowDetailCategory: "0003"
                    }
                  }}
                >
                  <div>
                    <h3>
                      {t("main.houseShare")}
                      <br />
                      <span>{t("main.story")}</span>
                    </h3>
                    <p>
                      {t("main.sharePet")}
                      <br />
                      {t("main.minkyLife")}
                    </p>
                  </div>
                </Link>
              </div>
              <div className={cx("clear")}></div>
            </div>
          </div>
        </div>
        <InhabiMobile />
        <Popup
          closeModal={this.closeModal}
          isModalOpen={this.state.isModalOpen}
        >
          <Login
            closeModal={this.closeModal}
            isModalOpen={this.state.isModalOpen}
          />
        </Popup>
        <EventPopup
          closeEvent={this.closeEvent}
          isEventOpen={this.state.isEventOpen}
        >
          <div className={cx("popupEvent")}>
            <button className={cx("close")} onClick={this.closeEvent}></button>
            {this.state.popupList && (
              <img
                src={this.state.popupList.POPUP_PIC_URL}
                alt="popup"
                onClick={this.popupLink}
              />
            )}

            <div>
              <label>
                <input
                  type="checkbox"
                  name="popupOpen"
                  checked={this.state.popupOpen}
                  onChange={this.popupCheck}
                />
                <span>오늘 하루 열지 않기</span>
              </label>
            </div>
          </div>
        </EventPopup>
      </div>
    );
  }
}

export default withTranslation()(withCookies(withRouter(Main)));
