import React from "react";
import styles from "./_EmailOk.module.scss";
import classnames from "classnames/bind";
import { withTranslation } from "react-i18next";

const cx = classnames.bind(styles);

class EmailOk extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    // 홈으로 이동
    goHome = () => {
      window.location.replace("/");
    }

    render() {
      const { t } = this.props;
        return (
          <div className={cx("chpw-wrap")}>
            <div>
              <h1>{t("mailOk.checkComplete")}</h1>
              <p>{t("mailOk.accountConfirm")}</p>
              <button onClick={this.goHome}>{t("mailOk.toHome")}</button>
            </div>
          </div>
        );
    }
}

export default withTranslation()(EmailOk);
