import React, { Component } from "react";
import classnames from "classnames/bind";
import { withRouter } from "react-router-dom";

import heart from "assets/images/heart.png";
import comment from "assets/images/comment.png";

import styles from "./Young.module.scss";
const cx = classnames.bind(styles);

class Young extends Component {
  constructor(props) {
    super(props);
    this.state = {
      category: true
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.getCategory();
  }
  // 카테고리 가져오기
  getCategory = () => {
    const category = this.props.youngCategory;
    let nextState = this.state;
    nextState["category"] = category;
    this.setState(nextState);
  };

  // 청년주택의 모든 것
  changeMenuCate1 = () => {
    let nextState = this.state;

    if (nextState["category"] === true) return null;

    nextState["category"] = true;

    this.setState(nextState);

    this.props.ArticleContent2(nextState["category"]);
  };

  // 청년 임대 주택 알아보기
  changeMenuCate2 = () => {
    let nextState = this.state;
    if (nextState["category"] === false) return null;

    nextState["category"] = false;
    this.setState(nextState);

    this.props.ArticleContent2(nextState["category"]);
  };
  // 페이지 이동
  goto = item => {
    if (item["ARTICLE_TYPE"] === "1") {
      this.props.history.push(item["ARTICLE_URL"]);
    } else {
      window.open(item["ARTICLE_URL"]);
    }
  };

  render() {
    return (
      <>
        <div className={cx("dictionary-box")}>
          <div className={cx("dictionary-contents")}>
            <div className={cx("contents-list")}>
              <div className={cx("y-house-card-items")}>
                <div>
                  {this.props.articleList.length > 0 &&
                    this.props.articleList
                      .filter(items => items["CATEGORY"] === "0006")
                      .map((item, index) => {
                        return (
                          <div
                            className={cx("third-cols")}
                            key={index}
                            onClick={() => this.goto(item)}
                          >
                            <div className={cx("image-cover")}>
                              <img
                                src={`${item["INFO_THUMB_URL"]}`}
                                alt="청년주택의 모든 것"
                              />
                            </div>
                            <div className={cx("leftSide")}>
                              <h3>{item["TITLE"]}</h3>
                              <div className={cx("card-footer")}>
                                <span>{item["CREATE_DT"]}</span>
                                {item["ARTICLE_TYPE"] === "1" && (
                                  <>
                                    <span>
                                      <img src={heart} alt="heart" />
                                      {item["LIKE_CNT"]}
                                    </span>
                                    <span>
                                      <img src={comment} alt="comment" />
                                      {item["COMMENT_CNT"]}
                                    </span>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(Young);
