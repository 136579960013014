import React, { useState, useCallback } from "react";
import classNames from "classnames/bind";
import Types from "prop-types";

import Dot from "assets/images/dot.png";

import styles from "./_Image.module.scss";

const cx = classNames.bind(styles);

const Image = ({ className, src, alt }) => {
  const [isLoading, setIsLoading] = useState(true);

  const onLoaded = useCallback(() => {
    setIsLoading(false);
  }, []);

  return (
    <>
      {isLoading && (
        <img className={cx("dot", className)} src={Dot} alt={alt} />
      )}
      <img
        className={cx("img", { none: isLoading }, className)}
        src={src}
        alt={alt}
        onLoad={onLoaded}
      />
    </>
  );
};

Image.propTypes = {
  className: Types.string,
  src: Types.string.isRequired,
  alt: Types.string
};

export default Image;
