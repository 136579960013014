import React from "react";
import classnames from "classnames/bind";
import { withTranslation } from "react-i18next";
import styled from "./_UserAccount.module.scss";

import CloseAccount from "containers/common/CloseAccount/CloseAccountContainer";
import PasswordChange from "containers/user/PasswordChange/PasswordChangeContainer";
import Popup from "components/common/Popup";

// import naver from "assets/images/naver-small.png";
// import facebook from "assets/images/facebook-small.png";
// import kakaotalk from "assets/images/kakao-small.png";

const cx = classnames.bind(styled);

class UserAccount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      MemberData: this.props.MemberData,
      NationCodes: this.props.NationCodes,
      NewsNotice:
        this.props.MemberData["NEWS_NOTICE_YN"] === "Y" ? true : false,
      TelConfirmyn:
        this.props.MemberData["TEL_CONFIRM_YN"] === "Y" ? true : false,
      Gender: this.props.MemberData["GENDER"],
      isModalOpen: false,
      isPasswordOpen: false,
      iswithdrawModalOpen: false,
      iswithdrawOpen: false,
      EMAIL: "",
      MEMBER_NAME: "",
      GENDER: "",
      BIRTHYEAR: "",
      NATION_CODE: "",
      NEW_PASSWORD: "",
      NOTICE_YN: "",
      NEWS_NOTICE_YN: "",
      MEMBER_TYPE: "",
      notice: false,
      news: false
    };
  }

  componentDidMount = () => {
    const { MemberData } = this.props;

    let nextState = this.state;
    nextState["EMAIL"] = MemberData["EMAIL"];
    nextState["MEMBER_NAME"] = MemberData["MEMBER_NAME"];
    nextState["GENDER"] = MemberData["GENDER"];
    nextState["BIRTHYEAR"] = MemberData["BIRTHYEAR"];
    nextState["NATION_CODE"] = MemberData["NATION_CODE"];
    nextState["NOTICE_YN"] = MemberData["NOTICE_YN"];
    nextState["NEWS_NOTICE_YN"] = MemberData["NEWS_NOTICE_YN"];
    nextState["MEMBER_TYPE"] = MemberData["MEMBER_TYPE"];

    this.setState(nextState);
  };
  // 내정보 수정
  MyinfoModify = e => {
    e.preventDefault();
    const {
      EMAIL,
      MEMBER_NAME,
      GENDER,
      BIRTHYEAR,
      NATION_CODE,
      NEW_PASSWORD,
      NOTICE_YN,
      NEWS_NOTICE_YN
    } = this.state;

    // 숫자만
    const pattern = /^[19]{1}[0-9]{2}|[20]{1}[012]{1}[0-9]{1}$/;
    // 특수문자 금지
    const regexp = /[^(가-힣ㄱ-ㅎㅏ-ㅣa-zA-Z0-9)]/gi;
    const { t } = this.props;
    if (!EMAIL || EMAIL.trim() === "") {
      return alert(t("myPage.emailAlert"));
    }
    if (regexp.test(MEMBER_NAME)) {
      return alert(t("myPage.nameAlert"));
    }
    if (!pattern.test(BIRTHYEAR)) {
      return alert(t("myPage.yearAlert"));
    }

    this.props.MyinfoModify({
      EMAIL,
      MEMBER_NAME,
      GENDER,
      BIRTHYEAR,
      NATION_CODE,
      NEW_PASSWORD,
      NOTICE_YN,
      NEWS_NOTICE_YN
    });
  };
  // 로그인창 열기
  openModal = e => {
    e.preventDefault();
    this.setState({
      ...this.state,
      isModalOpen: true,
      isPasswordOpen: true
    });
  };
 // 로그인창 닫기
  closeModal = e => {
    if (e !== undefined && e !== null) e.preventDefault();
    this.setState({
      ...this.state,
      isModalOpen: false,
      isPasswordOpen: false
    });
  };
  // 회원 탈퇴 오픈
  withdrawopenModal = e => {
    e.preventDefault();
    this.setState({
      ...this.state,
      iswithdrawModalOpen: true,
      iswithdrawOpen: true
    });
  };
  // 회원 탈퇴 닫기
  withdrawcloseModal = e => {
    e.preventDefault();
    this.setState({
      ...this.state,
      iswithdrawModalOpen: false,
      iswithdrawOpen: false
    });
  };
  // 패스워드 변경 오픈
  movePwchange = e => {
    e.preventDefault();
    let nextState = this.state;
    nextState["isPasswordOpen"] = !nextState["isPasswordOpen"];
    this.setState(nextState);
  };
  // 국가 입력
  handleSelectChange = e => {
    let nextState = this.state;

    const value = e.target.value;
    nextState["NATION_CODE"] = value;

    this.setState(nextState);
  };
  // 성별 입력
  onChangeGender = e => {
    let value = e.target.value;

    let nextState = this.state;
    nextState["GENDER"] = value;
    this.setState(nextState);
  };
  // 인풋값 입력
  onChange = e => {
    const name = e.target.name;
    if (name === "NOTICE_YN" || name === "NEWS_NOTICE_YN") {
      this.setState({
        [e.target.name]: this.state[e.target.name] === "Y"?"N":"Y"
      });
    }
  };

  render() {
    const { GENDER } = this.state;
    const { t } = this.props;
    return (
      <div className={cx("Account-management")}>
        <div>
          <div className={cx("UserAccount-left")}>
            <h1>{t("myPage.accountInfo")}</h1>
            <div className={cx("clear")}></div>
          </div>
          <div className={cx("UserAccount-right")}>
            <div>
              <form>
                {/* 일반 회원 일경우 */}
                {this.state.MEMBER_TYPE !== "kakao" &&
                  this.state.MEMBER_TYPE !== "naver" && (
                    <input
                      name={cx("email")}
                      type="email"
                      placeholder={t("register.email")}
                      defaultValue={this.state.MemberData["EMAIL"]}
                      readOnly
                    />
                  )}
                {/* SNS 일경우 */}
                {(this.state.MEMBER_TYPE === "kakao" ||
                  this.state.MEMBER_TYPE === "naver") && (
                  <input
                    name={cx("EMAIL")}
                    type="email"
                    placeholder={t("register.email")}
                    defaultValue={this.state.MemberData["EMAIL"]}
                    value={this.state.EMAIL}
                    onChange={this.onChange}
                  />
                )}
                {this.state.MEMBER_TYPE !== "kakao" &&
                  this.state.MEMBER_TYPE !== "naver" && (
                    <button
                      className={cx("change-password")}
                      onClick={this.movePwchange}
                    >
                      {t("myPage.pwdChange")}
                    </button>
                  )}
                <input
                  name="MEMBER_NAME"
                  type="text"
                  placeholder={t("register.name")}
                  value={this.state.MEMBER_NAME}
                  onChange={this.onChange}
                  required
                />
                <div className={cx("genders")}>
                  <label>
                    <input
                      type="radio"
                      name="GENDER"
                      value="M"
                      onChange={this.onChangeGender}
                      checked={GENDER === "M" ? true : false}
                    />
                    <div className={cx("gender-box")}>{t("myPage.man")}</div>
                  </label>
                  <label>
                    <input
                      type="radio"
                      name="GENDER"
                      value="F"
                      onChange={this.onChangeGender}
                      checked={GENDER === "F" ? true : false}
                    />
                    <div className={cx("gender-box")}>{t("myPage.woman")}</div>
                  </label>
                </div>
                <input
                  name="BIRTHYEAR"
                  type="number"
                  placeholder={t("register.birth")}
                  value={this.state.BIRTHYEAR}
                  onChange={this.onChange}
                  required
                />
                <select
                  name="NATION_CODE"
                  placeholder={t("register.nation")}
                  onChange={this.handleSelectChange}
                  value={this.state.NATION_CODE}
                >
                  {this.state.NationCodes.length > 0 &&
                    this.state.NationCodes.map(items => {
                      return (
                        <option key={items["code"]} value={items["code"]}>
                          {items["name"]}
                        </option>
                      );
                    })}
                </select>
              </form>
              <div className={cx("clear")}></div>
            </div>
          </div>
          <div className={cx("UserAccount-left")}>
            <h1>{t("myPage.memberType")}</h1>
            <div className={cx("liner")}></div>
            <div className={cx("clear")}></div>
          </div>
          <div className={cx("UserAccount-right")}>
            <div>
              <div className={cx("member_type")}>
                <div>{this.state.MEMBER_TYPE}</div>
              </div>
              <div className={cx("receive-agreement")}>
                <div>
                  <label>
                    {this.state.NEWS_NOTICE_YN === "Y" ? "✔" : ""}
                    <input
                      type="checkbox"
                      name="NEWS_NOTICE_YN"
                      checked={this.state.NEWS_NOTICE_YN === "Y" ? true : false}
                      onChange={this.onChange}
                    />
                  </label>
                  <span>{t("myPage.newsReceive")}</span>
                </div>
                <div className={cx("clear")}></div>
                <div>
                  <label>
                    {this.state.NOTICE_YN === "Y" ? "✔" : ""}
                    <input
                      type="checkbox"
                      name="NOTICE_YN"
                      checked={this.state.NOTICE_YN === "Y" ? true : false}
                      onChange={this.onChange}
                    />
                  </label>
                  <span>{t("myPage.alarmReceive")}</span>
                </div>
              </div>
              <div className={cx("clear")}></div>
              <button
                className={cx("close-account")}
                onClick={this.withdrawopenModal}
              >
                {t("myPage.memberOut")}
              </button>
              <div className={cx("clear")}></div>
            </div>
            <div className={cx("clear")}></div>
          </div>
          <div className={cx("clear")}></div>
          <div className={cx("confirm-change")}>
            <button className={cx("confirm-ok")} onClick={this.MyinfoModify}>
              {t("myPage.modiConfirm")}
            </button>
            <button className={cx("confirm-cancel")}>
              {t("myPage.modiCancel")}
            </button>
            <div className={cx("clear")}></div>
          </div>
          <div className={cx("clear")}></div>
        </div>
        {/* 패스워드 변경 */}
        <Popup
          closeModal={this.closeModal}
          isModalOpen={this.state.isPasswordOpen}
        >
          <PasswordChange
            closeModal={this.closeModal}
            isModalOpen={this.state.isPasswordOpen}
          />
        </Popup>
        {/* 회원탈퇴 */}
        <Popup
          closeModal={this.withdrawcloseModal}
          isModalOpen={this.state.iswithdrawOpen}
        >
          <CloseAccount
            closeModal={this.withdrawcloseModal}
            isModalOpen={this.state.iswithdrawOpen}
          />
        </Popup>
      </div>
    );
  }
}

export default withTranslation()(UserAccount);
