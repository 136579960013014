import React from "react";
import { withTranslation } from "react-i18next";
import styles from "./_PasswordChange.module.scss";
import classnames from "classnames/bind";

const cx = classnames.bind(styles);

class PasswordChange extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      oldPassword: "",
      newPassword: "",
      re_newPassword: ""
    };
  }
  // 비밀번호 변경 시 작성하는 항목들 state 에 넣고 저장 ( 이전 비밀번호 , 새 비밀번호 , 새 비밀번호 확인 )
  onChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  //클릭시 정규식 및 누락채크 ( 누락되지 않았다면 비밀번호 변경하는 api 로 떤져줌 )
  onClick = e => {
    e.preventDefault();
    const { t } = this.props;
    // var pattern1 = /[0-9]/;

    // var pattern2 = /[a-zA-Z]/;

    // var pattern3 = /[~!@#$%<>^&*]/;

    // if (!this.state.oldPassword) {
    //     alert("이전 비밀번호를 입력해주세요.");
    //     return false;
    // } else if (!this.state.newPassword) {
    //     alert("새 비밀번호를 입력해주세요.");
    //     return false;
    // } else if (!this.state.re_newPassword) {
    //     alert("비밀번호 재확인을 입력해주세요.");
    //     return false;
    // } else {
    //     if (this.state.newPassword.length < 8) {
    //         alert("비밀번호를 8자 이상으로 입력해주십시오");
    //         return false;
    //     } else if (
    //         !pattern1.test(this.state.newPassword) ||
    //         !pattern2.test(this.state.newPassword) ||
    //         !pattern3.test(this.state.newPassword)
    //     ) {
    //         alert(
    //             "비밀번호는 영문+숫자+특수기호 조합으로 구성하여야합니다."
    //         );
    //         return false;
    //     } else {
    //         var SamePass_0 = 0; //동일문자 카운트
    //         var SamePass_1 = 0; //연속성(+) 카운드
    //         var SamePass_2 = 0; //연속성(-) 카운드

    //         var chr_pass_0;
    //         var chr_pass_1;

    //         for (var i = 0; i < this.state.newPassword.length; i++) {
    //             chr_pass_0 = this.state.newPassword.charAt(i);
    //             chr_pass_1 = this.state.newPassword.charAt(i + 1);

    //             //동일문자 카운트
    //             if (chr_pass_0 === chr_pass_1) {
    //                 SamePass_0 = SamePass_0 + 1;
    //             } // if

    //             //연속성(+) 카운드
    //             if (
    //                 chr_pass_0.charCodeAt(0) - chr_pass_1.charCodeAt(0) ===
    //                 1
    //             ) {
    //                 SamePass_1 = SamePass_1 + 1;
    //             } // if

    //             //연속성(-) 카운드
    //             if (
    //                 chr_pass_0.charCodeAt(0) - chr_pass_1.charCodeAt(0) ===
    //                 -1
    //             ) {
    //                 SamePass_2 = SamePass_2 + 1;
    //             } // if
    //         } // for

    //         if (SamePass_0 > 1) {
    //             alert("동일문자를 3번 이상 사용할 수 없습니다.");
    //             return false;
    //         } // if

    //         if (SamePass_1 > 1 || SamePass_2 > 1) {
    //             alert(
    //                 "연속된 문자열(123 또는 321, abc, cba 등)을\n 3자 이상 사용 할 수 없습니다."
    //             );
    //             return false;
    //         } // if
    //     }
    //     this.props.passwordChange(
    //         this.state.oldPassword,
    //         this.state.re_newPassword
    //     );
    // }

    if (!this.state.oldPassword) {
      alert(t("myPage.prevPwAlert"));
      return false;
    } else if (!this.state.newPassword) {
      alert(t("myPage.newPwAlert"));
      return false;
    } else if (this.state.newPassword.length < 8) {
      alert(t("myPage.pwLengthAlert"));
      return false;
    } else if (!this.state.re_newPassword) {
      alert(t("myPage.pwCheckAlert"));
      return false;
    } else if (this.state.newPassword !== this.state.re_newPassword) {
      alert(t("myPage.pwNotEqual"));
      return false;
    }
    // 패스워드 변경
    this.props.passwordChange(
      this.state.oldPassword,
      this.state.re_newPassword
    );
  };

  render() {
    const { t } = this.props;
    return (
      <div className={cx("chpw-wrap")}>
        <div>
          <button
            className={cx("close")}
            onClick={this.props.closeModal}
          ></button>
          <h1>{t("myPage.passwordChange")}</h1>
          <p>{t("myPage.passwordChangeText")}</p>
          <form>
            <input
              type="password"
              placeholder={t("myPage.passwordChange")}
              name="oldPassword"
              onChange={e => this.onChange(e)}
            />
            <input
              type="password"
              placeholder={t("myPage.newPassword")}
              name="newPassword"
              onChange={e => this.onChange(e)}
            />
            <input
              type="password"
              placeholder={t("myPage.passwordCheck")}
              name="re_newPassword"
              onChange={e => this.onChange(e)}
            />
            <button onClick={e => this.onClick(e)}>
              {t("myPage.passwordChange")}
            </button>
          </form>
        </div>
      </div>
    );
  }
}

export default withTranslation()(PasswordChange);
