import React from "react";
import { bindActionCreators } from "redux";
import { withCookies } from "react-cookie";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as UserActions from "stores/modules/user";
import * as InqueryActions from "stores/modules/inquery";
import InquiryDetail from "components/user/mypage/details/Inquiry/detail";

class InquiryDetailContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inquiry: [],
      house: [],
      messageList: [],
      inquiry_id : "",
      isLoading: true
    };
  }
  async componentDidMount() {
    await this.getId();
    await this.topic();
  }

  topic = () => {
    const topicId = this.state.inquiry.INQUIRY_ID; // 하우스정보 또는 나의입주문의 상세정보의 INQUIRY_ID값을 사용
    if (topicId) {
      let stompClient = null;
      let socket = new window.SockJS("/chat");
      stompClient = window.Stomp.over(socket);
      stompClient.debug = null; //디버그 메시지 출력 제거, 배포할때 처리
      stompClient.connect({}, frame => {
        this.setState({ isLoading: false });
        stompClient.subscribe("/topic/" + topicId, greeting => {
          if (JSON.parse(greeting.body) !== undefined || null) {
            let nextState = this.state;
            nextState["messageList"].push(JSON.parse(greeting.body));
            this.setState(nextState);
          }
        });
      });
    }
  };
  // inquiry_id 가져오기
  getId = async () => {
    const { match } = this.props;

    const inquiry_id = match.params.inquiry_id;
    let nextState = this.state;
    nextState['inquiry_id'] = inquiry_id;
    this.setState(nextState);
    await this.get_inquiry_detail({ inquiry_id });
    // await this.houseMessageList();
  };
  // 상세정보 가져오기
  get_inquiry_detail = async inquiry_id => {
    const { InqueryActions } = this.props;
    try {
      const res = await InqueryActions.inquiry_detail(inquiry_id);
      const data = res.data.result.data;
      let nextState = this.state;
      nextState["inquiry"] = data.inquiry;
      nextState["house"] = data.house;
      nextState["messageList"] = data.messageList;
      this.setState(nextState);
    } catch (e) {
      console.log(e);
    }
  };

  // 메세지 받아오기
  houseMessageList = async () => {
    const { InqueryActions } = this.props;
    try {
      const res = await InqueryActions.houseMessage();
      let nextState = this.state;
      nextState["messageList"] = res.data.result.data.inquiryList;
      nextState["isLoading"] = false;
      this.setState(nextState);
    } catch (e) {
      console.log(e);
    }
  };
  
  // 메세지 보내기
  HouseInquery = async (house_id, message) => {
    const { InqueryActions, match } = this.props;
    const inquiry_id = match.params.inquiry_id;
    try {
      const res = await InqueryActions.HouseInquery( house_id, message, inquiry_id);
      return res;
    } catch (e) {
      console.log(e);
    }
  };
 

  render() {
    if (this.state.isLoading) return "데이터를 불러오는 중입니다.";
    return (
      <InquiryDetail
        inquiry={this.state.inquiry}
        house={this.state.house}
        HouseInquery={this.HouseInquery}
        getId={this.getId}
        messageList={this.state.messageList}
        houseMessageList={this.houseMessageList}
        inquiry_id={this.state.inquiry_id}
      />
    );
  }
}

export default withCookies(
  connect(state => ({
    isLogined: state.user.auth.user.isLogined
  }), dispatch => ({
    UserActions: bindActionCreators(UserActions, dispatch),
    InqueryActions: bindActionCreators(InqueryActions, dispatch)
  }))(withRouter(InquiryDetailContainer))
);
