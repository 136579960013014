import React from "react";
import Story from "components/dictionary/detail/Story";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as UserAction from "stores/modules/mainall";

class StoryContainer extends React.Component {
  // 쉐어하우스 스토리 페이지 뿌려주는 함수
  ArticleContent = async () => {
    const { UserAction, SEARCH_TEXT } = this.props;
    try {
      const res = await UserAction.ArticleContent({SEARCH_TEXT, CATEGORY: "0003"});
      return res.data.result.data.articleList;
    } catch (e) {
      console.log(e);
    }
  };

  render() {
    return <Story ArticleContent={this.ArticleContent} ArticleArr={this.props.ARTICLE_LIST}/>;
  }
}

export default connect(state => ({
  SEARCH_TEXT: state.mainall.article.content.SEARCH_TEXT,
  ARTICLE_LIST: state.mainall.article.content.ARTICLE_LIST
}), dispatch => ({
  UserAction: bindActionCreators(UserAction, dispatch)
}))(StoryContainer);
