import React from "react";
import MainAll from "components/dictionary/detail/MainAll";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as UserActions from "stores/modules/mainall";

class MainAllContainer extends React.Component {

  // 청년주거백과 메인 페이지
  ArticleContent = async () => {
    // 청년주거백과 전체 콘텐츠 받아오는 api
    const { UserActions, SEARCH_TEXT } = this.props;
    try {
      const res = await UserActions.ArticleContent({SEARCH_TEXT}); // api 통신내용 변수에 저장
      return res.data.result.data.articleList || []; // 통신내용중 콘텐츠 받아온것을 반환
    } catch (e) {
      console.log(e);
      return [];
    }
  };

  render() {
    // 청년주거백과 메인페이지 컴포넌트 및 콘텐츠 리스트 반환하는 함수 보냄
    return <MainAll ArticleContent={this.ArticleContent} ArticleArr={this.props.ARTICLE_LIST}/>;
  }
}

export default connect(state => ({
  SEARCH_TEXT: state.mainall.article.content.SEARCH_TEXT,
  ARTICLE_LIST: state.mainall.article.content.ARTICLE_LIST
}), dispatch => ({
  UserActions: bindActionCreators(UserActions, dispatch)
}))(MainAllContainer);
