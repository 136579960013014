import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as UserAction from "stores/modules/mainall";

import PasswordEmailCheck from "components/user/PasswordEmailCheck";


class PasswordEmailCheckContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmId: "",
      isLoading: true
    };
  }
  componentDidMount() {
    this.pwdModiEmailCheck();
  }
  // 비밀번호 변경 체크
  pwdModiEmailCheck = async () => {
    const { UserAction } = this.props;
    let emailKey = this.props.match.params.emailKey;
    // let emailKey = "89e15813-e621-40b6-9d77-e34f8a773358";
    let nextState = this.state;
    try {
      const res = await UserAction.pwdModiEmailCheck(emailKey);
      // nextState["confirmId"] = res.data.result.data;
      if(res.data.result.resCode === "0000"){
        nextState["confirmId"] = emailKey;
        nextState["isLoading"] = false;
        this.setState(nextState);
        return res;
      } else if (res.data.result.resCode === "0002") {
        window.location.replace("/user/expiredemail");
      } else {
        window.location.replace("/error/" + res.data.result.resCode);
      }
    } catch (e) {
      console.log(e);
    }
  };
  // 이메일 비밀번호 변경 후 메인으로 가기
  pwdModiEmail = async (newPassword, confirmid) => {
    const { UserAction } = this.props;

    try {
      const res = await UserAction.pwModiEmail(newPassword, confirmid);
      if(res.data.result.resCode === "0000"){
        alert(res.data.result.resMessage);
        this.props.history.push("/");
      }else{
        alert(res.data.result.resMessage);
        this.props.history.push("/");
      }
      return res;
    } catch (e) {
      console.dir(e);
    }
  };

  render() {
    if(this.state.isLoading) return null;
    return (
      <PasswordEmailCheck
        confirmId={this.state.confirmId}
        pwdModiEmail={this.pwdModiEmail}
      />
    );
  }
}
export default connect(null, dispatch => ({
  UserAction: bindActionCreators(UserAction, dispatch)
}))(PasswordEmailCheckContainer);
