import React from "react";
import { Link } from "react-router-dom";
import classnames from "classnames/bind";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import * as LangActions from "stores/modules/lang";
import { withTranslation } from "react-i18next";

import apple from "assets/images/apple.png";
import google from "assets/images/google_play.png";
import korea from "assets/images/korea.png";
import usa from "assets/images/usa.png";
import facebook from "assets/images/Facebook.png";
import n_blog from "assets/images/n_blog.png";
import instargram from "assets/images/Instagram.png";
import n_post from "assets/images/n_post.png";

import styles from "./_MobileFooter.module.scss";
const cx = classnames.bind(styles);

class MobileFooter extends React.Component {
  // 한글
  lang_ko = async () => {
    const { i18n, LangActions } = this.props;

    i18n.changeLanguage("ko-KR");
    LangActions.Lang_Ko();
    const url = this.props.location.pathname;
    window.location.replace(url);

    return;
  };
  // 영어
  lang_en = async () => {
    const { i18n, LangActions } = this.props;

    i18n.changeLanguage("en-US");
    LangActions.Lang_En();
    const url = this.props.location.pathname;
    window.location.replace(url);

    return;
  };

  render() {
    const { t } = this.props;
    return (
      <footer className={cx("m-footer")} id="m_footer">
        <div className={cx("content")}>
          <div className={cx("download-app")}>
            <a
              href="https://apps.apple.com/kr/app/%EC%BB%B4%EC%95%A4%EC%8A%A4%ED%85%8C%EC%9D%B4/id1089462100"
              target="blank"
            >
              <img src={apple} alt={"download-apple"} />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.thecomenstay&hl=ko"
              target="blank"
            >
              <img src={google} alt={"download-google"} />
            </a>
          </div>
          <div className={cx("language-sns")}>
            <div className={cx("language")}>
              <div>
                <Link to="#" onClick={this.lang_ko}>
                  <img src={korea} alt="korea" />
                </Link>
              </div>

              <div>
                <Link to="#" onClick={this.lang_en}>
                  <img src={usa} alt="usa" />
                </Link>
              </div>
            </div>
            <div className={cx("sns")}>
              <div>
                <Link to="#">
                  <img src={facebook} alt="facebook" />
                </Link>
              </div>

              <div>
                <Link to="#">
                  <img src={instargram} alt="instargram" />
                </Link>
              </div>

              <div>
                <Link to="#">
                  <img src={n_blog} alt="n_blog" />
                </Link>
              </div>

              <div>
                <Link to="#">
                  <img src={n_post} alt="n_post" />
                </Link>
              </div>
            </div>
          </div>
          <div className={cx("clear")}></div>
          <div className={cx("company-info")}>
            <h3>{t("footer.ComeAndStay")}</h3>
            <ul>
              <li>
                <a href="/company/intro">{t("footer.intro")}</a>
              </li>
              <li>
                <a href="/company/terms">{t("footer.terms")}</a>
              </li>
              <li>
                <a href="/company/Privacy">{t("footer.privacy")}</a>
              </li>
            </ul>
            <div className={cx("copy")}>
              <p>
                {t("footer.address")}
                <br />
                {t("footer.ceo")}&nbsp;&nbsp;{t("footer.company.code")}
                <br />
                {t("footer.email")}
                <br />
                COPYRIGHT ⓒ Come&amp;stay Co.Ltd.ALL RIGHTS RESERVED.
              </p>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default withTranslation()(
  connect(null, dispatch => ({
    LangActions: bindActionCreators(LangActions, dispatch)
  }))(withRouter(MobileFooter))
);
