import React, { createRef } from "react";
import classnames from "classnames/bind";
import { withRouter } from "react-router-dom";
import Calendar from "react-calendar";
import moment from "moment";
import ReactHtmlParser from "react-html-parser";
import copy from "copy-to-clipboard";
import { withTranslation } from "react-i18next";
import $ from "jquery";
import Slider from "react-slick";

import ImageGallery from "./react-image-gallery";
import "./image-gallery.scss";

import Popup from "components/common/Popup/Popup";
import Compare from "containers/house/Compare/CompareContainer";
import Login from "containers/user/login/UserLoginContainer";

import house_icon from "assets/images/house_icon.png";

import vr_see from "assets/images/vr-see.png";
import Glass from "assets/images/Search.png";

import comment from "assets/images/comment.png";
import heart from "assets/images/heart.png";
import star_yellow from "assets/images/star_yellow.png";
import star_gray from "assets/images/Star_gray.png";
import folder_add from "assets/images/folder_add.png";

import share from "assets/images/share.png";
import kakao_share from "assets/images/kakao_share.png";
import facebook_share from "assets/images/facebook_share.png";
import twitter_share from "assets/images/twitter_share.png";
import link_share from "assets/images/link_share.png";

import store24 from "assets/images/24store.png";
import Bank from "assets/images/Bank.png";
import Cafe from "assets/images/Cafe.png";
import drug from "assets/images/drug.png";
import fastfood from "assets/images/fastfood.png";
import Hospital from "assets/images/Hospital.png";
import Hot from "assets/images/Hot.png";
import Laundry from "assets/images/Landry.png";
import Mart from "assets/images/Mart.png";
import Movie from "assets/images/Movie.png";
import office from "assets/images/office.png";
import police from "assets/images/police.png";
import Drugstore from "assets/images/Drugstore.png";
import Daiso from "assets/images/Daiso.png";
import ErrorImg from "assets/images/default-image.png";
import school from "assets/images/school.png";
import play from "assets/images/play.png";

import MobileDetailMenu from "./MobileDetailMenu/MobileDetailMenu.jsx";

import styles from "./_Detail.module.scss";
import "./_Detail.css";

const cx = classnames.bind(styles);

class Detail extends React.Component {
  constructor(props) {
    super(props);
    this.mapRef = createRef();
    this.chatScroll = createRef();
    this.imgRef = createRef();
    this.chating = createRef();
    this.checkin = createRef();
    this.isImgFullScreen = false;
    this.state = {
      isLoading: true,
      HouseDetail: [],
      inquiry: [],
      articleList: [],
      brand: [],
      facility1List: [],
      facility2List: [],
      house: [],
      pictureList: [],
      placeList: [],
      roomList: [],
      subwayList: [],
      universityList: [],
      messageList: [],
      compareList: [],
      favList: [],
      spaceList: [],
      images: [],
      NowCaption: "공용공간",
      nthCard: 0,
      nthInfo: 0,
      InqMessage: "",
      displayN: { display: "none" },
      DESCRIPTION: {},
      selectState: false,
      isModalOpen: false,
      date: "",
      checkInCalendar: false,
      checkOutCalendar: false,
      calendarDate: "",
      checkInDate: "",
      checkOutDate: "",
      ROOM_NAME: "",
      questionOn: false,
      favCheck: false,
      bannerTop: false,
      currentContent: 0,
      fullScreenItem: false,
      checkIndex: 0,
      roomCount: 0,
      mobileChatOpen: false,
      isLoginModalOpen: false,
      youngRoom: 0,
      infoView: false,
      infoNum: 0,
      spaceHead: 0,
      spaceCount: 0,
      roomListCount: 0
    };
    this.all_room = [];
    this.all_room_head = [];
    this.empty_room = [];
    this.young_room = [];
    this.young_room_m = [];
    this.floorHead = [];
    this.floorContent = [];
  }

  async componentDidMount() {
    window.scrollTo(0, 0);

    await this.getHouseInfo();

    window.addEventListener("scroll", this.onScroll);

    await this.getCompareList();
    await this.favCheck();

    // 최근본 하우스 등록
    if (JSON.stringify(this.state.HouseDetail) !== "{}") {
      const myStorage = localStorage;
      const viewedhouse = myStorage.getItem("viewdhouse");
      let viewd = [];
      if (viewedhouse !== "") {
        const viewedhouseArr = viewedhouse.split("|");

        if (viewedhouseArr.length >= 20) {
          viewedhouseArr.pop();
        }

        viewedhouseArr.forEach((item, index) => {
          if (item.indexOf(this.state.house["HOUSE_ID"]) === -1) {
            viewd.push(item);
          }
        });

        const update_viewdhouse =
          this.state.house["HOUSE_ID"] + "|" + viewedhouseArr.join("|");

        const overlap_viewdhouse =
          this.state.house["HOUSE_ID"] + "|" + viewd.join("|");

        if (
          this.state.house["HOUSE_ID"] !== undefined &&
          this.state.house["HOUSE_ID"] !== null &&
          this.state.house["HOUSE_ID"] !== ""
        ) {
          if (viewedhouse.indexOf(this.state.house["HOUSE_ID"]) === -1) {
            myStorage.setItem("viewdhouse", update_viewdhouse);
          } else {
            myStorage.removeItem("viewdhouse");
            myStorage.setItem("viewdhouse", overlap_viewdhouse);
          }
        }
      } else {
        const update_viewdhouse = this.state.house["HOUSE_ID"];
        if (
          this.state.house["HOUSE_ID"] !== undefined &&
          this.state.house["HOUSE_ID"] !== null &&
          this.state.house["HOUSE_ID"] !== ""
        ) {
          if (viewedhouse.indexOf(update_viewdhouse) === -1) {
            myStorage.setItem("viewdhouse", update_viewdhouse);
          }
        }
      }
    }

    this.setState({
      isLoading: false
    });

    document.getElementById("mainTemplate_goPrev_btn").style.bottom = "60px";
    document.getElementById("mainTemplate_toTop_btn").style.bottom = "60px";

    document.addEventListener("click", e => {
      if (this.state.checkInCalendar) {
        const ps = $(e.target).parents();

        for (let index = 0; index < ps.length; index++) {
          const item = ps[index];
          const id = $(item).attr("id");
          const classes = $(item).attr("class");

          if (id === "calendar") {
            return;
          }
          if (classes === "react-calendar__century-view") {
            return;
          }
          if (classes === "react-calendar__decade-view") {
            return;
          }
          if (classes === "react-calendar__year-view") {
            return;
          }
        }
        this.setState({ checkInCalendar: false });
      } else if (this.state.checkOutCalendar) {
        const ps = $(e.target).parents();

        for (let index = 0; index < ps.length; index++) {
          const item = ps[index];
          const id = $(item).attr("id");
          const classes = $(item).attr("class");

          if (id === "calendar") {
            return;
          }
          if (classes === "react-calendar__century-view") {
            return;
          }
          if (classes === "react-calendar__decade-view") {
            return;
          }
          if (classes === "react-calendar__year-view") {
            return;
          }
        }
        this.setState({ checkOutCalendar: false });
      } else {
        this.setState({ checkInCalendar: false, checkOutCalendar: false });
      }
    });
    
    $(".slick-dots").css({ display: "none" });
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.onScroll);
    document.getElementById("mainTemplate_goPrev_btn").style.bottom = "32px";
    document.getElementById("mainTemplate_toTop_btn").style.bottom = "32px";
    document.getElementById("root").style.overflow = "visible";
    document.getElementById("root").style.position = "static";
    document.getElementById("root").style.width = "auto";
    document.getElementById("root").style.height = "auto";
  }

  componentDidUpdate(prevProps) {
    if (prevProps.messageList.length > 0 && prevProps.messageList) {
      if (prevProps.messageList.length !== this.props.messageList) {
        if (this.chatScroll.current) {
          this.chatScroll.current.scrollTo({
            left: 0,
            top: this.chatScroll.current.scrollHeight
          });
        }
      }
    }
  }

  Image = item => {
    return (
      <>
        {this.state.fullScreenItem && (
          <div className={cx("gallery-description")}>{item.description}</div>
        )}
        <img
          src={item.original}
          className={cx("gallery-item", {
            "fullScreen-item": this.state.fullScreenItem
          })}
          alt=""
        />
      </>
    );
  };

  getHouseInfo = () => {
    const {
      inquiry,
      articleList,
      brand,
      facility1List,
      facility2List,
      house,
      pictureList,
      placeList,
      spaceList,
      roomList,
      subwayList,
      universityList,
      messageList
    } = this.props.houseDetail;

    let nextState = this.state;
    nextState["inquiry"] = inquiry;
    if (inquiry !== undefined && inquiry !== null) {
      nextState["checkInDate"] = inquiry.FROM_DATE;
      nextState["checkOutDate"] = inquiry.TO_DATE;
    }
    nextState["articleList"] = articleList;
    nextState["brand"] = brand;
    nextState["spaceList"] = spaceList;
    if (spaceList) {
      nextState["spaceCount"] = spaceList.length;
    }
    if (roomList) {
      nextState["roomListCount"] = roomList.length;
    }
    if (facility1List) {
      nextState["facility1List"] = facility1List;
    }
    if (facility2List) {
      nextState["facility2List"] = facility2List;
    }
    nextState["house"] = house;
    nextState["pictureList"] = pictureList;
    pictureList.forEach((item, index) => {
      if(window.screen.width > 768){
        nextState["images"].push({
          key: index,
          original: item["PIC_URL"],
          description:
            brand["BRAND_NAME"] +
            " " +
            house["HOUSE_NAME"] +
            " " +
            item["CAPTION"],
          thumbnail: item["THUMB_URL"],
          renderItem: this.Image,
          CAPTION: item["CAPTION"]
        });
      }else{
        if (item["EMPTY_YN"] === "N") {
           nextState["images"].push({
             key: index,
             original: item["PIC_URL"],
             description:
               brand["BRAND_NAME"] +
               " " +
               house["HOUSE_NAME"] +
               " " +
               item["CAPTION"],
             thumbnail: item["THUMB_URL"],
             renderItem: this.Image,
             CAPTION: item["CAPTION"]
           });
        }
        
      }
      
    });
    nextState["placeList"] = placeList;
    nextState["roomList"] = roomList;
    nextState["subwayList"] = subwayList;
    nextState["universityList"] = universityList;
    nextState["messageList"] = messageList;
    nextState["roomCount"] = roomList.filter(
      val => val["ROOM_STATUS"] !== "0003"
    ).length;

    this.setState(nextState);

    const options = {
      center: new window.kakao.maps.LatLng(
        this.props.houseDetail.house["LATITUDE"],
        this.props.houseDetail.house["LONGITUDE"]
      ),
      level: 2,
      position: new window.kakao.maps.LatLng(
        this.props.houseDetail.house["LATITUDE"],
        this.props.houseDetail.house["LONGITUDE"]
      )
    };

    this.map = new window.kakao.maps.Map(this.mapRef, options);
    const control = new window.kakao.maps.ZoomControl();

    this.map.addControl(control, window.kakao.maps.ControlPosition.TOPRIGHT);

    if (window.screen.width <= 768) {
      this.map.setDraggable(false);
    }

    new window.kakao.maps.Marker({
      map: this.map,
      position: new window.kakao.maps.LatLng(
        this.props.houseDetail.house["LATITUDE"],
        this.props.houseDetail.house["LONGITUDE"]
      ),
      title: this.props.houseDetail.house["HOUSE_NAME"],
      image: new window.kakao.maps.MarkerImage(
        house_icon,
        new window.kakao.maps.Size(32, 38)
      ),
      zIndex: 50
    });

    this.overlay = new window.kakao.maps.CustomOverlay({
      map: this.map,
      position: null,
      content: "",
      xAnchor: 0.5,
      yAnchor: 1.6,
      zIndex: 999
    });

    if (window.screen.width <= 768) {
      this.overlay.setVisible(false);
    }

    this.Markers();

    this.makeShareButton();
  };

  // 마커 이미지
  Markers = () => {
    const hospitalMarker = new window.kakao.maps.MarkerImage(
      Hospital,
      new window.kakao.maps.Size(32, 32),
      new window.kakao.maps.Point(13, 34)
    );

    const bankMarker = new window.kakao.maps.MarkerImage(
      Bank,
      new window.kakao.maps.Size(32, 32),
      new window.kakao.maps.Point(13, 34)
    );

    const daisoMarker = new window.kakao.maps.MarkerImage(
      Daiso,
      new window.kakao.maps.Size(32, 32),
      new window.kakao.maps.Point(13, 34)
    );

    const drugstoreMarker = new window.kakao.maps.MarkerImage(
      Drugstore,
      new window.kakao.maps.Size(32, 32),
      new window.kakao.maps.Point(13, 34)
    );

    const fastfoodMarker = new window.kakao.maps.MarkerImage(
      fastfood,
      new window.kakao.maps.Size(32, 32),
      new window.kakao.maps.Point(13, 34)
    );

    const cafeMarker = new window.kakao.maps.MarkerImage(
      Cafe,
      new window.kakao.maps.Size(32, 32),
      new window.kakao.maps.Point(13, 34)
    );

    const laundryMarker = new window.kakao.maps.MarkerImage(
      Laundry,
      new window.kakao.maps.Size(32, 32),
      new window.kakao.maps.Point(13, 34)
    );

    const martMarker = new window.kakao.maps.MarkerImage(
      Mart,
      new window.kakao.maps.Size(32, 32),
      new window.kakao.maps.Point(13, 34)
    );

    const store24Marker = new window.kakao.maps.MarkerImage(
      store24,
      new window.kakao.maps.Size(32, 32),
      new window.kakao.maps.Point(13, 34)
    );

    const movieMarker = new window.kakao.maps.MarkerImage(
      Movie,
      new window.kakao.maps.Size(32, 32),
      new window.kakao.maps.Point(13, 34)
    );

    const policeMarker = new window.kakao.maps.MarkerImage(
      police,
      new window.kakao.maps.Size(32, 32),
      new window.kakao.maps.Point(13, 34)
    );

    const officeMarker = new window.kakao.maps.MarkerImage(
      office,
      new window.kakao.maps.Size(32, 32),
      new window.kakao.maps.Point(13, 34)
    );

    const drugMarker = new window.kakao.maps.MarkerImage(
      drug,
      new window.kakao.maps.Size(32, 32),
      new window.kakao.maps.Point(13, 34)
    );

    const hotMarker = new window.kakao.maps.MarkerImage(
      Hot,
      new window.kakao.maps.Size(32, 32),
      new window.kakao.maps.Point(13, 34)
    );
    const { placeList } = this.state;
    if (placeList && placeList.length > 0) {
      placeList.map(val => {
        const marker_position = new window.kakao.maps.LatLng(
          val["LATITUDE"],
          val["LONGITUDE"]
        );

        let marker = new window.kakao.maps.Marker({
          position: marker_position
        });

        if (val.CATEGORY === "1001") {
          marker.setImage(hospitalMarker);
        } else if (val.CATEGORY === "1002") {
          marker.setImage(drugMarker);
        } else if (val.CATEGORY === "1003") {
          marker.setImage(bankMarker);
        } else if (val.CATEGORY === "1004") {
          marker.setImage(daisoMarker);
        } else if (val.CATEGORY === "1005") {
          marker.setImage(drugstoreMarker);
        } else if (val.CATEGORY === "1006") {
          marker.setImage(fastfoodMarker);
        } else if (val.CATEGORY === "1007") {
          marker.setImage(cafeMarker);
        } else if (val.CATEGORY === "1008") {
          marker.setImage(martMarker);
        } else if (val.CATEGORY === "1009") {
          marker.setImage(store24Marker);
        } else if (val.CATEGORY === "1010") {
          marker.setImage(movieMarker);
        } else if (val.CATEGORY === "1011") {
          marker.setImage(policeMarker);
        } else if (val.CATEGORY === "1012") {
          marker.setImage(officeMarker);
        } else if (val.CATEGORY === "1013") {
          marker.setImage(laundryMarker);
        } else if (val.CATEGORY === "1100") {
          marker.setImage(hotMarker);
        } else {
          return null;
        }

        window.kakao.maps.event.addListener(
          marker,
          "click",
          this.makeOverlay.bind(
            this,
            marker_position,
            val.PLACE_NAME,
            val.DISTANCE,
            val.ROAD_ADDRESS
          )
        );

        window.kakao.maps.event.addListener(
          marker,
          "mouseover",
          this.makeOverlay.bind(
            this,
            marker_position,
            val.PLACE_NAME,
            val.DISTANCE,
            val.ROAD_ADDRESS
          )
        );

        window.kakao.maps.event.addListener(
          marker,
          "mouseout",
          this.makeOverlay.bind(this, null, "")
        );

        return marker.setMap(this.map);
      });
    }
  };

  makeOverlay = (position, place_name, distance, road_address) => {
    if (window.screen.width <= 768) {
      const visibale = this.overlay.getVisible();
      if (visibale) {
        return this.overlay.setVisible(false);
      }
    }

    if (!position || !place_name || !distance || !road_address)
      return this.overlay.setVisible(false);

    let content = "";

    if (window.screen.width > 768) {
      content = `
          <div style="
            width: 210px;
            height: 86px;
            padding: 10px;
            background: #FFFFFF;
            border: 1px solid #4F4F4F;
            border-radius: 1px;
            box-sizing: border-box;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            overflow:hidden;
            text-overflow:ellipsis;
            white-space:nowrap;
            z-index: 999;
          ">
            <div style="margin-bottom: 6px; color: #808080; overflow:hidden;">
              <h2 style="font-size: 14px; line-height: unset;">${place_name}</h2>
            </div>
            <div style="padding: 0; margin: 0; overflow:hidden;">
              <p style="font-size: 12px; color: #808080; margin:0; line-height: unset;">${distance}</p>
            </div>
            <div style="padding: 0; margin: 0; overflow:hidden;">
              <p style="font-size: 12px; color: #808080; margin:0; line-height: unset;">${road_address}</p>
            </div>
          </div>
  `;
    } else {
      content = `
            <div style="
              width: 150px;
              height: 86px;
              padding: 10px;
              background: #FFFFFF;
              border: 1px solid #4F4F4F;
              border-radius: 1px;
              box-sizing: border-box;
              box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
              overflow:hidden;
              text-overflow:ellipsis;
              white-space:nowrap;
              z-index: 999;
            ">
              <div style="margin-bottom: 6px; color: #808080;">
                <h2 style="font-size: 10px; line-height: unset;">${place_name}</h2>
              </div>
              <div style="padding: 0; margin: 0;">
                <p style="font-size: 9px; color: #808080; margin:0; line-height: unset;">${distance}</p>
              </div>
              <div style="padding: 0; margin: 0;">
                <p style="font-size: 9px; color: #808080; margin:0; line-height: unset;">${road_address}</p>
              </div>
            </div>
      `;
    }

    this.overlay.setVisible(true);
    this.overlay.setContent(content);
    this.overlay.setPosition(position);
  };

  getCompareList = () => {
    const MyStorage = localStorage;
    let nextState = this.state;
    let compareList_arr = JSON.parse(MyStorage.getItem("compared"));
    nextState["compareList"] = compareList_arr;
    nextState["favList"] = this.props.favList; // 관심하우스 목록
    this.setState(nextState);
  };

  favCheck = () => {
    const { favList } = this.state;
    let nextState = this.state;

    if (favList !== undefined && favList !== null) {
      const idArr = favList.filter(val => {
        return val.HOUSE_ID === this.state.house.HOUSE_ID;
      });
      if (idArr.length === 0) {
        nextState["favCheck"] = false;
      } else {
        nextState["favCheck"] = true;
      }
      this.setState(nextState);
    }
  };

  onScroll = e => {
    if (this.roomInfo === undefined || this.roomInfo === null) return false;
    if (window.screen.width >= 768) {
      const scrollTop = ("scroll", e.srcElement.scrollingElement.scrollTop);
      const windowWidth = window.screen.width;
      const windowHeight = document.body.clientHeight - 1080;
      const footerHeight = document.getElementById("footer").clientHeight;
      const screenValue = windowHeight - footerHeight;
      if (
        this.manager !== undefined &&
        this.manager.getBoundingClientRect().top <= 30
      ) {
        this.setState({ currentContent: 3 });
      } else if (this.house.getBoundingClientRect().top <= 30) {
        this.setState({ currentContent: 2 });
      } else if (this.roomInfo.getBoundingClientRect().top <= 30) {
        this.setState({ currentContent: 1 });
      } else if (this.houseInfo.getBoundingClientRect().top <= 30) {
        this.setState({ currentContent: 0 });
      }

      if (scrollTop > 112) {
        this.fixedInfo.style.position = "fixed";
        this.fixedInfo.style.height = "100vh";
      } else {
        this.fixedInfo.style.position = "relative";
        this.fixedInfo.style.height = "calc(100vh - 112px)";
      }
      if (scrollTop >= screenValue) {
        this.fixedInfo.style.height = "calc(100vh - " + footerHeight + "px)";
      }

      if (windowWidth > 768) {
        if (this.roomMenuHead.getBoundingClientRect().top <= -1) {
          this.roomMenu.style.position = "fixed";
          this.roomMenu.style.top = 0;
          this.roomMenu.style.width = "736px";
          this.setState({ bannerTop: true });
        } else {
          this.roomMenu.style.position = "static";
          this.roomMenu.style.width = "100%";
          this.setState({ bannerTop: false });
        }
      }
    } else {
      const scrollTop = ("scroll", e.srcElement.scrollingElement.scrollTop);
      const windowHeight = document.body.clientHeight - 1080;
      const footerHeight = document.getElementById("footer").clientHeight;
      if (
        this.manager !== undefined &&
        this.manager.getBoundingClientRect().top <= 30
      ) {
        this.setState({ currentContent: 3 });
      } else if (this.house.getBoundingClientRect().top <= 30) {
        this.setState({ currentContent: 2 });
      } else if (this.roomInfo.getBoundingClientRect().top <= 30) {
        this.setState({ currentContent: 1 });
      } else if (this.houseInfo.getBoundingClientRect().top <= 30) {
        this.setState({ currentContent: 0 });
      }

      if (this.roomMenuHead.getBoundingClientRect().top <= -1) {
        this.roomMenu.style.position = "fixed";
        this.roomMenu.style.top = 0;
        this.roomMenu.style.paddingRight = "32px";
        this.setState({ bannerTop: true });
      } else {
        this.roomMenu.style.position = "static";
        this.roomMenu.style.paddingRight = null;
        this.setState({ bannerTop: false });
      }
    }
  };

  houseMessageList = () => {
    const { messageList, fromDate, toDate } = this.props;
    let nextState = this.state;
    nextState["messageList"] = messageList;
    nextState["checkInDate"] = fromDate;
    nextState["checkOutDate"] = toDate;
    this.setState(nextState);
  };

  // 카카오 공유 버튼
  makeShareButton = () => {
    window.Kakao.cleanup();
    window.Kakao.init("7948b1ade860cba5b7eb0c9d44af8b2f"); // 사용할 앱의 JavaScript 키를 설정

    let _address = this.state.house["ADDRESS"];
    let _housename = this.state.house["HOUSE_NAME"];
    let _introduce = this.state.brand["INTRODUCE"];
    let _houseid = this.state.house["HOUSE_ID"];
    let _imgurl = this.state.pictureList[0]["PIC_URL"];

    window.Kakao.Link.createDefaultButton({
      container: "#kakao",
      objectType: "location",
      address: `${_address} ${_housename}`,
      addressTitle: `${_address} ${_housename}`,
      content: {
        title: `${_housename}`,
        description: `${_introduce}`,
        imageUrl: `${_imgurl}`,
        link: {
          mobileWebUrl: `localhost:3000/house/${_houseid}`,
          webUrl: `localhost:3000/house/${_houseid}`
        }
      },
      social: {
        likeCount: 286,
        commentCount: 45,
        sharedCount: 845
      },
      buttons: [
        {
          title: "웹으로 보기",
          link: {
            mobileWebUrl: `localhost:3000/house/${_houseid}`,
            webUrl: `localhost:3000/house/${_houseid}`
          }
        }
      ],
      success: function(response) {
        console.log(response);
      },
      fail: function(error) {
        console.log(error);
      }
    });
  };

  facebookShareButton = () => {
    const page = encodeURIComponent(window.location.href);
    document.getElementById("og:url").setAttribute("content", page);
    document
      .getElementById("og:title")
      .setAttribute(
        "content",
        this.state.brand.BRAND_NAME + " " + this.state.house.HOUSE_NAME
      );
    document
      .getElementById("og:description")
      .setAttribute(
        "content",
        this.state.brand.BRAND_NAME + " " + this.state.house.HOUSE_NAME
      );
    document
      .getElementById("og:image")
      .setAttribute("content", this.state.brand.LOGO_PIC_URL);

    window.open(`https://www.facebook.com/sharer/sharer.php?href=${page}`);
  };

  twitterShareButton = () => {
    const page = encodeURIComponent(window.location.href);
    window.open(`https://twitter.com/intent/tweet?url=${page}`);
  };

  normalShareLink = () => {
    const page = window.location.href;
    copy(page);
  };

  onChange = e => {
    const ori_RoomName = e.target.value;
    // const RoomName = ori_RoomName.split("(")[0].trim();
    let nextState = this.state;
    nextState["NowCaption"] = ori_RoomName;
    this.setState({ nextState });

    this.ChangeImages();
  };

  onClickNowCaption = e => {
    let nextState = this.state;
    nextState["NowCaption"] = e.target.value;
    this.setState({ nextState });

    this.ChangeImages();
  };

  // 이미지 변경
  ChangeImages = () => {
    let nextState = this.state;
    let idx = 0;
    const BRAND_NAME = nextState["brand"]["BRAND_NAME"];
    const HOUSE_NAME = nextState["house"]["HOUSE_NAME"];
    const { t } = this.props;
    nextState["images"] = [];
    nextState["pictureList"].forEach(item => {
      if (item["ROOM_CODE"] === nextState["NowCaption"]) {
        nextState["images"].push({
          original:
            window.screen.width > 768
              ? item["PIC_URL"]
              : item["EMPTY_YN"] === "N" && item["PIC_URL"],
          thumbnail:
            window.screen.width > 768
              ? item["THUMB_URL"]
              : item["EMPTY_YN"] === "N" && item["THUMB_URL"],
          description: BRAND_NAME + " " + HOUSE_NAME + " " + item["CAPTION"],
          renderItem: this.Image
        });
      } else {
        idx++;
      }
    });
    if (idx === nextState["pictureList"].length) {
      alert(nextState["NowCaption"] + t("inform.pictureAlert"));
      nextState["NowCaption"] = "공용공간";
      nextState["pictureList"].forEach(item => {
        nextState["images"].push({
          original: item["PIC_URL"],
          thumbnail: item["THUMB_URL"],
          description: BRAND_NAME + " " + HOUSE_NAME + " " + item["CAPTION"],
          renderItem: this.Image
        });
      });
    }

    this.setState({ nextState });
  };

  rebuild_map = () => {
    const options = {
      center: new window.kakao.maps.LatLng(
        this.state.house["LATITUDE"],
        this.state.house["LONGITUDE"]
      ),
      level: 3,
      position: new window.kakao.maps.LatLng(
        this.state.house["LATITUDE"],
        this.state.house["LONGITUDE"]
      )
    };
    this.map = new window.kakao.maps.Map(this.mapRef, options);
  };

  // 하우스 상세페에지 입주문의 입주문의 메세지 입력시 사용자 입력한것 state 에 저장..
  MessageChn = e => {
    let nextState = this.state;
    nextState["InqMessage"] = e.target.value;
    this.setState(nextState);
  };

  scrollToHouseInfo = () => {
    this.houseInfo.scrollIntoView({ behavior: "smooth" });
  };

  scrollToRoomInfo = () => {
    this.roomInfo.scrollIntoView({ behavior: "smooth" });
  };

  scrollToHouse = () => {
    this.house.scrollIntoView({ behavior: "smooth" });
  };

  scrollToManager = () => {
    const { t } = this.props;
    if (this.manager === undefined) {
      alert(t("inform.managerAlert"));
      return;
    }

    this.manager.scrollIntoView({ behavior: "smooth" });
  };

  setSelectAll = () => {
    let nextState = this.state;
    nextState["selectState"] = true;
    nextState["roomCount"] = this.state.roomList.length;
    nextState["checkIndex"] = 0;
    this.setState(nextState);
    this.room_info_table.style.marginLeft = 0;
  };

  setSelectEmpty = () => {
    let nextState = this.state;
    nextState["selectState"] = false;
    nextState["roomCount"] = this.state.roomList.filter(
      val => val["ROOM_STATUS"] !== "0003"
    ).length;
    nextState["checkIndex"] = 0;
    this.setState(nextState);
    this.room_info_table.style.marginLeft = 0;
  };

  viewDetail = key => {
    let clickView = document.getElementsByClassName("clickView" + key);
    const textCheck = document.getElementsByClassName(key);
    const text = textCheck[0].innerHTML;
    let dis1 = clickView[0].style.display;
    let dis2 = clickView[1].style.display;
    if (dis1 === "none" && text !== "") {
      document.getElementsByClassName("clickView" + key)[0].style.display =
        "table-row";
    } else {
      document.getElementsByClassName("clickView" + key)[0].style.display =
        "none";
    }
    if (dis2 === "none") {
      document.getElementsByClassName("clickView" + key)[1].style.display =
        "table-row";
    } else {
      document.getElementsByClassName("clickView" + key)[1].style.display =
        "none";
    }
  };

  openModal = e => {
    if (e !== undefined && e !== null) {
      e.preventDefault();
    }
    this.setState({ isModalOpen: true });
  };

  closeModal = () => {
    this.getCompareList();
    this.setState({ isModalOpen: false });
  };

  closeLoginModal = () => {
    this.setState({ isLoginModalOpen: false });
  };

  // 이미지 에러
  onImageError = e => {
    e.currentTarget.src = ErrorImg;
  };

  checkInCalendarView = () => {
    let nextState = this.state;
    nextState["checkInCalendar"] = !nextState["checkInCalendar"];
    nextState["checkOutCalendar"] = false;
    this.setState(nextState);
  };

  checkOutCalendarView = () => {
    let nextState = this.state;
    nextState["checkInCalendar"] = false;
    nextState["checkOutCalendar"] = !nextState["checkOutCalendar"];
    this.setState(nextState);
  };

  // 체크인
  handleCheckInDate = e => {
    let nextState = this.state;
    let year = e.getFullYear();
    let month = parseInt(e.getMonth() + 1);
    if (month < 10) {
      month = "0" + month;
    }
    let day = e.getDate();
    if (day < 10) {
      day = "0" + day;
    }
    let date = year + "-" + month + "-" + day;

    nextState["checkInDate"] = date;
    nextState["checkInCalendar"] = false;

    this.setState(nextState);
  };

  // 체크아웃
  handleCheckOutDate = e => {
    let nextState = this.state;
    let year = e.getFullYear();
    let month = parseInt(e.getMonth() + 1);
    if (month < 10) {
      month = "0" + month;
    }
    let day = e.getDate();
    if (day < 10) {
      day = "0" + day;
    }
    let date = year + "-" + month + "-" + day;
    nextState["checkOutDate"] = date;
    nextState["checkOutCalendar"] = false;

    this.setState(nextState);
  };

  // 상담하기 열기
  questionView = () => {
    if (!this.props.isLogined) {
      this.setState({ isLoginModalOpen: true });
      return;
    }
    let nextState = this.state;
    nextState["questionOn"] = true;

    this.setState(nextState, () => {
      if (!this.chatScroll.current || !this.chatScroll.current.scrollHeight)
        return;

      const scrollHeight = this.chatScroll.current.scrollHeight;

      this.chatScroll.current.scroll(0, scrollHeight);
    });
  };

  // 상담하기 닫기
  closeQuestion = () => {
    let nextState = this.state;
    nextState["questionOn"] = false;
    this.setState(nextState);
  };

  // 관심 하우스 추가
  AddFav = (e, houseid) => {
    e.preventDefault();

    if (!this.props.isLogined) {
      this.setState({ isLoginModalOpen: true });
    } else {
      this.props.AddFavHouse(houseid);

      let nextState = this.state;

      nextState["favCheck"] = true;

      this.setState(nextState);

      return;
    }
  };

  // 관심 하우스 제거
  DelFav = (e, houseid) => {
    e.preventDefault();

    if (!this.props.isLogined) {
      this.setState({ isLoginModalOpen: true });
    } else {
      this.props.delFavHouse(houseid);

      let nextState = this.state;

      nextState["favCheck"] = false;

      this.setState(nextState);

      return;
    }
  };

  AddComparelist = async houseId => {
    await this.props.AddCompare(houseId);
    this.getCompareList();
  };

  houseMessageSend = async e => {
    e.preventDefault();

    const {
      house,
      InqMessage,
      inquiry,
      checkInDate,
      checkOutDate,
      ROOM_NAME
    } = this.state;

    const { HouseInquery } = this.props;

    const message = InqMessage.trim();
    if (!InqMessage || message === "") return null;

    await HouseInquery({
      HOUSE_ID: house.HOUSE_ID,
      MESSAGE: InqMessage,
      INQUIRY_ID: inquiry.INQUIRY_ID,
      FROM_DATE: checkInDate,
      TO_DATE: checkOutDate,
      ROOM_CODE: ROOM_NAME
    });

    let nextState = this.state;
    nextState["InqMessage"] = "";
    this.setState(nextState);

    const scrollHeight = this.chatScroll.current.scrollHeight;

    this.chatScroll.current.scroll(0, scrollHeight);
  };

  goto = item => {
    if (item["ARTICLE_TYPE"] === "1") {
      this.props.history.push(item["ARTICLE_URL"]);
    } else {
      window.open(item["ARTICLE_URL"]);
    }
  };

  imageClick = () => {
    if (!this.state.fullScreenItem) {
      this.imgRef.fullScreen();
      document.getElementById("root").style.position = "fixed";
      document.getElementById("root").style.width = "100%";
      document.getElementById("root").style.height = "100%";
      document.getElementById("roomchating").style.display = "none";
    } else {
      this.imgRef.exitFullScreen();
      document.getElementById("root").style.position = "static";
      document.getElementById("root").style.width = "auto";
      document.getElementById("root").style.height = "auto";
      document.getElementById("roomchating").style.display = "block";
    }
  };

  wrapInfo = index => {
    let nextState = this.state;
    nextState["checkIndex"] = index;
    this.setState(nextState);
    let tbodyWidth = 0;
    if (this.state.selectState) {
      // 전체보기
      tbodyWidth = this.all_room[index].clientWidth;
    } else {
      // 공실
      tbodyWidth = this.empty_room[index].clientWidth;
    }

    const roomInfo = this.room_info;
    const iosScroll = function(to, duration) {
      const element = roomInfo,
        start = element.scrollLeft,
        change = to - start,
        startDate = +new Date(),
        // t = current time
        // b = start value
        // c = change in value
        // d = duration
        easeInOutQuad = function(t, b, c, d) {
          t /= d / 2;
          if (t < 1) return (c / 2) * t * t + b;
          t--;
          return (-c / 2) * (t * (t - 2) - 1) + b;
        },
        animateScroll = function() {
          const currentDate = +new Date();
          const currentTime = currentDate - startDate;
          element.scrollLeft = parseInt(
            easeInOutQuad(currentTime, start, change, duration)
          );
          if (currentTime < duration) {
            requestAnimationFrame(animateScroll);
          } else {
            element.scrollLeft = to;
          }
        };
      animateScroll();
    };
    if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
      // iOS 아이폰, 아이패드, 아이팟
      iosScroll(tbodyWidth * index, 500);
    } else {
      this.room_info.scrollTo({
        left: tbodyWidth * index,
        top: 0,
        behavior: "smooth"
      });
    }
  };

  selectRoom = e => {
    let nextState = this.state;
    nextState[e.target.name] = e.target.value;
    this.setState(nextState);
  };

  onMobileChat = e => {
    if (e) {
      let nextState = this.state;
      nextState["mobileChatOpen"] = true;
      this.setState(nextState);
      window.scrollTo(0, 0);
    }
    document.getElementById("root").style.overflow = "hidden";
    document.getElementById("root").style.position = "fixed";
    document.getElementById("root").style.width = "100%";
    document.getElementById("root").style.height = "100%";
  };

  offMobileChat = () => {
    let nextState = this.state;
    nextState["mobileChatOpen"] = false;
    this.setState(nextState);
    document.getElementById("root").style.overflow = "visible";
    document.getElementById("root").style.position = "static";
    document.getElementById("root").style.width = "auto";
    document.getElementById("root").style.height = "auto";
  };

  roomInfoChange = e => {
    this.setState({ youngRoom: e });
    $(".slick-dots>li>button")
      .eq(e)
      .click();
  };

  roomInfoView = e => {
    const roomNum = this.young_room.length;
    for (let i = 0; i < roomNum; i++) {
      if (e === i) {
        if (this.young_room[i].style.display === "none") {
          this.young_room[i].style.display = "table-row";
        } else {
          this.young_room[i].style.display = "none";
        }
      } else {
        this.young_room[i].style.display = "none";
      }
    }
  };

  roomInfoViewM = e => {
    const roomNum = this.young_room_m.length;
    for (let i = 0; i < roomNum; i++) {
      if (e === i) {
        if (this.young_room_m[i].style.display === "none") {
          this.young_room_m[i].style.display = "table-row";
        } else {
          this.young_room_m[i].style.display = "none";
        }
      } else {
        this.young_room_m[i].style.display = "none";
      }
    }
  };

  spaceScroll = e => {
    const spaceScrollLeft = this.spaceContent.clientWidth * e;
    let nextState = this.state;
    nextState["spaceHead"] = e;
    this.setState(nextState);

    const spaceContent = this.spaceContent;
    const iosScroll = function(to, duration) {
      const element = spaceContent,
        start = element.scrollLeft,
        change = to - start,
        startDate = +new Date(),
        // t = current time
        // b = start value
        // c = change in value
        // d = duration
        easeInOutQuad = function(t, b, c, d) {
          t /= d / 2;
          if (t < 1) return (c / 2) * t * t + b;
          t--;
          return (-c / 2) * (t * (t - 2) - 1) + b;
        },
        animateScroll = function() {
          const currentDate = +new Date();
          const currentTime = currentDate - startDate;
          element.scrollLeft = parseInt(
            easeInOutQuad(currentTime, start, change, duration)
          );
          if (currentTime < duration) {
            requestAnimationFrame(animateScroll);
          } else {
            element.scrollLeft = to;
          }
        };
      animateScroll();
    };
    if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
      // iOS 아이폰, 아이패드, 아이팟
      iosScroll(spaceScrollLeft, 500);
    } else {
      this.spaceContent.scrollTo({
        left: spaceScrollLeft,
        top: 0,
        behavior: "smooth"
      });
    }
  };
  spaceContentScroll = () => {
    let spaceLeft = this.spaceContent.scrollLeft;
    let scrollLeftValue = spaceLeft / this.spaceContent.clientWidth;
    let nextState = this.state;
    nextState["spaceHead"] = Math.round(scrollLeftValue);
    this.setState(nextState);
  };

  roomScroll = () => {
    let nextState = this.state;
    let checkIndexCal = Math.round(
      this.room_info.scrollLeft / this.room_info.clientWidth
    );
    nextState["checkIndex"] = checkIndexCal;
    this.setState(nextState);

    let movePer = this.room_info.scrollLeft / this.room_info.scrollWidth;
    let nameScroll = this.roomName_scroll.scrollWidth * movePer;

    this.roomName_scroll.scrollTo({
      left: nameScroll,
      top: 0,
      behavior: "smooth"
    });
  };

  render() {
    // if (!this.state.isLoading) {
    //   // localstorage 에 현재 하우스가 최근본 하우스 로 등록이 되어있는지 되어있으면 안넣고 안되어 있으면 넣음...
    //   if (JSON.stringify(this.state.HouseDetail) !== "{}") {
    //     const myStorage = localStorage;
    //     const viewedhouse = myStorage.getItem("viewdhouse");
    //     if (viewedhouse !== "") {
    //       const viewedhouseArr = viewedhouse.split("|");

    //       if (viewedhouseArr.length >= 20) {
    //         viewedhouseArr.pop();
    //       }

    //       const update_viewdhouse =
    //         this.state.house["HOUSE_ID"] + "|" + viewedhouseArr.join("|");

    //       if (
    //         this.state.house["HOUSE_ID"] !== undefined &&
    //         this.state.house["HOUSE_ID"] !== null &&
    //         this.state.house["HOUSE_ID"] !== ""
    //       ) {
    //         if (viewedhouse.indexOf(this.state.house["HOUSE_ID"]) === -1) {
    //           myStorage.setItem("viewdhouse", update_viewdhouse);
    //         } else {
    //           console.log("dd");
    //           myStorage.removeItem("viewdhouse");
    //           myStorage.setItem("viewdhouse", update_viewdhouse);
    //         }
    //       }
    //     } else {
    //       const update_viewdhouse = this.state.house["HOUSE_ID"];
    //       if (
    //         this.state.house["HOUSE_ID"] !== undefined &&
    //         this.state.house["HOUSE_ID"] !== null &&
    //         this.state.house["HOUSE_ID"] !== ""
    //       ) {
    //         if (viewedhouse.indexOf(update_viewdhouse) === -1) {
    //           myStorage.setItem("viewdhouse", update_viewdhouse);
    //         }
    //       }
    //     }
    //   }
    // }

    const { currentContent } = this.state;
    const { t } = this.props;
    const settingsYoung = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 2,
      slidesToScroll: 2,
      autoplay: true,
      autoplaySpeed: 3000,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesPerRow: 1,
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: false,
            dots: false,
            autoplay: false,
            autoplaySpeed: 3000,
            onSwipe: e => {
              let nextState = this.state;
              if (e === "left") {
                if (this.state.youngRoom + 1 === this.state.roomListCount) {
                  nextState["youngRoom"] = this.state.youngRoom;
                } else {
                  nextState["youngRoom"] = this.state.youngRoom + 1;
                }
              } else if (e === "right") {
                if (this.state.youngRoom !== 0) {
                  nextState["youngRoom"] = this.state.youngRoom - 1;
                } else {
                  nextState["youngRoom"] = 0;
                }
              }
              this.setState(nextState);
              let infoScrollLeft =
                this.infoHead.scrollWidth / this.state.roomListCount;
              this.infoHead.scrollTo({
                left: infoScrollLeft * this.state.youngRoom,
                top: 0,
                behavior: "smooth"
              });
            }
          }
        }
      ]
    };
    const settingsSpace = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 2,
      slidesToScroll: 1,
      autoplay: false,
      autoplaySpeed: 3000
    };
    const settingsinfo = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: false,
      autoplaySpeed: 3000,
      onSwipe: e => {
        let nextState = this.state;
        if (e === "left") {
          if (this.state.youngRoom + 1 === this.state.roomListCount) {
            nextState["youngRoom"] = this.state.youngRoom;
          } else {
            nextState["youngRoom"] = this.state.youngRoom + 1;
          }
        } else if (e === "right") {
          if (this.state.youngRoom !== 0) {
            nextState["youngRoom"] = this.state.youngRoom - 1;
          } else {
            nextState["youngRoom"] = 0;
          }
        }
        this.setState(nextState);
        let infoScrollLeft =
          this.infoHead.scrollWidth / this.state.roomListCount;
        this.infoHead.scrollTo({
          left: infoScrollLeft * this.state.youngRoom,
          top: 0,
          behavior: "smooth"
        });
      }
    };

    return (
      <div className={cx("detail-all-wrap")}>
        <div className={cx("detail-wrap")}>
          <div className={cx("detail-content")}>
            <div className={cx("slider-button")}>
              <button
                className={cx("type-button")}
                onClick={this.onClickNowCaption}
                value="H001"
              >
                {t("inform.commonspace")}
              </button>
              {this.state.house["RENT_TYPE"] !== "0002" && (
              <button
                className={cx("floor-button")}
                onClick={this.onClickNowCaption}
                value="H002"
              >
                {t("inform.outlook")}
              </button>
              )}
              <select
                className={cx("room-selector")}
                onChange={this.onChange}
                defaultValue="H001"
              >
                <option value="H001">{t("inform.roomchoice")}</option>

                {this.state.roomList.map((items) => {
                  return (
                    <option key={items["ROOM_CODE"]} value={items["ROOM_CODE"]}>
                      {items["ROOM_NAME"]}
                    </option>
                  );
                })}
              </select>
              {this.state.house["VR_HOUSE_YN"] === "Y" && (
                <button
                  className={cx("vr-see")}
                  onClick={() => window.open(this.state.house["VR_URL"])}
                >
                  <img src={vr_see} alt="vr-see" />
                  {t("inform.vr")}
                </button>
              )}
            </div>
            {/* 이미지 슬라이더 */}
            <ImageGallery
              ref={(ref) => (this.imgRef = ref)}
              onClick={this.imageClick}
              items={this.state.images}
              showPlayButton={false}
              lazyLoad={false}
              isFullscreen={true}
              showThumbnails={
                !this.state.fullScreenItem && window.screen.width > 768
              }
              onScreenChange={(e) => {
                if (e) {
                  this.setState({ ...this.state, fullScreenItem: true });
                  document.getElementById("root").style.position = "fixed";
                  document.getElementById("root").style.width = "100%";
                  document.getElementById("root").style.height = "100%";
                  document.getElementById("roomchating").style.display = "none";
                } else {
                  this.setState({ ...this.state, fullScreenItem: false });
                  document.getElementById("root").style.position = "static";
                  document.getElementById("root").style.width = "auto";
                  document.getElementById("root").style.height = "auto";
                  document.getElementById("roomchating").style.display =
                    "block";
                }
              }}
            />
            <MobileDetailMenu
              states={this.state}
              AddFav={this.AddFav}
              DelFav={this.DelFav}
              AddComparelist={this.AddComparelist}
              openModal={this.openModal}
              swipingTransitionDuration={300}
              isLogined={this.props.isLogined}
              HOUSE_ID={this.props.houseDetail.house["HOUSE_ID"]}
              messageList={this.props.messageList}
              HouseInquery={this.props.HouseInquery}
              getHouseDetail={this.props.getHouseDetail}
              onMobileChat={this.onMobileChat}
              offMobileChat={this.offMobileChat}
              fromDate={this.props.fromDate}
              toDate={this.props.toDate}
            />
            <div
              className={cx("house-content")}
              ref={(ref) => {
                this.roomMenuHead = ref;
              }}
            >
              {this.state.bannerTop && (
                <div
                  className={cx("banner-empty-div")}
                  ref={(ref) => {
                    this.bannerDiv = ref;
                  }}
                ></div>
              )}
              <div
                className={cx("content-category")}
                ref={(ref) => {
                  this.roomMenu = ref;
                }}
              >
                {this.state.house["RENT_TYPE"] === "0002" ? (
                  <button
                    className={cx({ "select-btn": currentContent === 0 })}
                    onClick={this.scrollToHouseInfo}
                  >
                    {t("inform.youngHouseDetail")}
                  </button>
                ) : (
                  <button
                    className={cx({ "select-btn": currentContent === 0 })}
                    onClick={this.scrollToHouseInfo}
                  >
                    {t("inform.housedetail")}
                  </button>
                )}
                <button
                  className={cx({ "select-btn": currentContent === 1 })}
                  onClick={this.scrollToRoomInfo}
                >
                  {t("inform.roominfo")}
                </button>
                <button
                  className={cx({ "select-btn": currentContent === 2 })}
                  onClick={this.scrollToHouse}
                >
                  {t("inform.houseintro")}
                </button>
                <button
                  className={cx({ "select-btn": currentContent === 3 })}
                  onClick={this.scrollToManager}
                >
                  {t("inform.introducing")}
                </button>
              </div>
              {this.state.house["CAMPAIGN_YN"] === "Y" && (
                <div className={cx("content-event-banner")}>
                  <div className={cx("banner-rectangle")}>
                    {t("inform.event")}
                  </div>
                  <div className={cx("banner-title")}>
                    {this.state.house["CAMPAIGN_TITLE"]}
                  </div>
                  <div className={cx("banner-content")}>
                    <div>
                      <span>{t("inform.content")}:</span>
                      <span>{this.state.house["CAMPAIGN_TEXT"]}</span>
                    </div>
                    <div>
                      <span>{t("inform.person")}:</span>
                      <span>{this.state.house["CAMPAIGN_TARGET"]}</span>
                    </div>
                    <div>
                      <span>{t("inform.date")}:</span>
                      <span>
                        {this.state.house["CAMPAIGN_FROM"]}{" "}
                        {(this.state.house["CAMPAIGN_FROM"] ||
                          this.state.house["CAMPAIGN_TO"]) &&
                          "~"}{" "}
                        {this.state.house["CAMPAIGN_TO"]}
                      </span>
                    </div>
                  </div>
                </div>
              )}
              <div
                className={cx("comeToMe")}
                ref={(ref) => {
                  this.houseInfo = ref;
                }}
              ></div>
              <div className={cx("more-information")}>
                <h1>
                  {this.state.house["RENT_TYPE"] === "0002"
                    ? t("inform.youngHouseDetail")
                    : t("inform.housedetail")}
                </h1>
                <div className={cx("info-detail")}>
                  <div>
                    <div>
                      <span>{t("inform.houseopendate")}</span>
                      <h1>{this.state.brand["OPEN_DATE"]}</h1>
                    </div>

                    <div>
                      <span>{t("inform.area")}</span>
                      <h1>{this.state.house["ADDRESS"]}</h1>
                    </div>
                  </div>

                  <div>
                    {this.state.house["DEPOSIT"] && (
                      <div>
                        <span>{t("inform.rentfee")}</span>
                        <h1>{this.state.house["DEPOSIT"]}</h1>
                      </div>
                    )}

                    {this.state.house["RENTFEE"] && (
                      <div>
                        <span>{t("inform.feepermonth")}</span>
                        <h1>{this.state.house["RENTFEE"]}</h1>
                      </div>
                    )}
                  </div>
                </div>

                {/* 하우스 정보 more */}
                {this.state.house["RENT_TYPE"] === "0002" ? (
                  <div className={cx("info-detail-more")}>
                    {this.state.house["ROOM_LIST"] && (
                      <div className={cx("t-row")}>
                        <div className={cx("t-cell")}>
                          <span>{t("inform.roomSize")}</span>
                        </div>
                        <div className={cx("t-cell")}>
                          {ReactHtmlParser(this.state.house["ROOM_LIST"])}
                          <br />
                          {ReactHtmlParser(this.state.house["HOUSEHOLD"])}
                        </div>
                      </div>
                    )}
                    {this.state.house["TARGET_2030"] && (
                      <div className={cx("t-row")}>
                        <div className={cx("t-cell")}>
                          <span>{t("inform.terget")}</span>
                        </div>
                        <div className={cx("t-cell")}>
                          {ReactHtmlParser(this.state.house["TARGET_2030"])}
                        </div>
                      </div>
                    )}
                    {this.state.house["ETC_2030"] && (
                      <div className={cx("t-row")}>
                        <div className={cx("t-cell")}>
                          <span>{t("inform.etc")}</span>
                        </div>
                        <div className={cx("t-cell")}>
                          {ReactHtmlParser(this.state.house["ETC_2030"])}
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  <div className={cx("info-detail-more")}>
                    {this.state.house["MANAGE_FEE_AMT"] && (
                      <div className={cx("t-row")}>
                        <div className={cx("t-cell")}>
                          <span>{t("inform.fixocost")}</span>
                        </div>
                        <div className={cx("t-cell")}>
                          {this.state.house["MANAGE_FEE_AMT"]}
                        </div>
                      </div>
                    )}

                    {this.state.house["ROOM_CNT"] && (
                      <div className={cx("t-row")}>
                        <div className={cx("t-cell")}>
                          <span>{t("inform.roomnumb")}</span>
                        </div>
                        <div className={cx("t-cell")}>
                          {this.state.house["ROOM_CNT"]}
                          {this.state.house["RENT_TYPE"] === "0001" ? this.state.house["BATHROOM_CNT"] && (
                            <>
                              <span>|</span> {t("inform.toilet")}{" "}
                              {this.state.house["BATHROOM_CNT"]}
                            </>
                          ):""}
                        </div>
                      </div>
                    )}

                    {this.state.house["MOVEIN_CONDITION"] && (
                      <div className={cx("t-row")}>
                        <div className={cx("t-cell")}>
                          <span>{t("inform.condition")}</span>
                        </div>
                        <div className={cx("t-cell")}>
                          {this.state.house["MOVEIN_CONDITION"]}
                        </div>
                      </div>
                    )}

                    {this.state.house["HOUSE_SERVICE"] && (
                      <div className={cx("t-row")}>
                        <div className={cx("t-cell")}>
                          <span>{t("inform.availableservice")}</span>
                        </div>
                        <div className={cx("t-cell")}>
                          {this.state.house["HOUSE_SERVICE"]}
                        </div>
                      </div>
                    )}
                    {this.state.house["HOUSE_SIZE"] && (
                      <div className={cx("t-row")}>
                        <div className={cx("t-cell")}>
                          <span>{t("inform.edicated")}</span>
                        </div>
                        <div className={cx("t-cell")}>
                          {this.state.house["HOUSE_SIZE"]}
                        </div>
                      </div>
                    )}
                    {this.state.facility1List.length > 0 && (
                      <div className={cx("t-row")}>
                        <div className={cx(["t-cell", "appender"])}>
                          <span>{t("inform.facilities")}</span>
                        </div>
                        <div className={cx("t-cell")}>
                          <div className={cx("t-row")}>
                            <div className={cx("t-cell")}>
                              {t("inform.commonfacilities")}
                            </div>
                            <div>
                              {this.state.facility1List.length > 0 &&
                                this.state.facility1List.map((items) => {
                                  return (
                                    <div
                                      className={cx("t-cell")}
                                      key={items["FA_CODE"]}
                                    >
                                      <img
                                        src={items["ICON_FILE_URL"]}
                                        alt="stuff"
                                      />
                                      <span className={cx("product")}>
                                        {items["FA_NAME"]}
                                      </span>
                                    </div>
                                  );
                                })}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {this.state.facility2List.length > 0 && (
                      <div className={cx("t-row")}>
                        <div className={cx("t-cell")}>
                          <span></span>
                        </div>
                        <div className={cx("t-cell")}>
                          <div className={cx("t-row")}>
                            <div className={cx("t-cell")}>
                              {t("inform.kitchen")}
                            </div>
                            <div>
                              {this.state.facility2List.length > 0 &&
                                this.state.facility2List.map((items) => {
                                  return (
                                    <div
                                      className={cx("t-cell")}
                                      key={items["FA_CODE"]}
                                    >
                                      <img
                                        src={items["ICON_FILE_URL"]}
                                        alt="stuff"
                                      />
                                      <span className={cx("product")}>
                                        {items["FA_NAME"]}
                                      </span>
                                    </div>
                                  );
                                })}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}
                <div
                  className={cx("comeToMe")}
                  ref={(ref) => {
                    this.roomInfo = ref;
                  }}
                ></div>

                {/* 방정보 */}
                {this.state.house["RENT_TYPE"] === "0002" ? (
                  <div className={cx(["room_infomation-young", "dp-none"])}>
                    <h1>{t("inform.roominfo")}</h1>
                    {/* pc화면 */}
                    {window.screen.width > 768 ? (
                      <>
                        <div className={cx("floor-infomation")}>
                          <div
                            className={cx("infomation-left")}
                            ref={(ref) => {
                              this.infoHead = ref;
                            }}
                          >
                            {this.state.roomList.map((val, index) => {
                              return (
                                <div
                                  key={index}
                                  className={cx({
                                    checked: this.state.youngRoom === index,
                                  })}
                                  onClick={() => this.roomInfoChange(index)}
                                  ref={(ref) => {
                                    this.floorHead[index] = ref;
                                  }}
                                >
                                  {val["ROOM_NAME"]}({val["ROOM_SIZE"]})
                                </div>
                              );
                            })}
                          </div>
                          <div className={cx("infomation-right")}>
                            {this.state.roomList.map((val, index) => {
                              return (
                                <img
                                  key={index}
                                  src={val["THUMB_URL"]}
                                  alt="roomThumb"
                                  className={cx({
                                    checked: this.state.youngRoom === index,
                                  })}
                                />
                              );
                            })}
                          </div>
                          <div className={cx("clear")}></div>
                        </div>
                        <div className={cx("young_table")}>
                          <table>
                            <thead>
                              <tr>
                                <th rowSpan="2">
                                  {t("inform.roomName")}
                                  <br />
                                  (㎡)
                                </th>
                                <th rowSpan="2">
                                  {t("inform.contractSize")}
                                  <br />
                                  (㎡)
                                </th>
                                <th colSpan="3">{t("inform.depoitNfee")}</th>
                                <th rowSpan="2">{t("inform.manage")}</th>
                                <th rowSpan="2">{t("inform.facilities")}</th>
                              </tr>
                              <tr>
                                <th>{t("inform.depositPer")} {this.state.roomList[0].RATIO_2030_1}%</th>
                                <th>{t("inform.depositPer")} {this.state.roomList[0].RATIO_2030_2}%</th>
                                <th>{t("inform.depositPer")} {this.state.roomList[0].RATIO_2030_3}%</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.roomList.map((val, index) => {
                                return (
                                  <React.Fragment key={index}>
                                    <tr>
                                      <td>{val["ROOM_NAME"]}</td>
                                      <td>{val["ROOM_SIZE"]}</td>
                                      <td>
                                        {val["DEPOSIT_2030_1"]} <br />{" "}
                                        {val["RENTFEE_2030_1"]}
                                      </td>
                                      <td>
                                        {val["DEPOSIT_2030_2"]} <br />{" "}
                                        {val["RENTFEE_2030_2"]}
                                      </td>
                                      <td>
                                        {val["DEPOSIT_2030_3"]} <br />{" "}
                                        {val["RENTFEE_2030_3"]}
                                      </td>
                                      <td>
                                        {this.state.house["MANAGE_FEE_AMT"]}
                                      </td>
                                      <td
                                        onClick={() => {
                                          this.roomInfoView(index);
                                        }}
                                      >
                                        <img src={Glass} alt="더보기" />
                                      </td>
                                    </tr>
                                    <tr
                                      ref={(ref) =>
                                        (this.young_room[index] = ref)
                                      }
                                      style={{ display: "none" }}
                                    >
                                      <td>{t("inform.facilities")}</td>
                                      <td colSpan="6">{val["facilities"]}</td>
                                    </tr>
                                  </React.Fragment>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </>
                    ) : (
                      <>
                        {/* 모바일 화면 */}
                        <div className={cx("floor-infomation_m")}>
                          <div
                            className={cx("infomation-left")}
                            ref={(ref) => {
                              this.infoHead = ref;
                            }}
                          >
                            {this.state.roomList.map((val, index) => {
                              return (
                                <div
                                  key={index}
                                  className={cx({
                                    checked: this.state.youngRoom === index,
                                  })}
                                  onClick={() => this.roomInfoChange(index)}
                                  ref={(ref) => {
                                    this.floorHead[index] = ref;
                                  }}
                                >
                                  {val["ROOM_NAME"]}({val["ROOM_SIZE"]})
                                </div>
                              );
                            })}
                          </div>
                          <Slider {...settingsinfo}>
                            {this.state.roomList.map((val, index) => {
                              return (
                                <div className={cx("contentWrap")} key={index}>
                                  <img
                                    key={index}
                                    src={val["THUMB_URL"]}
                                    alt="roomThumb"
                                    className={cx({
                                      checked: this.state.youngRoom === index,
                                    })}
                                    ref={(ref) => {
                                      this.floorContent[index] = ref;
                                    }}
                                  />
                                  <table
                                    className={cx({
                                      checked: this.state.youngRoom === index,
                                    })}
                                  >
                                    <tbody>
                                      <tr>
                                        <th>
                                          {t("inform.roomName")}
                                          (㎡)
                                        </th>
                                        <td colSpan="2">{val["ROOM_NAME"]}</td>
                                      </tr>
                                      <tr>
                                        <th>
                                          {t("inform.contractSize")}
                                          <br />
                                          (㎡)
                                        </th>
                                        <td colSpan="2">{val["ROOM_SIZE"]}</td>
                                      </tr>
                                      <tr>
                                        <th rowSpan="3">
                                          {t("inform.depoitNfee")}
                                        </th>
                                        <th>{t("inform.depositPer")} {val["RATIO_2030_1"]}%</th>
                                        <td>
                                          {val["DEPOSIT_2030_1"]} <br />{" "}
                                          {val["RENTFEE_2030_1"]}
                                        </td>
                                      </tr>
                                      <tr>
                                        <th>{t("inform.depositPer")} {val["RATIO_2030_2"]}%</th>
                                        <td>
                                          {val["DEPOSIT_2030_2"]} <br />{" "}
                                          {val["RENTFEE_2030_2"]}
                                        </td>
                                      </tr>
                                      <tr>
                                        <th>{t("inform.depositPer")} {val["RATIO_2030_3"]}%</th>
                                        <td>
                                          {val["DEPOSIT_2030_3"]} <br />{" "}
                                          {val["RENTFEE_2030_3"]}
                                        </td>
                                      </tr>
                                      <tr>
                                        <th>{t("inform.manage")}</th>
                                        <td colSpan="2">
                                          {this.state.house["MANAGE_FEE_AMT"]}
                                        </td>
                                      </tr>
                                      <tr>
                                        <th>{t("inform.facilities")}</th>
                                        <td colSpan="2">{val["facilities"]}</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              );
                            })}
                          </Slider>
                        </div>
                      </>
                    )}
                  </div>
                ) : (
                  <div className={cx("room_infomation")}>
                    <h1>
                      {t("inform.roominfo")}
                      <span
                        data-select={this.state.selectState}
                        onClick={this.setSelectAll}
                      >
                        {t("inform.all")}
                      </span>
                      <span
                        data-select={!this.state.selectState}
                        onClick={this.setSelectEmpty}
                      >
                        {t("inform.onlyempty")}
                      </span>
                    </h1>
                    <h2
                      className={cx("room-name-m")}
                      ref={(ref) => {
                        this.roomName_scroll = ref;
                      }}
                    >
                      <div>
                        {this.state.selectState
                          ? this.state.roomList.map((items, index) => {
                              return (
                                <span
                                  key={items["ROOM_CODE"]}
                                  onClick={() => this.wrapInfo(index)}
                                  className={cx("room_info_name", {
                                    checked: this.state.checkIndex === index,
                                  })}
                                  ref={(ref) => (this.room_all_view = ref)}
                                >
                                  {items["ROOM_NAME"]}
                                </span>
                              );
                            })
                          : this.state.roomList
                              .filter(
                                (val) => val["ROOM_STATUS"] !== "0003"
                              )
                              .map((items, index) => {
                                return (
                                  <span
                                    key={items["ROOM_CODE"]}
                                    onClick={() => this.wrapInfo(index)}
                                    className={cx("room_info_empty", {
                                      checked: this.state.checkIndex === index,
                                    })}
                                  >
                                    {items["ROOM_NAME"]}
                                  </span>
                                );
                              })}
                      </div>
                    </h2>
                    <div className={cx("room-info-table")}>
                      <table>
                        <thead>
                          <tr>
                            <th>{t("inform.nameoftheroom")}</th>
                            <th>{t("inform.rentfee")}</th>
                            <th>{t("inform.monthlyfee")}</th>
                            <th>{t("inform.availabledate")}</th>
                            <th>{t("inform.status")}</th>
                            <th>{t("inform.providedfacilities")}</th>
                          </tr>
                        </thead>

                        {this.state.selectState
                          ? this.state.roomList.map((items, index) => {
                              return (
                                <tbody
                                  key={items["ROOM_CODE"]}
                                  className={cx("all_room")}
                                  ref={(ref) => {
                                    this.all_room[index] = ref;
                                  }}
                                >
                                  <tr>
                                    <td>{items["ROOM_NAME"]}</td>
                                    <td>{items["DEPOSIT"]}</td>
                                    <td>{items["RENTFEE"]}</td>
                                    <td>
                                      {items["ROOM_STATUS"] === "0001"
                                        ? t("inform.instant")
                                        : items["FREE_DATE"]}
                                    </td>
                                    <td>{items["ROOM_STATUS_NAME"]}</td>
                                    <td
                                      onClick={(key) =>
                                        this.viewDetail(items["ROOM_CODE"])
                                      }
                                    >
                                      <img src={Glass} alt="more" />
                                    </td>
                                  </tr>
                                  <tr
                                    style={this.state.displayN}
                                    className={"clickView" + items["ROOM_CODE"]}
                                  >
                                    <td>{t("inform.room.explanation")}</td>
                                    <td
                                      colSpan="6"
                                      className={items["ROOM_CODE"]}
                                    >
                                      {items["DESCRIPTION"]}
                                    </td>
                                  </tr>
                                  <tr
                                    style={this.state.displayN}
                                    className={"clickView" + items["ROOM_CODE"]}
                                  >
                                    <td>{t("inform.facilities")}</td>
                                    <td colSpan="6">{items["facilities"]}</td>
                                  </tr>
                                </tbody>
                              );
                            })
                          : this.state.roomList
                              .filter(
                                (val) => val["ROOM_STATUS"] !== "0003"
                              )
                              .map((items, index) => {
                                return (
                                  <tbody
                                    key={items["ROOM_CODE"]}
                                    className={cx("empty_room")}
                                  >
                                    <tr>
                                      <td>{items["ROOM_NAME"]}</td>
                                      <td>{items["DEPOSIT"]}</td>
                                      <td>{items["RENTFEE"]}</td>
                                      <td>
                                        {items["ROOM_STATUS"] === "0001"
                                          ? t("inform.instant")
                                          : items["FREE_DATE"]}
                                      </td>
                                      <td>{items["ROOM_STATUS_NAME"]}</td>
                                      <td
                                        onClick={(key) =>
                                          this.viewDetail(items["ROOM_CODE"])
                                        }
                                      >
                                        <img src={Glass} alt="more" />
                                      </td>
                                    </tr>
                                    <tr
                                      style={this.state.displayN}
                                      className={
                                        "clickView" + items["ROOM_CODE"]
                                      }
                                    >
                                      <td>{t("inform.room.explanation")}</td>
                                      <td
                                        colSpan="6"
                                        className={items["ROOM_CODE"]}
                                      >
                                        {items["DESCRIPTION"]}
                                      </td>
                                    </tr>
                                    <tr
                                      style={this.state.displayN}
                                      className={
                                        "clickView" + items["ROOM_CODE"]
                                      }
                                    >
                                      <td>{t("inform.facilities")}</td>
                                      <td colSpan="6">{items["facilities"]}</td>
                                    </tr>
                                  </tbody>
                                );
                              })}
                        {this.state.roomCount === 0 && (
                          <tbody>
                            <tr>
                              <td
                                colSpan="6"
                                style={{ color: "#666", textAlign: "center" }}
                              >
                                {t("inform.romm.full")}
                              </td>
                            </tr>
                          </tbody>
                        )}
                      </table>
                    </div>
                    <div
                      className={cx("room-info-table-m")}
                      ref={(ref) => {
                        this.room_info = ref;
                      }}
                      onScroll={this.roomScroll}
                    >
                      <table
                        data-pos-location={this.state.nthInfo}
                        data-length={this.state.roomCount}
                        ref={(ref) => {
                          this.room_info_table = ref;
                        }}
                      >
                        {this.state.selectState
                          ? this.state.roomList.map((items, index) => {
                              return (
                                <tbody
                                  key={items["ROOM_CODE"]}
                                  ref={(ref) => (this.all_room[index] = ref)}
                                >
                                  <tr>
                                    <td>{t("inform.nameoftheroom")}</td>
                                    <td>{items["ROOM_NAME"]}</td>
                                  </tr>
                                  <tr>
                                    <td>{t("inform.rentfee")}</td>
                                    <td>{items["DEPOSIT"]}</td>
                                  </tr>
                                  <tr>
                                    <td>{t("inform.monthlyfee")}</td>
                                    <td>{items["RENTFEE"]}</td>
                                  </tr>
                                  <tr>
                                    <td>{t("inform.availabledate")}</td>
                                    <td>
                                      {items["ROOM_STATUS"] === "0001"
                                        ? t("inform.instant")
                                        : items["FREE_DATE"]}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>{t("inform.status")}</td>
                                    <td>{items["ROOM_STATUS_NAME"]}</td>
                                  </tr>
                                  <tr className={items["ROOM_CODE"]}>
                                    <td>{t("inform.room.explanation")}</td>
                                    <td className={cx("fac")}>
                                      {items["DESCRIPTION"]}
                                    </td>
                                  </tr>
                                  <tr className={items["ROOM_CODE"]}>
                                    <td>{t("inform.providedfacilities")}</td>
                                    <td className={cx("fac")}>
                                      {items["facilities"]}
                                    </td>
                                  </tr>
                                </tbody>
                              );
                            })
                          : this.state.roomList
                              .filter(
                                (val) => val["ROOM_STATUS"] !== "0003"
                              )
                              .map((items, index) => {
                                return (
                                  <tbody
                                    key={items["ROOM_CODE"]}
                                    ref={(ref) =>
                                      (this.empty_room[index] = ref)
                                    }
                                  >
                                    <tr>
                                      <td>{t("inform.nameoftheroom")}</td>
                                      <td>{items["ROOM_NAME"]}</td>
                                    </tr>
                                    <tr>
                                      <td>{t("inform.rentfee")}</td>
                                      <td>{items["DEPOSIT"]}</td>
                                    </tr>
                                    <tr>
                                      <td>{t("inform.monthlyfee")}</td>
                                      <td>{items["RENTFEE"]}</td>
                                    </tr>
                                    <tr>
                                      <td>{t("inform.availabledate")}</td>
                                      <td>
                                        {items["ROOM_STATUS"] === "0001"
                                          ? t("inform.instant")
                                          : items["FREE_DATE"]}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>{t("inform.status")}</td>
                                      <td>{items["ROOM_STATUS_NAME"]}</td>
                                    </tr>
                                    <tr className={items["ROOM_CODE"]}>
                                      <td>{t("inform.room.explanation")}</td>
                                      <td className={cx("fac")}>
                                        {items["DESCRIPTION"]}
                                      </td>
                                    </tr>
                                    <tr className={items["ROOM_CODE"]}>
                                      <td>{t("inform.providedfacilities")}</td>
                                      <td className={cx("fac")}>
                                        {items["facilities"]}
                                      </td>
                                    </tr>
                                  </tbody>
                                );
                              })}
                        {this.state.roomCount === 0 && (
                          <tbody>
                            <tr
                              style={{
                                width: "100%",
                              }}
                            >
                              <td
                                colSpan="2"
                                style={{
                                  color: "#666",
                                  textAlign: "center",
                                  width: "100%",
                                }}
                              >
                                {t("inform.romm.full")}
                              </td>
                            </tr>
                          </tbody>
                        )}
                      </table>
                    </div>
                  </div>
                )}

                {this.state.spaceList && (
                  <>
                    <h1 className={cx("shareSpaceHead")}>
                      {ReactHtmlParser(t("inform.shareSpace"))}
                    </h1>
                    <div className={cx("shareArea")}>
                      {window.screen.width < 768 && (
                        <div className={cx("spaceHeadWrap")}>
                          {this.state.spaceList.map((items, index) => {
                            return (
                              <div
                                key={index}
                                onClick={() => this.spaceScroll(index)}
                                className={cx({
                                  checked: this.state.spaceHead === index,
                                })}
                              >
                                {items["SPACE_NAME"]}
                              </div>
                            );
                          })}
                        </div>
                      )}
                      <div
                        className={cx("spaceContentWrap", {
                          web: window.screen.width > 768,
                        })}
                        data-count={this.state.spaceCount}
                      >
                        {window.screen.width > 768 ? (
                          <div
                            ref={(ref) => {
                              this.spaceContent = ref;
                            }}
                            onScroll={this.spaceContentScroll}
                            className={cx("spaceList")}
                          >
                            <Slider {...settingsSpace}>
                              {this.state.spaceList.map((items, index) => {
                                return (
                                  <div
                                    className={cx("spaceContent")}
                                    key={index}
                                  >
                                    <figure>
                                      <img
                                        src={items["THUMB_URL"]}
                                        alt="spaceThumb"
                                      />
                                    </figure>
                                    <div className={cx("contentText")}>
                                      <h4>{items["SPACE_NAME"]}</h4>
                                      <p>
                                        {ReactHtmlParser(items["DESCRIPTION"])}
                                      </p>
                                      <div>
                                        사용대상 : {items["SPACE_TARGET"]}
                                      </div>
                                      <div>
                                        수용인원 : {items["SPACE_TENANT"]}
                                      </div>
                                      <div>
                                        사용시간 : {items["AVAIL_TIME"]}
                                      </div>
                                      <div>시설 : {items["FACILITY"]}</div>
                                    </div>
                                  </div>
                                );
                              })}
                            </Slider>
                          </div>
                        ) : (
                          <div
                            ref={(ref) => {
                              this.spaceContent = ref;
                            }}
                            onScroll={this.spaceContentScroll}
                            className={cx("spaceList")}
                          >
                            {this.state.spaceList.map((items, index) => {
                              return (
                                <div className={cx("spaceContent")} key={index}>
                                  <figure>
                                    <img
                                      src={items["THUMB_URL"]}
                                      alt="spaceThumb"
                                    />
                                  </figure>
                                  <div className={cx("contentText")}>
                                    <h4>{items["SPACE_NAME"]}</h4>
                                    <p>
                                      {ReactHtmlParser(items["DESCRIPTION"])}
                                    </p>
                                    <div>
                                      사용대상 : {items["SPACE_TARGET"]}
                                    </div>
                                    <div>
                                      수용인원 : {items["SPACE_TENANT"]}
                                    </div>
                                    <div>사용시간 : {items["AVAIL_TIME"]}</div>
                                    <div>시설 : {items["FACILITY"]}</div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                )}

                <div
                  className={cx("comeToMe")}
                  ref={(ref) => {
                    this.house = ref;
                  }}
                ></div>
                <div className={cx("house-location")}>
                  <h1>{t("inform.houseintro")}</h1>
                  <div
                    className={cx("house-location-map")}
                    ref={(ref) => (this.mapRef = ref)}
                  />
                  <div className={cx("map-icons")}>
                    <div>
                      <div className={cx("icons")}>
                        <img src={Hospital} alt="map-icon" />
                        <span>{t("inform.hospital")}</span>
                      </div>

                      <div className={cx("icons")}>
                        <img src={Bank} alt="map-icon" />
                        <span>{t("inform.bank")}</span>
                      </div>

                      <div className={cx("icons")}>
                        <img src={Daiso} alt="map-icon" />
                        <span>{t("inform.daiso")}</span>
                      </div>

                      <div className={cx("icons")}>
                        <img src={Drugstore} alt="map-icon" />
                        <span>{t("inform.drugstore")}</span>
                      </div>

                      <div className={cx("icons")}>
                        <img src={fastfood} alt="map-icon" />
                        <span>{t("inform.fastfood")}</span>
                      </div>

                      <div className={cx("icons")}>
                        <img src={Cafe} alt="map-icon" />
                        <span>{t("inform.cafe")}</span>
                      </div>

                      <div className={cx("icons")}>
                        <img src={Laundry} alt="map-icon" />
                        <span>{t("inform.laundry")}</span>
                      </div>

                      <div className={cx("icons")}>
                        <img src={drug} alt="map-icon" />
                        <span>{t("inform.drug")}</span>
                      </div>

                      <div className={cx("icons")}>
                        <img src={Mart} alt="map-icon" />
                        <span>{t("inform.mart")}</span>
                      </div>

                      <div className={cx("icons")}>
                        <img src={store24} alt="map-icon" />
                        <span>{t("inform.convenience")}</span>
                      </div>

                      <div className={cx("icons")}>
                        <img src={Movie} alt="map-icon" />
                        <span>{t("inform.movie")}</span>
                      </div>

                      <div className={cx("icons")}>
                        <img src={police} alt="map-icon" />
                        <span>{t("inform.police")}</span>
                      </div>

                      <div className={cx("icons")}>
                        <img src={office} alt="map-icon" />
                        <span>{t("inform.office")}</span>
                      </div>

                      <div className={cx("icons")}>
                        <img src={Hot} alt="map-icon" />
                        <span>{t("inform.hot")}</span>
                      </div>
                    </div>
                  </div>
                  <div className={cx("house-location-info")}>
                    <div className={cx("train-info")}>
                      {this.state.subwayList.map((items, index) => {
                        return (
                          <div className={cx("info-items")} key={index}>
                            {items["lineList"].map((item, index) => {
                              if (
                                item["NAME"] !== "1" &&
                                item["NAME"] !== "2" &&
                                item["NAME"] !== "3" &&
                                item["NAME"] !== "4" &&
                                item["NAME"] !== "5" &&
                                item["NAME"] !== "6" &&
                                item["NAME"] !== "7" &&
                                item["NAME"] !== "8" &&
                                item["NAME"] !== "9"
                              ) {
                                return (
                                  <span
                                    className={cx([
                                      "train-number",
                                      "string-train",
                                    ])}
                                    style={{
                                      backgroundColor: item["COLOR"],
                                    }}
                                    key={index}
                                  >
                                    {item["NAME"]}
                                  </span>
                                );
                              } else {
                                return (
                                  <span
                                    className={cx("train-number")}
                                    style={{
                                      backgroundColor: item["COLOR"],
                                    }}
                                    key={index}
                                  >
                                    {item["NAME"]}
                                  </span>
                                );
                              }
                            })}
                            {items["SUB_NAME"] + "" + items["DISTANCE"]}
                          </div>
                        );
                      })}
                    </div>
                    <div className={cx("schoolInfo")}>
                      {this.state.universityList.map((val, index) => {
                        return (
                          <div key={index}>
                            <img src={school} alt="school" />
                            <span>{val.UNI_NAME}</span>
                            <span>{val.DISTANCE}</span>
                          </div>
                        );
                      })}
                    </div>
                    <div className={cx("house-info")}>
                      {ReactHtmlParser(this.state.house["INTRODUCE"])}
                    </div>
                  </div>
                </div>

                <div
                  className={cx("comeToMe")}
                  ref={(ref) => {
                    this.manager = ref;
                  }}
                ></div>
                <div className={cx("master-info")}>
                  <div className={cx("profile")}>
                    <div>
                      <img
                        src={this.state.brand["LOGO_PIC_URL"]}
                        alt="profile"
                      />
                    </div>
                  </div>
                  <div className={cx("info-content")}>
                    <h1>{this.state.brand["BRAND_NAME"]}</h1>
                    <p>
                      <b>
                        {this.state.brand["HOUSE_CNT"]}│
                        {this.state.brand["OPEN_DATE"]}
                      </b>
                    </p>
                    {ReactHtmlParser(this.state.brand["INTRODUCE"])}
                  </div>
                </div>
                {this.state.articleList.length > 0 && (
                  <div className={cx("house-story")}>
                    <h1>
                      {this.state.house["RENT_TYPE"] === "0002"
                        ? t("inform.youngContent")
                        : this.state.brand["BRAND_NAME"] +
                          " " +
                          t("inform.story")}
                      {this.state.house["RENT_TYPE"] === "0002" ? (
                        <span
                          onClick={() => {
                            this.props.history.push("/article/young");
                          }}
                        >
                          {t("inform.more")}
                        </span>
                      ) : (
                        <span
                          onClick={() => {
                            this.props.history.push("/article", {
                              nowCategory: "house",
                              nowDetailCategory: "0003",
                            });
                          }}
                        >
                          {t("inform.more")}
                        </span>
                      )}
                    </h1>
                    <div className={cx("story-cards-slider")}>
                      <div
                        className={cx("story-cards-inner")}
                        data-pos-location={this.state.nthCard}
                      >
                        {this.state.articleList.length > 0 &&
                          this.state.articleList.map((item, index) => {
                            if (index >= 3) return null;
                            return (
                              <div
                                className={cx("story-card")}
                                key={index}
                                onClick={(e) => this.goto(item)}
                              >
                                <div className={cx("card-image")}>
                                  <img
                                    src={item["INFO_THUMB_URL"]}
                                    alt="card"
                                  />
                                  {item["ARTICLE_TYPE"] === "2" && (
                                    <img
                                      className={cx("card-play")}
                                      src={play}
                                      alt="play"
                                    />
                                  )}
                                </div>

                                <div className={cx("card-title")}>
                                  {item["TITLE"]}
                                </div>
                                <div className={cx("card-footer")}>
                                  <span>{item["CREATE_DT"]}</span>
                                  {item["LIKE_CNT"] && (
                                    <span>
                                      <img src={heart} alt="heart" />
                                      {item["LIKE_CNT"]}
                                    </span>
                                  )}
                                  {item["COMMENT_CNT"] && (
                                    <span>
                                      <img src={comment} alt="comment" />
                                      {item["COMMENT_CNT"]}
                                    </span>
                                  )}
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className={cx("right")}>
          <div
            className={cx("fixed-info")}
            ref={(ref) => {
              this.fixedInfo = ref;
            }}
          >
            <div className={cx("tag-box")}>
              {this.state.house["AD_HOUSE_YN"] === "Y" && (
                <button className={cx("nomination-btn")}>
                  {t("inform.room.recommend")}
                </button>
              )}

              <button className={cx("primary-blue-btn")}>
                {this.state.house["RENT_TYPE_NAME"]}
              </button>
              <span> {this.state.house["HOUSE_TYPE"]}</span>
            </div>
            <h1>
              {this.state.house["HOUSE_NAME"] &&
                this.state.brand["BRAND_NAME"] &&
                this.state.brand["BRAND_NAME"] +
                  " " +
                  this.state.house["HOUSE_NAME"]}
            </h1>
            <p className={"info-notice"}>{t("inform.checkfreeroom")}</p>
            <form>
              <select
                defaultValue=""
                name="ROOM_NAME"
                onChange={this.selectRoom}
              >
                <option value="">{t("inform.selRoom")}</option>
                {this.state.roomList.map((item) => {
                  return (
                    <option value={item["ROOM_CODE"]} key={item["ROOM_CODE"]}>
                      {item["ROOM_NAME"]}
                    </option>
                  );
                })}
              </select>
              <input
                id="romm-chat"
                type="button"
                value={t("inform.room.chat")}
                onClick={this.questionView}
              />
            </form>
            <div className={cx("util-buttons")}>
              {this.state.favCheck ? (
                <div
                  onClick={(e) => {
                    this.DelFav(e, this.state.house.HOUSE_ID);
                  }}
                >
                  <img src={star_yellow} alt="fav" />
                </div>
              ) : (
                <div
                  onClick={(e) => {
                    this.AddFav(e, this.state.house.HOUSE_ID);
                  }}
                >
                  <img src={star_gray} alt="fav" />
                </div>
              )}

              <div
                onClick={() =>
                  this.AddComparelist(this.state.house["HOUSE_ID"])
                }
              >
                <img src={folder_add} alt="folder" className={cx("share")} />
              </div>
              <div>
                <img src={share} alt="share" />
              </div>
              <div className={cx("hover-util")}>
                <button id="kakao">
                  <img
                    src={kakao_share}
                    alt="kakao_share"
                    onClick={this.makeShareButton}
                  />
                </button>
                <button onClick={this.facebookShareButton}>
                  <img src={facebook_share} alt="facebook_share" />
                </button>

                <button onClick={this.twitterShareButton}>
                  <img src={twitter_share} alt="twitter_share" />
                </button>

                <button onClick={this.normalShareLink}>
                  <img src={link_share} alt="link_share" />
                </button>
              </div>
              <button onClick={(e) => this.openModal(e)}>
                <img src={folder_add} alt="folder_add" />
                {t("inform.comparison")} ({this.state.compareList.length})
              </button>
            </div>
            {this.state.questionOn && (
              <div
                className={cx("chating")}
                ref={(ref) => (this.chating = ref)}
              >
                <div className={cx("chating-head")}>
                  <h1>{t("inform.room.question")}</h1>
                  <p>
                    <span>{t("inform.room.callQuestion")} </span>
                    <span className={cx("tel")}>
                      <a href={"tel:" + this.state.brand.BRAND_TEL_NO}>
                        {this.state.brand.BRAND_TEL_NO}
                      </a>
                    </span>
                  </p>
                  <div onClick={this.closeQuestion}>+</div>
                </div>
                <div className={cx("date-selector")} id="calendar">
                  <span>{t("inform.room.checkInDate")}</span>
                  <input
                    type="text"
                    placeholder={t("inform.checkin")}
                    className={cx("start-date")}
                    value={this.state.checkInDate}
                    onClick={this.checkInCalendarView}
                    readOnly
                  />
                  {this.state.checkInCalendar && (
                    <Calendar
                      id="calendar"
                      className={cx("cal")}
                      value={this.state.calendarDate}
                      onChange={this.handleCheckInDate}
                    />
                  )}
                  <input
                    type="text"
                    placeholder={t("inform.checkout")}
                    className={cx("end-date")}
                    value={this.state.checkOutDate}
                    onClick={this.checkOutCalendarView}
                    readOnly
                  />
                  {this.state.checkOutCalendar && (
                    <Calendar
                      id="calendar"
                      className={cx("cal")}
                      value={this.state.calendarDate}
                      onChange={this.handleCheckOutDate}
                    />
                  )}
                </div>
                <div className={cx("chat-body")} ref={this.chatScroll}>
                  {this.props.messageList && (
                    <>
                      {this.props.messageList.map((val, index) => {
                        if (val.ACCOUNT_TYPE === "0003") {
                          return (
                            <div className={cx("user-chat")} key={index}>
                              <div className={cx("chat")}>
                                <span>{val.MESSAGE}</span>

                                <span className={cx("time-line")}>
                                  {val.MESSAGE_DT}
                                </span>
                              </div>
                              <span className="clear"></span>
                            </div>
                          );
                        } else {
                          return (
                            <div className={cx("admin-chat")} key={index}>
                              <div className={cx("chat-profile")}>
                                <img src={val.MEMBER_PIC_URL} alt="profile" />
                              </div>
                              <div className={cx("chat")}>
                                <span>{val.MESSAGE}</span>

                                <span className={cx("time-line")}>
                                  {val.MESSAGE_DT}
                                </span>
                              </div>

                              <span className="clear"></span>
                            </div>
                          );
                        }
                      })}
                    </>
                  )}
                  <div className="clear"></div>
                </div>
                <div className={cx("chat-sender")}>
                  <form onSubmit={this.houseMessageSend}>
                    <input
                      type="text"
                      placeholder={t("inform.room.plzInsertMessage")}
                      onChange={(e) => this.MessageChn(e)}
                      value={this.state.InqMessage}
                    />
                    <button
                      type="submit"
                      className={cx("icon")}
                      onClick={this.houseMessageSend}
                    ></button>
                  </form>
                </div>
              </div>
            )}
          </div>
        </div>

        <>
          <Popup
            closeModal={this.closeModal}
            isModalOpen={this.state.isModalOpen}
          >
            <Compare
              closeModal={this.closeModal}
              isModalOpen={this.state.isModalOpen}
            />
          </Popup>

          <Popup
            closeModal={this.closeLoginModal}
            isModalOpen={this.state.isLoginModalOpen}
          >
            <Login
              closeModal={this.closeLoginModal}
              isModalOpen={this.state.isLoginModalOpen}
            />
          </Popup>
        </>
      </div>
    );
  }
}

export default withTranslation()(withRouter(Detail));
