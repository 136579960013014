import React from "react";
import { Switch, Route, NavLink, withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import classnames from "classnames/bind";
import styles from "./_Dictionary.module.scss";
import search from "assets/images/Search.png";

import MainAll from "containers/common/dictionary/detail/MainAllContainer/MainAllContainer";
import HouseLife from "containers/common/dictionary/detail/HouseLifeContainer/HouseLifeContainer";
import Comic from "containers/common/dictionary/detail/ComicContainer/ComicContainer";
import Story from "containers/common/dictionary/detail/StoryContainer/StoryContainer";
import Report from "containers/common/dictionary/detail/ReportContainer/ReportContainer";
import Young from "containers/common/dictionary/detail/YoungContainer/YoungContainer";
import Know from "containers/common/dictionary/detail/KnowContainer/KnowContainer";

const cx = classnames.bind(styles);
class Dictionary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      SEARCH_TEXT: "",
      house: false,
      young: false
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  // 검색어 입력
  onChange = e => {
    const name = e.target.name;
    const value = e.target.value;

    this.setState({
      [name]: value
    });
  };
  // 청년주거 백과 내 검색어 유지
  ArticleContent = e => {
    e.preventDefault();

    const SEARCH_TEXT = this.state.SEARCH_TEXT;

    this.props.ArticleContent({ SEARCH_TEXT });
  };

  render() {
    const { t } = this.props;
    return (
      <>
        <div className={cx("dictionary-wrap")}>
          <div className={cx("dictionary-search")}>
            <h1>{t("header.encyclopedia")}</h1>
            <p>
              {t("Dictionary.subTitle1")}
              <br />
              {t("Dictionary.subTitle2")}
            </p>
            <form onSubmit={this.ArticleContent}>
              <input
                type="text"
                name="SEARCH_TEXT"
                placeholder={t("Dictionary.insertWord")}
                onChange={this.onChange}
                value={this.state.searchText}
              ></input>
              <button type="submit">{t("Dictionary.search")}</button>
              <img src={search} alt={"search"} />
            </form>
          </div>
        </div>
        <div className={cx("dictionary-category")}>
          <div className={cx("main-category")}>
            <ul>
              <li>
                <NavLink
                  exact
                  to="/article"
                  activeClassName={cx("primary")}
                  onClick={() => this.setState({ house: false, young: false })}
                >
                  ALL
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/article/house"
                  activeClassName={cx("primary")}
                  onClick={() => this.setState({ house: true, young: false })}
                >
                  {t("Dictionary.shareHouse")}
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/article/young"
                  activeClassName={cx("primary")}
                  onClick={() => this.setState({ house: false, young: true })}
                >
                  {t("Dictionary.seoulYoungHouse")}
                </NavLink>
              </li>
            </ul>
          </div>
          {this.props.match.url === "/article/house" ||
          this.props.match.params.sub ? (
            <div className={cx("sub-category")}>
              <ul>
                <li>
                  <NavLink
                    exact
                    to={"/article/house"}
                    activeClassName={cx("primary")}
                  >
                    {t("Dictionary.houseLife")}
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    exact
                    to="/article/house/comic"
                    activeClassName={cx("primary")}
                  >
                    {t("Dictionary.comic")}
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    exact
                    to="/article/house/story"
                    activeClassName={cx("primary")}
                  >
                    {t("Dictionary.story")}
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    exact
                    to="/article/house/report"
                    activeClassName={cx("primary")}
                  >
                    {t("Dictionary.report")}
                  </NavLink>
                </li>
              </ul>
            </div>
          ) : null}
          {this.props.match.url === "/article/young" ||
          this.props.match.params.type ? (
            <div className={cx("sub-category")}>
              <ul>
                <li>
                  <NavLink
                    exact
                    to={"/article/young"}
                    activeClassName={cx("primary")}
                  >
                    {t("Dictionary.youngHouse")}
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    exact
                    to="/article/young/youngknow"
                    activeClassName={cx("primary")}
                  >
                    {t("Dictionary.youngHouseKnow")}
                  </NavLink>
                </li>
              </ul>
            </div>
          ) : null}
          <Switch>
            <Route exact path="/article" component={MainAll} />
            <Route exact path="/article/house" component={HouseLife} />
            <Route exact path="/article/house/comic" component={Comic} />
            <Route
              exact
              path="/article/house/houselife"
              component={HouseLife}
            />
            <Route exact path="/article/house/story" component={Story} />
            <Route exact path="/article/house/report" component={Report} />
            <Route exact path="/article/young" component={Young} />
            <Route exact path="/article/young/youngknow" component={Know} />
          </Switch>
        </div>
      </>
    );
  }
}

export default withTranslation()(withRouter(Dictionary));
