import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as UserAction from "stores/modules/search";
import * as HouseActions from "stores/modules/housedetail";
import * as SearchActions from 'stores/modules/search';
import Search from "components/house/search/Search";
import { withTranslation } from "react-i18next";

import { resCode } from "lib/resCode";

class SearchContainer extends React.Component {
  state = {
    houseLoad: true,
    houseList: [],
    totalCount: 0,
    comparehouselist: [],
    page: "",
    totalPage: ""
  };

  componentDidMount() {
    // this.Viewcomparelist();
  }

  // 검색 페이지 하우스 검색 함수
  SearchList = async ({
    page,
    rows,
    NORTH,
    SOUTH,
    EAST,
    WEST,
    SHAREHOUSE,
    HOUSE2030,
    PREMIUM_HOUSE,
    VR_HOUSE,
    NEW_OPEN,
    NOW_MOVIN,
    CAMPAIGN,
    GENDER_WOMAN,
    GENDER_MAN,
    GENDER_ALL,
    FOREIGNER,
    DEPOSIT_ADJUST,
    FAT,
    DEPOSIT_MIN,
    DEPOSIT_MAX,
    RENTFEE_MIN,
    RENTFEE_MAX,
  }) => {
    const { UserAction } = this.props;
    try {
      const res = await UserAction.SearchList({
        page,
        rows,
        NORTH,
        SOUTH,
        EAST,
        WEST,
        SHAREHOUSE,
        HOUSE2030,
        PREMIUM_HOUSE,
        VR_HOUSE,
        NEW_OPEN,
        NOW_MOVIN,
        CAMPAIGN,
        GENDER_WOMAN,
        GENDER_MAN,
        GENDER_ALL,
        FOREIGNER,
        DEPOSIT_ADJUST,
        FAT,
        DEPOSIT_MIN,
    DEPOSIT_MAX,
    RENTFEE_MIN,
    RENTFEE_MAX,
      });
      const {t} = this.props;
      if (res["data"]["result"]["resCode"] !== "0000") {
        alert(t("search.notInfo"));
        return;
      }

      let nextState = this.state;

      nextState["page"] = res.data.result.data.page;
      nextState["totalPage"] = res.data.result.data.totalPage;
      nextState["houseList"] = res.data.result.data.houseList;
      nextState["totalCount"] = res.data.result.data.totalCount;
      nextState["houseLoad"] = false;

      await this.setState(nextState);
      return;
    } catch (e) {
      console.log(e);
      return;
    }
  };

  // 추천검색어 받아오는 함수
  SearchHelp = async search_keyword => {
    const { UserAction, SearchActions } = this.props;
    const MyStorage = localStorage;
    MyStorage.setItem("searchWord", search_keyword);
    try {
      const res = await UserAction.SearchHelp(search_keyword);
      const searchWord = SearchActions.SearchWord(search_keyword);
      return res.data.result.data.kewordList;
    } catch (e) {
      console.log(e);
    }
  };

  // 비교함 목록 뿌려주는 함수
  Viewcomparelist = () => {
    const Mystorage = localStorage;
    const { UserAction } = this.props;
    JSON.parse(Mystorage.getItem("compared")).map(item => {
      try {
        const houserest = `/house/${item}`;
        UserAction.HouseDetail(houserest).then(value => {
          let NextState = this.state;
          NextState["comparehouselist"].push(value.data.result.data);
          this.setState(NextState);
        });
        return null;
      } catch (e) {
        console.log(e);
        return null;
      }
    });
  };

  // 비교함 추가 함수
  AddCompare = async houseid => {
    const {t} = this.props;
    const Mystorage = localStorage;
    let compareList_arr = JSON.parse(Mystorage.getItem("compared"));
    let HasSameValue = false;
    if (compareList_arr.length > 3) {
      alert(t("search.compareAlert"));
    } else {
      if (compareList_arr.indexOf(houseid) !== -1) {
        HasSameValue = true;
        alert(t("search.compareAlreadyInAlert"));
      }
      if (!HasSameValue) {
        compareList_arr.push(houseid);
        Mystorage.setItem("compared", JSON.stringify(compareList_arr));
        alert(t("search.compareInAlert"));
        return null;
      }
    }
  };

  // 관심 하우스 추가
  addFavHouse = async houseid => {
    const { HouseActions } = this.props;

    try {
      const res = await HouseActions.AddFavHouse(houseid);
      resCode(res);

      await HouseActions.favHouseList();

      return;
    } catch (e) {
      return;
    }
  };

  // 관심 하우스 제거
  delFavHouse = async houseid => {
    const { HouseActions } = this.props;
    try {
      const res = await HouseActions.delFavHouse(houseid);
      resCode(res);

      await HouseActions.favHouseList();

      return;
    } catch (e) {
      return;
    }
  };

  render() {
    return (
      <Search
        isLogined={this.props.isLogined}
        page={this.state.page}
        totalPage={this.state.totalPage}
        SearchNomination={this.SearchHelp}
        SearchList={this.SearchList}
        houseLoad={this.state.houseLoad}
        houseList={this.state.houseList}
        totalCount={this.state.totalCount}
        AddCompare={this.AddCompare}
        comparehouselist={this.state.comparehouselist}
        addFavHouse={this.addFavHouse}
        delFavHouse={this.delFavHouse}
        searchText={this.props.SEARCH_TEXT}
      />
    );
  }
}

export default withTranslation()(
  connect(
    state => ({
      isLogined: state.user.auth.user.isLogined,
      SEARCH_TEXT: state.search.SEARCH_TEXT
    }),
    dispatch => ({
      UserAction: bindActionCreators(UserAction, dispatch),
      HouseActions: bindActionCreators(HouseActions, dispatch),
      SearchActions: bindActionCreators(SearchActions, dispatch)
    })
  )(SearchContainer)
);
