import React from "react";
import styles from "./_DeclinedEmail.module.scss";
import { withTranslation } from "react-i18next";
// import ReactHtmlParser from "react-html-parser";
import classnames from "classnames/bind";

const cx = classnames.bind(styles);

class DeclinedEmail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    // 홈이동
    goHome = () => {
      window.location.replace("/");
    }

    render() {
      const {t} = this.props;
        return (
            <div className={cx("chpw-wrap")}>
                <div>
                    <h1>{t("email.reject")}</h1>
                    <p>{t("email.rejectText")}</p>
                    <button onClick={this.goHome}>{t("email.toHome")}</button>
                </div>
            </div>
        );
    }
}

export default withTranslation()(DeclinedEmail);
