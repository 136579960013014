import React from "react";
import classnames from "classnames/bind";
import { withTranslation } from "react-i18next";
import styles from "./_MobileInquiry.module.scss";
import { Link } from "react-router-dom";

import notification from "assets/images/notification.png";
import check from "assets/images/check.png";

const cx = classnames.bind(styles);

class MobileInquiry extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inquiryData: [],
      isLoading: true,
      checked: [],
      checkedAll: false
    };
  }

  componentDidMount() {
    this.getData();
  }
  // 데이터 가져오기
  getData = () => {
    this.props.InquiryData().then(value => {
      let nextState = this.state;
      nextState["inquiryData"] = value;
      nextState["isLoading"] = false;
      this.setState(nextState);
    });
  };
  // 페이지 이동
  goto = e => {
    this.props.history.push(e);
  };

  // 메세지 삭제
  inquery_del = async () => {
    const { t } = this.props;
    if (this.state.checked.length === 0) {
      alert(t("myPage.delEmpty"));
      return false;
    }
    if (!window.confirm(t("myPage.delConfirm"))) {
      return false;
    }
    let nextState = this.state;
    const promises = nextState["checked"].map(async item =>
      this.props.inqueryDel(item)
    );

    await Promise.all(promises);

    this.getData();
  };

  // 체크 박스 전체 체크
  onChangeCheckBoxAll = () => {
    if (
      this.state.checked.length !== this.state.inquiryData["inquiryList"].length
    )
      this.setState({
        checked: this.state.inquiryData["inquiryList"].map(
          item => item["INQUIRY_ID"]
        )
      });
    else
      this.setState({
        checked: []
      });
  };
  // 체크 박스 체크
  onChangeCheckBox = e => {
    let nextState = this.state;

    if (this.state.checkedAll) {
      nextState["checkedAll"] = false;
    }

    if (e.target.checked) {
      nextState.checked.push(e.target.value);
    } else {
      nextState.checked = nextState.checked.filter(
        check => check !== e.target.value
      );
    }

    this.setState(nextState);
  };

  render() {
    const { t } = this.props;
    if (this.state.isLoading) return "Loading...";
    return (
      <div>
        <div className={cx("inquery")}>
          <div className={cx("inquery_header")}>
            <div>
              <input
                type="checkbox"
                className={cx("chk-box")}
                id="chk-all"
                checked={
                  this.state.checked.length !== 0 &&
                  this.state.checked.length ===
                    this.state.inquiryData["inquiryList"].length
                }
                onClick={this.onChangeCheckBoxAll}
              />
              <label htmlFor={"chk-all"}>
                <span>
                  <img src={check} alt={"check"} />
                </span>
              </label>
              <button onClick={this.inquery_del}>{t("myPage.Del")}</button>
            </div>
          </div>
          {this.state.inquiryData["inquiryList"] &&
            this.state.inquiryData["inquiryList"].map((items, index) => {
              return (
                <div className={cx("inquery_body")} key={index}>
                  <div className={cx("inquery_left")}>
                    <div>
                      <input
                        type="checkbox"
                        className={cx("chk-box")}
                        id={"chk-piece" + index}
                        value={items["INQUIRY_ID"]}
                        onChange={this.onChangeCheckBox}
                        checked={
                          this.state.checked.includes(items["INQUIRY_ID"]) ||
                          this.state.checkedAll
                        }
                      />
                      <label htmlFor={"chk-piece" + index}>
                        <span>
                          <img src={check} alt={"check"} />
                        </span>
                      </label>
                    </div>
                  </div>
                  <div className={cx("inquery_right")}>
                    <Link
                      to={`/user/mypage/inquiry/detail/${items["INQUIRY_ID"]}`}
                    >
                      <div>
                        <span>{items["RENT_TYPE_NAME"]}</span>
                        <span className={cx("houseName")}>
                          {items["BRAND_NAME"] + " " + items["HOUSE_NAME"]}
                        </span>
                        <span>{items["LAST_MESSAGE_DT"].substr(0, 10)}</span>
                      </div>
                      <div>
                        {items["LAST_MESSAGE"]}
                        {items["READ_YN"] === "N" && (
                          <img src={notification} alt="notification" />
                        )}
                      </div>
                    </Link>
                  </div>
                  <div className={cx("clear")}></div>
                </div>
              );
            })}
        </div>
      </div>
    );
  }
}

export default withTranslation()(MobileInquiry);
