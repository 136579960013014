import React from "react";
import { withRouter } from "react-router-dom";
import { withCookies } from "react-cookie";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as UserAction from "stores/modules/user";

import Register from "components/user/Register/Register";

import { encode } from "lib/encrypt";
import { resCode } from "lib/resCode";

class UserRegisterContainer extends React.Component {
  // 회원가입시 사용될 국가코드 받아오는 함수
  NationCodes = async () => {
    const { UserAction } = this.props;
    try {
      const res = await UserAction.Nation_Codes();
      return res.data.result.data.nationList;
    } catch (e) {
      console.log(e);
    }
  };

  // 일반회원 회원가입 함수
  RegisterNormal = async ({
    EMAIL,
    MEMBER_NAME,
    GENDER,
    BIRTHYEAR,
    NEW_PASSWORD,
    NATION_CODE,
    NOTICE_YN
  }) => {
    const { UserAction } = this.props;
    try {
      const res = await UserAction.RegisterNormal({
        EMAIL,
        MEMBER_NAME,
        GENDER,
        BIRTHYEAR,
        NEW_PASSWORD,
        NATION_CODE,
        NOTICE_YN
      });

      resCode(res);

      const LoginData = {
        member_name: res["data"]["result"]["data"]["member"]["MEMBER_NAME"],
        access_token: res["headers"]["x-access-token"],
        refresh_token: res["headers"]["x-refresh-token"]
      };

      this.props.cookies.set("auth", encode(JSON.stringify(LoginData)), {
        path: "/"
      });

      window.location.replace("/user/registerDone");
    } catch (e) {
      console.log(e);
    }
  };

  render() {
    return (
      <Register
        isLogined={this.props.isLogined}
        NationCodes={this.NationCodes}
        RegisterNormal={this.RegisterNormal}
      />
    );
  }
}

export default withCookies(
  connect(
    state => ({
      isLogined: state.user.auth.user.isLogined
    }),
    dispatch => ({
      UserAction: bindActionCreators(UserAction, dispatch)
    })
  )(withRouter(UserRegisterContainer))
);
